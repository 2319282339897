import React from "react";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2/dist/sweetalert2.js";
import debounce from "lodash.debounce";
import Select from "react-select";
import { connect } from "react-redux";
import { withAlert } from "react-alert";
import { Table } from "react-bootstrap";
import {
  castToDefaultDateFormat,
  castToTimeFormat,
} from "../../../settings/index";
import requireAuth from "../../../hoc/requireAuth.js";
import Footer from "../../../common/Footer.js";
import AdminSideNav from "../../../common/AdminSideNav.js";
import AnalystSideNav from "../../../common/AnalystSideNav.js";
import UnauthorisedPage from "../../Unauthorised.js";
import TopNav from "../../../common/TopNav.js";
import authorizeAnalystOrAdmin from "../../../hoc/authorizeAnalystOrAdmin.js";
import "../../../assets/css/data-upload.css";
import "sweetalert/dist/sweetalert.css";

const securityConfig = {
  flipkart: "FKLID_PID42!",
  domain: "DomainIP88@",
  screenshot: "SnapShot77#",
  facebook: "Facebook|66&",
  master: "Acc3s@s@f4",
  pinterest: "Pinterest@199",
  twitter: "xScrape@122_",
};

const optionsMap = [
  { label: "Select Report", value: "" },
  { label: "Flipkart LID/PID", value: "flipkart" },
  { label: "Web Screenshot", value: "screenshot" },
  { label: "Domain IP Extractor", value: "domain" },
  { label: "Facebook Search", value: "facebook" },
  { label: "Pinterest Search", value: "pinterest" },
  { label: "Twitter Search", value: "twitter" },
];
class FlipkartLID extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authorised: true,
      emailId: "",
      report: "",
      platform: "",
      urls: [],
      queues: [],
      proxy: true,
      fullscreen: false,
      headless: true,
      pagination: {
        page: 1,
        nextPage: 1,
        prevPage: null,
        hasNextPage: true,
        hasPrevPage: false,
        pagingCounter: 1,
        totalDocs: null,
        totalPages: null,
      },
      perPage: 10,
      isLoading: false,
      hasMoreDomainData: true,
    };
    this.listofRecords = debounce(this.listofRecords.bind(this), 500);
    window.onscroll = debounce(() => {
      const {
        listofRecords,
        state: { hasMoreDomainData },
      } = this;

      if (!hasMoreDomainData) return;

      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        listofRecords();
      }
    }, 100);
  }

  componentWillUnmount() {
    window.onscroll = null;
  }

  componentDidMount() {
    this.listofRecords();
    this.getEmailId();
  }

  async getEmailId() {
    await window.axios
      .get("/users/getEmailId", {
        params: {
          page: 1,
          perPage: 400,
        },
      })
      .then((res) => {
        this.setState({
          emailId: res.data.data,
        });
      });
  }

  async postQueueConfirmation() {
    const { value: password } = await Swal.fire({
      title: "Enter your password",
      input: "password",
      inputLabel: "Password",
      inputPlaceholder: "Enter your password",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: (password) => {
        if (
          password !== securityConfig[this.state.report] &&
          password !== securityConfig["master"]
        ) {
          Swal.showValidationMessage("Incorrect password");
          return false;
        }
        return password;
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });

    if (
      password === securityConfig[this.state.report] ||
      password === securityConfig["master"]
    ) {
      if (!this.state.report || this.state.urls.length === 0) return;

      const username = this.props.login.user.username;
      const noOfDocument =
        this.state.report === "flipkart" ? 1 : this.state.urls.length;

      try {
        const result = await Swal.fire({
          title:
            this.state.report === "flipkart"
              ? `Flipkart search term: ${this.state.urls[0]}`
              : `This query will fetch ${noOfDocument} records`,
          showDenyButton: true,
          showCancelButton: false,
          showConfirmButton: noOfDocument > 0,
          confirmButtonText: "Accept",
          denyButtonText: `Cancel`,
        });

        const body = {
          email: this.state.emailId,
          username,
          numOfRecords: noOfDocument,
          report: this.state.report,
          urls: this.state.urls,
          proxy:
            ["social-media", "e-commerce", "app-store"].includes(
              this.state.platform
            ) &&
            ![
              "facebook",
              "domain",
              "pinterest",
              "flipkart",
              "twitter",
            ].includes(this.state.report),
          fullscreen: this.state.fullscreen,
          headless: this.state.headless,
          platform: this.state.platform,
        };

        if (result.isConfirmed) {
          await window.axios.post("/queue/postQueueRecord", body);
          this.listofRecords(true);
          Swal.fire("Saved!", "", "success");
        }
      } catch (error) {
        alert(error.response?.data?.message || "An error occurred");
      }
    }
  }

  async listofRecords(reArrangeRecords = false) {
    const reqData = {
      page: reArrangeRecords ? 1 : this.state.pagination.nextPage,
      perPage: this.state.perPage,
    };

    this.setState({ isLoading: true });

    try {
      const res = await window.axios.get(
        "/queue/getRecords?report=flipkart&report=screenshot&report=domain&report=facebook&report=pinterest&report=twitter",
        { params: reqData }
      );

      const queueData = reArrangeRecords
        ? res.data.docs
        : [...this.state.queues, ...res.data.docs];

      this.setState({
        queues: queueData,
        isLoading: false,
        pagination: {
          page: res.data.page,
          nextPage: res.data.nextPage,
          prevPage: res.data.prevPage,
          hasNextPage: res.data.hasNextPage,
          hasPrevPage: res.data.hasPrevPage,
          pagingCounter: res.data.pagingCounter,
          totalDocs: res.data.totalDocs,
          totalPages: res.data.totalPages,
        },
        hasMoreDomainData: res.data.hasNextPage,
      });
    } catch (error) {
      this.setState({ isLoading: false });
      console.error("Error fetching records:", error);
    }
  }

  renderLoader() {
    return this.state.isLoading ? (
      <div className="text-center">
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={10}
          width={200}
          timeout={5000}
        />
      </div>
    ) : null;
  }

  renderHtml() {
    if (!this.state.authorised) return <UnauthorisedPage />;

    return (
      <div className="row">
        {this.props?.login?.user?.role === "Admin" ? (
          <AdminSideNav />
        ) : (
          <AnalystSideNav />
        )}

        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
          <TopNav />

          <div className="main-content-container container-fluid px-4">
            <div className="page-header row no-gutters py-4">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-sm-4 text-sm-left mb-0">
                    <span className="text-uppercase page-subtitle">
                      Quick Tools
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card card-small mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        {/* Reports Dropdown */}
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Reports
                            </span>
                            <select
                              onChange={(e) =>
                                this.setState({ report: e.target.value })
                              }
                              className="form-control"
                            >
                              {optionsMap.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Platform
                            </span>
                            <select
                              value={this.state.platform}
                              onChange={(e) =>
                                this.setState({ platform: e.target.value })
                              }
                              className="form-control"
                            >
                              <option value="">Select from dropdown</option>
                              <option value="domain">Domain</option>
                              <option value="website">Website</option>
                              <option value="social-media">Social Media</option>
                              <option value="e-commerce">E-commerce</option>
                              <option value="app-store">App Store</option>
                            </select>
                          </div>
                        </div>

                        {/* Email Field */}
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Email
                            </span>
                            <Select
                              value={{
                                value: this.state.emailId,
                                label: this.state.emailId,
                              }}
                              isDisabled={true}
                            />
                          </div>
                        </div>

                        {/* Conditional Fields Based on Selected Report */}
                        {[
                          "facebook",
                          "flipkart",
                          "pinterest",
                          "twitter",
                        ].includes(this.state.report) && (
                          <div className="col-md-3">
                            <div className="form-group">
                              <span className="text-muted d-block mb-2">
                                Search
                              </span>
                              <input
                                className="form-control form-control-md"
                                placeholder="Enter search term or URL"
                                onChange={(e) =>
                                  this.setState({ urls: [e.target.value] })
                                }
                              />
                            </div>
                          </div>
                        )}

                        {["screenshot", "domain"].includes(
                          this.state.report
                        ) && (
                          <div className="col-md-3">
                            <div className="form-group">
                              <span className="text-muted d-block mb-2">
                                File
                              </span>
                              <input
                                type="file"
                                className="form-control form-control-md"
                                accept=".txt"
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file?.type === "text/plain") {
                                    const reader = new FileReader();
                                    reader.onload = (e) =>
                                      this.setState({
                                        urls: e.target.result
                                          .split("\n")
                                          .map((line) => line.trim())
                                          .filter(Boolean),
                                      });
                                    reader.readAsText(file);
                                  } else {
                                    alert("Please upload a valid .txt file");
                                  }
                                }}
                              />
                            </div>
                          </div>
                        )}

                        {/* Start Processing Button */}
                        <div className="col-md-3 d-flex align-items-end">
                          <div className="form-group d-flex justify-content-center w-100">
                            <button
                              onClick={() => this.postQueueConfirmation()}
                              className="btn btn-ldotr w-100"
                              disabled={
                                !this.state.report ||
                                !this.state.platform ||
                                this.state.urls.length === 0
                              }
                            >
                              Start Processing
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="card card-small mb-4">
                  <div className="card-body p-0">
                    <Table hover className="table mb-0">
                      <thead className="bg-light uppercase-th">
                        <th width="3%">Sr.</th>
                        <th width="10%">Date & Time added</th>
                        <th width="8%">Username</th>
                        <th width="8%">Report</th>
                        <th width="6%">No of Records</th>
                        <th width="8%">Status</th>
                        <th width="8%">Download</th>
                      </thead>
                      <tbody>
                        {this.state.queues.map((queue, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {queue.DateCreated &&
                                `${castToDefaultDateFormat(queue.DateCreated)} 
                                 ${castToTimeFormat(queue.DateCreated)}`}
                            </td>
                            <td title={queue.username}>{queue.username}</td>
                            <td title={queue.report}>{queue.report}</td>
                            <td>{queue.numOfRecords}</td>
                            <td>{queue.status}</td>
                            <td>
                              <a href={queue.downloadURL} download>
                                <button
                                  className="btn btn-ldotr"
                                  disabled={
                                    !queue.downloadURL ||
                                    queue.status === "Pending"
                                  }
                                >
                                  Download
                                </button>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>

                {this.state.hasMoreDomainData && (
                  <div className="text-center">
                    <button
                      className="btn btn-link text-danger"
                      onClick={() => this.listofRecords()}
                      disabled={this.state.isLoading}
                    >
                      {this.state.isLoading ? "Loading..." : "Load more"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </div>
    );
  }

  render() {
    return this.renderHtml();
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  login: state.login,
  response: state.response,
});

export default withAlert()(
  connect(mapStateToProps)(authorizeAnalystOrAdmin(requireAuth(FlipkartLID)))
);
