import { useState } from "react";
import BarChartComponent from "../charts/barChats/BarChartComponent";
import UniversalModal from "../modal/universalModal";

const WebsiteDashboard = ({ chartData }) => {
  const { reporting } = chartData;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelType, setModalType] = useState(null);

  const handleOpenModal = (type) => {
    setIsModalOpen(true);
    setModalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalType(null);
  };
  return (
    <>
      <div className="container" style={{width: "100%"}}>
        <div
          className="row card-body p-0 text-center"
          style={{
            height: "100%",
            minHeight: "450px",
            width: "100%",
            margin: "auto",
          }}
        >
          <BarChartComponent
            title="Website Infringement by Reporting Date"
            data={reporting}
            openModel={() => handleOpenModal("Reporting")}
            reporting={true}
          />
        </div>
      </div>
      {/* modal */}
      <UniversalModal
        title={
          modelType === "Reporting" && "Website Infringement by Reporting Date"
        }
        body={
          <div
            className="card-body p-0 text-center"
            style={{ height: "100%", minHeight: "500px" }}
          >
            {modelType === "Reporting" && (
              <BarChartComponent
                data={reporting}
                modal={true}
                reporting={true}
              />
            )}
          </div>
        }
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        size="xl"
        centered={true}
      />
    </>
  );
};

export default WebsiteDashboard;
