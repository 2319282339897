import React from "react";
import Footer from "../../common/Footer.js";
import AdminSideNav from "../../common/AdminSideNav.js";
import AnalystSideNav from "../../common/AnalystSideNav.js";
import UnauthorisedPage from "../Unauthorised";
import TopNav from "../../common/TopNav.js";
import { connect } from "react-redux";
import Select from "react-select";
import requireAuth from "../../hoc/requireAuth";
import authorizeAnalystOrAdmin from "../../hoc/authorizeAnalystOrAdmin";
import DateRangePicker from "react-bootstrap-daterangepicker";
import * as moment from "moment";
import "sweetalert/dist/sweetalert.css";
import { withAlert } from "react-alert";
import "../../assets/css/data-upload.css";
import debounce from "lodash.debounce";
import { Table } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { InputGroup, FormControl } from "react-bootstrap";
import {
  getInfrigementStatusText,
  fetchInfrigementEcommerceStatus,
} from "./../../infrigementStatusCode";

class LogSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authorised: true,
      platform: "",
      showDataSavedAlert: false,
      selectedOption: null,
      companies: [],
      users: [],
      brands: [],
      emailId: "",
      username: "",
      startDate: moment().subtract(30, "days").format("DD/MM/YYYY"),
      endDate: moment().format("DD/MM/YYYY"),
      company: "",
      brand: "",
      dataSaveError: {},
      locale: { format: "DD/MM/YYYY" },
      logData: [],
      pagination: {
        page: 1,
        nextPage: 1,
        prevPage: null,
        hasNextPage: true,
        hasPrevPage: false,
        pagingCounter: 1,
        totalDocs: null,
        totalPages: null,
      },
      perPage: 10,
      isLoading: false,
      hasMoreDomainData: true,
      selectedKey: "",
      searchTerm: "",
      selectedFromStatus: "",
      selectedToStatus: "",
    };
    this.listofRecords = debounce(this.listofRecords.bind(this), 500);
    window.onscroll = debounce(() => {
      const {
        listofRecords,
        state: { hasMoreDomainData },
      } = this;

      if (!hasMoreDomainData) {
        return;
      }

      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        listofRecords();
      }
    }, 100);
  }

  statusFromChange = (event) => {
    this.setState({ selectedFromStatus: event.target.value });
  };

  statusToChange = (event) => {
    this.setState({ selectedToStatus: event.target.value });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.company !== this.state.company) {
      this.getBrands();
      this.getEmailId();
    }

    if (this.state.brand && this.state.brand !== prevState.brand) {
      let selectedBrand = this.state.brands.filter(
        (brand) => brand.id === this.state.brand
      );

      if (selectedBrand.length) {
        let platform = selectedBrand[0].platform;
        this.setState(() => ({
          availablePlatforms: platform,
        }));
      } else {
        this.setState(() => ({
          availablePlatforms: null,
        }));
      }
    }
  }

  componentDidMount() {
    this.getCompanies();
    this.getUsers();
    this.listofRecords();
  }

  async getCompanies() {
    await window.axios
      .get("/company", {
        params: {
          perPage: 100,
        },
      })
      .then((res) => {
        this.setState({
          companies: res.data.docs,
        });
      });
  }

  async getUsers() {
    await window.axios
      .get("/users", {
        params: {
          page: 1,
          perPage: 100,
          sortDirection: 1,
        },
      })
      .then((res) => {
        this.setState({
          users: res.data.docs,
        });
      });
  }

  async getBrands() {
    await window.axios
      .get("/brand", {
        params: {
          disabledBrands: true,
          company: this.state.company,
          page: 1,
          perPage: 800,
        },
      })
      .then((res) => {
        res.data.docs.push({ brandName: "All" });
        this.setState({
          brands: res.data.docs,
        });
      });
  }

  async getEmailId() {
    await window.axios
      .get("/users/getEmailId", {
        params: {
          page: 1,
          perPage: 400,
        },
      })
      .then((res) => {
        this.setState({
          emailId: res.data.data,
        });
      });
  }

  resetSearch() {
    this.setState(
      {
        brand: "",
        company: "",
        platform: "",
        searchTerm: "",
        username: "",
        startDate: moment().subtract(30, "days").format("DD/MM/YYYY"),
        endDate: moment().format("DD/MM/YYYY"),
        brands: [],
        selectedFromStatus: "",
        selectedToStatus: "",
        pagination: {
          page: 1,
          nextPage: 1,
          prevPage: null,
          hasNextPage: true,
          hasPrevPage: false,
          pagingCounter: 1,
          totalDocs: null,
          totalPages: null,
        },
        logData: [],
      },
      () => {
        this.logRecordSearch(true);
      }
    );
  }

  async logRecordSearch(reArrangeRecords = false) {
    let reqData = {
      page: this.state.pagination.page,
      perPage: this.state.perPage,
    };

    reqData.sortBy = this.state.sortBy;
    reqData.sortDirection = this.state.sortDirection;
    if (reArrangeRecords) {
      reqData.page = 1;
    }
    reqData.search = this.state.search;

    this.setState(() => ({ isLoading: true }));
    reqData.company = this.state.company;
    reqData.brand = this.state.brand;
    reqData.platform = this.state.platform;
    reqData.startDate = this.state.startDate;
    reqData.endDate = this.state.endDate;
    reqData.username = this.state.username;
    reqData.searchTerm = this.state.searchTerm;
    reqData.selectedFromStatus = this.state.selectedFromStatus;
    reqData.selectedToStatus = this.state.selectedToStatus;
    await window.axios
      .get("/data-log/searchLogRecord", {
        params: reqData,
      })
      .then((res) => {
        this.setState(() => ({
          logData: res.data.docs,
          isLoading: false,
          pagination: {
            page: res.data.page,
            nextPage: res.data.nextPage,
            prevPage: res.data.prevPage,
            hasNextPage: res.data.hasNextPage,
            hasPrevPage: res.data.hasPrevPage,
            pagingCounter: res.data.pagingCounter,
            totalDocs: res.data.totalDocs,
            totalPages: res.data.totalPages,
          },
          hasMoreDomainData: res.data.hasNextPage,
        }));
      });
  }

  async listofRecords(reArrangeRecords = false) {
    let reqData = {
      page: this.state.pagination.nextPage,
      perPage: this.state.perPage,
    };

    if (reArrangeRecords) {
      reqData.page = 1;
    }

    this.setState(() => ({ isLoading: true }));
    if (this.state.companies) {
      reqData.company = this.state.company;
    }
    reqData.brand = this.state.brand || null;

    await window.axios
      .get("/data-log/searchLogRecord", {
        params: reqData,
      })
      .then((res) => {
        let LogSearchData = [...this.state.logData].concat(res.data.docs);

        if (reArrangeRecords) {
          LogSearchData = res.data.docs;
        }

        this.setState(() => ({
          logData: LogSearchData,
          isLoading: false,
          pagination: {
            page: res.data.page,
            nextPage: res.data.nextPage,
            prevPage: res.data.prevPage,
            hasNextPage: res.data.hasNextPage,
            hasPrevPage: res.data.hasPrevPage,
            pagingCounter: res.data.pagingCounter,
            totalDocs: res.data.totalDocs,
            totalPages: res.data.totalPages,
          },
          hasMoreDomainData: res.data.hasNextPage,
        }));
      });
  }

  renderLoader() {
    if (this.state.isLoading) {
      return (
        <div className="text-center">
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={10}
            width={200}
            timeout={5000}
          />
        </div>
      );
    }
  }

  getDateFilters() {
    return {
      startDate: moment(this.state.startDate, "DD/MM/YYYY"),
      endDate: moment(this.state.endDate, "DD/MM/YYYY"),
      locale: this.state.locale,
    };
  }

  handleCallback = (event, picker) => {
    this.setState({
      startDate: picker.startDate.format("DD/MM/YYYY"),
      endDate: picker.endDate.format("DD/MM/YYYY"),
    });
  };

  handleChange = (selectedOption) => {
    if (selectedOption?.label?.toUpperCase() === "ALL") {
      this.setState({ brand: "ALL" });
    } else {
      this.setState({
        brand: selectedOption?.value || "",
      });
    }
  };

  changePlatformSelectBox(e) {
    let selectedKeyValue = "domainKeys";
    let key = e.target.value;

    if (key === "website") selectedKeyValue = "websiteKeys";
    if (key === "social-media") selectedKeyValue = "socialMediaKeys";
    if (key === "e-commerce") selectedKeyValue = "ecommerceKeys";
    if (key === "app-store") selectedKeyValue = "appStoreKeys";

    this.setState({
      platform: e.target.value,
      selectedKey: selectedKeyValue,
    });
  }

  renderHtml() {
    const statusOptions = fetchInfrigementEcommerceStatus();
    if (!this.state.authorised) return <UnauthorisedPage />;

    const options = this.state.brands.map((brand) => ({
      value: brand.id,
      label: brand.brandName,
    }));

    return (
      <div className="row">
        {this.props?.login?.user?.role === "Admin" ? (
          <AdminSideNav />
        ) : (
          <AnalystSideNav />
        )}

        <main
          id="bulk-data-download-screen"
          className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3"
        >
          <TopNav />

          <div className="main-content-container container-fluid px-4">
            <div className="page-header row no-gutters py-4">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-sm-4 text-sm-left mb-0">
                    <span className="text-uppercase page-subtitle">
                      Log Search
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card card-small mb-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group">
                              <span className="text-muted d-block mb-2">
                                Company
                              </span>
                              <select
                                onChange={(e) =>
                                  this.setState({ company: e.target.value })
                                }
                                className="form-control"
                                value={this.state.company}
                              >
                                <option value="">Select from dropdown</option>
                                {this.state.companies.map(
                                  (company, companyIndex) => (
                                    <option
                                      key={companyIndex}
                                      value={company.id}
                                    >
                                      {company.name}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <span className="text-muted d-block mb-2">
                                Brand
                              </span>
                              <Select
                                options={options}
                                onChange={this.handleChange}
                                value={options.find(
                                  (option) => option.value === this.state.brand
                                )}
                                isClearable={true}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <span className="text-muted d-block mb-2">
                                Platform
                              </span>
                              <select
                                value={this.state.platform}
                                onChange={(e) =>
                                  this.changePlatformSelectBox(e)
                                }
                                className="form-control"
                              >
                                <option value="">Select from dropdown</option>
                                {(this.state.availablePlatforms?.domain ||
                                  !this.state.availablePlatforms) && (
                                  <option value="domain">Domain</option>
                                )}
                                {(this.state.availablePlatforms?.website ||
                                  !this.state.availablePlatforms) && (
                                  <option value="website">Website</option>
                                )}
                                {(this.state.availablePlatforms?.socialMedia ||
                                  !this.state.availablePlatforms) && (
                                  <option value="social-media">
                                    Social Media
                                  </option>
                                )}
                                {(this.state.availablePlatforms?.eCommerce ||
                                  !this.state.availablePlatforms) && (
                                  <option value="e-commerce">E-commerce</option>
                                )}
                                {(this.state.availablePlatforms?.appStore ||
                                  !this.state.availablePlatforms) && (
                                  <option value="app-store">App Store</option>
                                )}
                                <option value="tmch">TMCH</option>
                                <option value="trap-purchase">
                                  Trap Purchase
                                </option>
                              </select>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group">
                              <label className="text-muted mb-2">
                                Date Range
                              </label>
                              <DateRangePicker
                                className="w-100"
                                onApply={this.handleCallback}
                                initialSettings={this.getDateFilters()}
                                alwaysShowCalendars={true}
                              >
                                <input className="form-control" />
                              </DateRangePicker>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group">
                              <span className="text-muted d-block mb-2">
                                Username
                              </span>
                              <select
                                onChange={(e) =>
                                  this.setState({ username: e.target.value })
                                }
                                className="form-control"
                                value={this.state.username}
                              >
                                <option value="">Select from dropdown</option>
                                {this.state.users.map((user, userIndex) => (
                                  <option key={userIndex} value={user.id}>
                                    {user.username}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <span className="text-muted d-block mb-2">
                                Search Term
                              </span>
                              <InputGroup className="">
                                <FormControl
                                  placeholder=""
                                  aria-label="Search"
                                  value={this.state.searchTerm}
                                  onChange={(event) =>
                                    this.setState({
                                      searchTerm: event.target.value,
                                    })
                                  }
                                />
                              </InputGroup>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label className="text-muted mb-2">
                                Status From
                              </label>
                              <select
                                className="form-control"
                                value={this.state.selectedFromStatus}
                                onChange={this.statusFromChange}
                              >
                                {statusOptions.map((status) => (
                                  <option key={status.code} value={status.code}>
                                    {status.text}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <span className="text-muted d-block mb-2">
                                Status To
                              </span>
                              <select
                                className="form-control"
                                value={this.state.selectedToStatus}
                                onChange={this.statusToChange}
                              >
                                {statusOptions.map((status) => (
                                  <option key={status.code} value={status.code}>
                                    {status.text}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="d-flex gap-4">
                              <div className="form-group">
                                <button
                                  onClick={(e) => this.logRecordSearch(true)}
                                  className="mt-4 btn btn-ldotr"
                                >
                                  Fetch Log
                                </button>
                              </div>
                              <div className="form-group">
                                <button
                                  onClick={() => this.resetSearch()}
                                  className="mt-4 btn btn-ldotr"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card card-small mb-4">
                  <div className="card-body p-0">
                    <div
                      className="table-responsive"
                      style={{ overflowX: "auto" }}
                    >
                      <Table
                        hover
                        width="100%"
                        id="queue-table"
                        className="table mb-0"
                      >
                        <thead className="bg-light uppercase-th">
                          <tr>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ width: "4%" }}
                            >
                              Sr.
                            </th>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ width: "12%" }}
                            >
                              Company
                            </th>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ width: "8%" }}
                            >
                              Brand
                            </th>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ width: "10%" }}
                            >
                              Platform
                            </th>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ width: "18%" }}
                            >
                              Username
                            </th>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ width: "20%" }}
                            >
                              URL/Domain
                            </th>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ width: "8%" }}
                            >
                              Date & Time
                            </th>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ width: "10%" }}
                            >
                              From Status
                            </th>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ width: "10%" }}
                            >
                              To Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state?.logData.map((log, key) => (
                            <tr
                              key={key}
                              data-toggle="collapse"
                              data-target={".multi-collapse" + key}
                              aria-controls={"multiCollapseExample" + key}
                            >
                              <td className="text-nowrap">{key + 1}</td>
                              <td className="text-nowrap">
                                {log.company?.name || ""}
                              </td>
                              <td
                                className="text-nowrap"
                                title={log.brand?.brandName || ""}
                              >
                                {log.brand?.brandName || ""}
                              </td>
                              <td
                                className="text-nowrap"
                                title={log.module || ""}
                              >
                                {log.module || ""}
                              </td>
                              <td
                                className="text-nowrap"
                                title={log.reqUserId?.username || ""}
                              >
                                {log.reqUserId?.username
                                  ? log.reqUserId?.username.length < 24
                                    ? log.reqUserId?.username
                                    : `${log.reqUserId?.username.substring(
                                        0,
                                        24
                                      )}...`
                                  : ""}
                              </td>
                              <td
                                className="text-nowrap"
                                title={log.searchTerm || ""}
                              >
                                {log.searchTerm
                                  ? log.searchTerm.length < 24
                                    ? log.searchTerm
                                    : `${log.searchTerm.substring(0, 24)}...`
                                  : ""}
                              </td>
                              <td
                                className="text-nowrap"
                                title={log.createdAt || ""}
                              >
                                {log.createdAt
                                  ? new Date(log.createdAt).toLocaleString(
                                      "en-GB",
                                      {
                                        timeZone: "UTC",
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                      }
                                    )
                                  : ""}
                              </td>
                              <td
                                className="text-nowrap"
                                title={getInfrigementStatusText(log.statusFrom)}
                              >
                                {log.statusFrom &&
                                  getInfrigementStatusText(log.statusFrom)}
                              </td>
                              <td
                                className="text-nowrap"
                                title={getInfrigementStatusText(log.statusTo)}
                              >
                                {log.statusTo &&
                                  getInfrigementStatusText(log.statusTo)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                {this.state.hasMoreDomainData ? (
                  <div className="text-center">
                    <a
                      href="#"
                      className="text-danger"
                      onClick={(e) => this.listofRecords()}
                    >
                      {this.renderLoader()} Load more...
                    </a>
                  </div>
                ) : (
                  <div className="text-center">
                    <label className="text-muted">No more data to show.</label>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </div>
    );
  }

  render() {
    return <>{this.renderHtml()}</>;
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  login: state.login,
  response: state.response,
});

export default withAlert()(
  connect(mapStateToProps)(authorizeAnalystOrAdmin(requireAuth(LogSearch)))
);
