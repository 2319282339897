import React from "react";
import Footer from "../../common/Footer.js";
import AnalystSideNav from "../../common/AnalystSideNav.js";
import AdminSideNav from "../../common/AdminSideNav.js";
import Select from "react-select";

import UnauthorisedPage from "../Unauthorised";
import TopNav from "../../common/TopNav.js";
import { Button, Modal, Form, Table, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import requireAuth from "../../hoc/requireAuth";
import authorizeAnalystOrAdmin from "../../hoc/authorizeAnalystOrAdmin";
import { withAlert } from "react-alert";
import {
  SUCCESS,
  IN_PROGRESS,
  AWAITING_APPROVAL,
  APPROVED,
  NO_FURTHER_ACTION,
  NO_ACTION,
  getInfrigementStatusText,
  fetchInfrigementAllStatus,
  PURCHASE,
  PURCHASE_IN_PROGRESS,
  PURCHASE_IN_COMPLETE,
  CLIENT_REVIEW,
  PURCHASE_INCOMPLETE_PRODUCT_NOT_AVAILABLE,
  PURCHASE_INCOMPLETE_SELLER_NOT_RESPONSIVE,
  SELF_RESOLVED,
} from "../../../src/infrigementStatusCode";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";
import SweetAlert from "sweetalert-react";
import "sweetalert/dist/sweetalert.css";
import "sweetalert2/src/sweetalert2.scss";
import * as moment from "moment";
import {
  castToServerDateFormat,
  castToDefaultDateFormat,
} from "../../settings/index";
import { getNewSortOrder } from "../../helpers.js";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import PaginationComponentV1 from "../../common/PaginationComponentV1.js";

const paginationInitialState = {
  totalDocs: 0,
  totalPages: 0,
  page: 1,
  pagingCounter: 1,
  hasPrevPage: false,
  hasNextPage: true,
  prevPage: null,
  nextPage: 1,
};
class Enforcement extends React.Component {
  constructor(props) {
    super(props);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.showEnforcementData = this.showEnforcementData.bind(this);
    this.showAddEnforcementPopup = this.showAddEnforcementPopup.bind(this);
    this.handleCloseAddEnforcementModal =
      this.handleCloseAddEnforcementModal.bind(this);
    this.saveEnforcementData = this.saveEnforcementData.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
    this.updateInfrigementCheckStatus =
      this.updateInfrigementCheckStatus.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      sorting: {
        sortBy: "",
        sortIconClass: "sort-icon-nutral",
        sortOrder: "",
      },
      checkedInfrigements: [],
      registrarFilter: "",
      ipAddressFilter: "",
      infrigementStatusFilter: "",
      site: "",
      hostingFilter: "",
      actionTakenFilter: "",
      infrigementStatus: null,
      enforcementData: [],
      currentInfrigementId: null,
      showModal: false,
      showAddEnforcementModal: false,
      authorised: true,
      platform: "",
      showDataSavedAlert: false,
      showFilesUploadedAlert: false,
      uploadedFiles: {},
      excelData: [],
      companies: [],
      brands: [],
      reportingDate: "",
      company: "",
      brand: "",
      files: [],
      errorMessage: null,
      filters: {},
      availablePlatforms: null,
      isDisable: false,
      domainKeys: [
        "image",
        "domain",
        "tld",
        "priority",
        "status",
        "advertiser",
        "reason",
        "reasonCode",
        "registrar",
      ],
      websiteKeys: [
        "image",
        "domain",
        "url",
        "reportingDate",
        "priority",
        "status",
        "advertiser",
        "reason",
        "reasonCode",
      ],
      socialMediaKeys: [
        "image",
        "platform",
        "heading",
        "link",
        "reportingDate",
        "priority",
        "status",
        "advertiser",
        "reason",
        "reasonCode",
      ],
      ecommerceKeys: [
        "image",
        "site",
        "reportingDate",
        "url",
        "title",
        "status",
        "priority",
        "price",
        "reason",
        "reasonCode",
      ],
      appStoreKeys: [
        "image",
        "appName",
        "appStore",
        "reportingDate",
        "url",
        "developerName",
        "latestPublishDate",
        "status",
        "reason",
        "reasonCode",
        "priority",
        "price",
      ],
      selectedKey: "",
      availableReportingDate: [],
      eCommerceSiteData: null,
      pagination: paginationInitialState,
      perPage: 50,
    };
  }

  showAddEnforcementPopup(data, index = null) {
    this.fetchLastEnforcementData(data);
    this.setState(() => {
      return {
        showAddEnforcementModal: true,
        currentInfrigementId: data?.id,
        currentInfrigementIndex: index,
      };
    });
  }
  ////    LDOTR-9 Start   ////
  //Bulk enforcement for Domain, Website, AppStore and Social-Media
  showBulkEnforcementPopup(disable) {
    this.setState(() => {
      return {
        showAddEnforcementModal: true,
        isDisable: disable,
      };
    });
  }
  ////    LDOTR-9 End   ////

  changeSorting(sortBy) {
    this.setState((prevState) => {
      const { sortOrder, classPostFix } = getNewSortOrder(prevState);

      return {
        sorting: {
          sortBy,
          sortIconClass: `sort-icon-${classPostFix}`,
          sortOrder,
        },
      };
    });
  }

  async fetchLastEnforcementData(data) {
    if (!data) {
      this.setState(() => ({
        date: castToServerDateFormat(moment()),
      }));
      return;
    }

    let infrigementId = data?.id;

    let platform = this.state.platform;

    let url = `/enforcement/get-last-enforcement-record`;

    await window.axios
      .get(url, {
        params: {
          platform,
          infrigementId,
        },
      })
      .then((response) => {
        let lastInfrigement = response.data;

        this.setState(() => ({
          registrar: lastInfrigement?.registrar,
          ipAddress: lastInfrigement?.ipAddress,
          hosting: lastInfrigement?.hosting,
          actionTaken: lastInfrigement?.actionTaken,
          date: castToServerDateFormat(moment()), //castToServerDateFormat(lastInfrigement?.date),
          infrigementStatus: data?.status,
        }));
      })
      .catch((error) => {
        if (error.response.status === 422) {
          this.setState(() => {
            return {
              errorMessage: error.response.data?.errors,
            };
          });
        }
      });
  }

  async saveEnforcementData() {
    let platform = this.state.platform;

    if (platform === "e-commerce") {
      platform = "ecommerce";
    }

    const dataId = this.state.currentInfrigementId;

    let url = `/${platform}/${dataId}/enforcement`;

    let params = {
      registrar: this.state.registrar,
      ipAddress: this.state.ipAddress,
      hosting: this.state.hosting,
      actionTaken: this.state.actionTaken,
      date: this.state.date,
      infrigementStatus: this.state.infrigementStatus,
    };

    if (!dataId && this.state.checkedInfrigements.length > 0) {
      url = `/${platform}/bulk/enforcement`;
      params.recordIds = this.state.checkedInfrigements;
    }

    await window.axios
      .post(url, params)
      .then((response) => {
        this.props.alert.show(response.data.message);

        // when there is bulk update
        if (!dataId && this.state.checkedInfrigements.length > 0) {
          this.fetchData();
          this.handleCloseAddEnforcementModal();
          return;
        }

        let data = this.state.excelData;

        data[this.state.currentInfrigementIndex]["enforcementData"] =
          response.data.data;
        data[this.state.currentInfrigementIndex]["status"] =
          this.state.infrigementStatus;

        this.setState(() => {
          return {
            excelData: data,
            errorMessage: null,
            registrar: null,
            ipAddress: null,
            hosting: null,
            actionTaken: null,
            date: null,
            infrigementStatus: null,
          };
        });

        this.handleCloseAddEnforcementModal();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          this.setState(() => {
            return {
              errorMessage: error.response.data?.errors,
            };
          });
        }
      });
  }

  async fetchEnforcement(dataId) {
    let platform = this.state.platform;

    if (platform === "e-commerce") {
      platform = "ecommerce";
    }

    let url = `/${platform}/${dataId}/enforcement`;

    await window.axios.get(url, {}).then((res) => {
      this.setState(() => {
        return { enforcementData: res.data.data };
      });
    });
  }

  async changeInputBox(e, column) {
    let state = {};
    state[column] = e.target.value;
    await this.setState(() => {
      return state;
    });
  }

  showEnforcementData(dataId) {
    this.setState(() => {
      return {
        showModal: true,
      };
    });

    this.fetchEnforcement(dataId?.id);
  }

  handleCloseAddEnforcementModal() {
    this.setState(() => {
      return {
        showAddEnforcementModal: false,
        currentInfrigementId: null,
        errorMessage: null,
        currentInfrigementIndex: null,
        registrar: "",
        ipAddress: "",
        hosting: "",
        actionTaken: "",
        date: "",
        infrigementStatus: "",
        checkedInfrigements: [],
        isDisable: false,
      };
    });
  }

  handleCloseModal() {
    this.setState(() => {
      return {
        showModal: false,
      };
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.reportingDate !== prevState.reportingDate) {
      if (this.state.platform === "e-commerce") {
        this.fetchAvailableECommerceSiteData();
      }
    }

    if (this.state.pagination.page !== prevState.pagination.page) {
      if (!this.state.platform) return;
      this.fetchData();
    }

    if (this.state.perPage !== prevState.perPage) {
      if (!this.state.platform) return;
      this.fetchData();
    }

    if (prevState.company !== this.state.company) {
      this.getBrands();

      if (this.state.platform) {
        if (!this.state.company) return;
        this.fetchAvailableReportingDate();
      }

      if (this.state.platform === "e-commerce") {
        if (!this.state.company) return;
        this.fetchAvailableECommerceSiteData();
      }
    }

    if (prevState.platform !== this.state.platform) {
      if (this.state.platform) {
        if (!this.state.company) return;
        this.fetchAvailableReportingDate();
      }

      if (this.state.platform === "e-commerce") {
        if (!this.state.company) return;
        this.fetchAvailableECommerceSiteData();
      }
    }

    if (
      prevState.availablePlatforms !== this.state.availablePlatforms &&
      this.state.availablePlatforms
    ) {
      const platform = this.state.availablePlatforms;

      let selectedPlatform = "domain";

      if (platform.appStore) {
        selectedPlatform = "app-store";
      }

      if (platform.eCommerce) {
        selectedPlatform = "e-commerce";
      }

      if (platform.socialMedia) {
        selectedPlatform = "social-media";
      }

      if (platform.website) {
        selectedPlatform = "website";
      }

      this.changePlatformSelectBox(selectedPlatform);
    }

    if (prevState.brand !== this.state.brand) {
      if (this.state.platform) {
        if (!this.state.company) return;
        this.fetchAvailableReportingDate();
      }

      if (this.state.platform === "e-commerce") {
        if (!this.state.company) return;
        this.fetchAvailableECommerceSiteData();
      }

      if (this.state.brand) {
        let selectedBrand = this.state.brands.filter(
          (brand) => brand.id === this.state.brand
        );

        if (selectedBrand.length) {
          let platform = selectedBrand[0].platform;

          this.setState(() => ({
            availablePlatforms: platform,
          }));
        }
      } else {
        this.setState(() => ({
          availablePlatforms: null,
        }));
      }
    }

    if (
      prevState.sorting.sortBy !== this.state.sorting.sortBy ||
      prevState.sorting.sortOrder !== this.state.sorting.sortOrder
    ) {
      this.fetchData();
    }
  }

  componentDidMount() {
    this.changePlatformSelectBox("domain");
    this.getCompanies();
  }

  async getCompanies() {
    await window.axios
      .get("/company", {
        params: {
          perPage: 100,
        },
      })
      .then((res) => {
        this.setState({
          companies: res.data.docs,
        });
      });
  }

  async getBrands() {
    await window.axios
      .get("/brand", {
        params: {
          disabledBrands: true,
          company: this.state.company,
          page: 1,
          perPage: 800, //JiraId :- LDOTR-3
        },
      })
      .then((res) => {
        this.setState({
          brands: res.data.docs,
        });
      });
  }

  async fetchData() {
    const options = {
      headers: { "Content-Type": "application/json" },
    };

    await window.axios
      .post(
        `/save-bulk-data/fetch-data-for-enforcement?limit=${this.state.perPage}&page=${this.state.pagination.page}`,
        {
          company: this.state.company,
          brand: this.state.brand,
          reportingDate: this.state.reportingDate,
          platform: this.state.platform,
          status: this.state.infrigementStatusFilter,
          sortBy: this.state.sorting.sortBy,
          sortOrder: this.state.sorting.sortOrder,
          registrar: this.state.registrarFilter,
          ipAddress: this.state.ipAddressFilter,
          hosting: this.state.hostingFilter,
          actionTaken: this.state.actionTakenFilter,
          site: this.state.site,
          ...this.state.filters,
        },
        options
      )
      .then((res) => {
        const { data, count, totalPages, currentPage } = res.data;

        this.setState({
          excelData: data || [],
          pagination: {
            ...this.state.pagination,
            totalDocs: count,
            totalPages,
            page: currentPage,
            pagingCounter: (currentPage - 1) * this.state.perPage + 1,
            hasPrevPage: currentPage > 1,
            hasNextPage: currentPage < totalPages,
            prevPage: currentPage > 1 ? currentPage - 1 : null,
            nextPage: currentPage < totalPages ? currentPage + 1 : null,
          },
        });
      })
      .catch(function (error) {
        alert(error.response.data.message);
      });
  }

  handleChange = (selectedOption) => {
    const selectedBrand = this.state.brands.find(
      (brand) => brand.id === selectedOption.value
    );

    this.setState({
      brand: selectedOption.value,
      brandId: selectedBrand ? selectedBrand.id : null,
    });
  };

  renderDomainComponentImage(domainKey, row, rowKey) {
    if (domainKey === "image") {
      return (
        <>
          <img width="50px" src={row.imageUrl} alt="image" />
        </>
      );
    } else if (domainKey === "status") {
      return (
        <span
          title={getInfrigementStatusText(row[domainKey])}
          className="bind-width"
        >
          {getInfrigementStatusText(row[domainKey])}
        </span>
      );
    } else if (domainKey === "domain") {
      return (
        <a
          base
          className="bind-width"
          title={row[domainKey]}
          rel="noreferrer"
          target="_blank"
          href={`//${row[domainKey]}`}
        >
          {row[domainKey]}
        </a>
      );
    } else if (domainKey === "link") {
      return (
        <a
          base
          className="bind-width"
          title={row[domainKey]}
          rel="noreferrer"
          target="_blank"
          href={`${row[domainKey]}`}
        >
          {row[domainKey]}
        </a>
      );
    } else if (domainKey === "url") {
      return (
        <a
          base
          className="bind-width"
          title={row[domainKey]}
          rel="noreferrer"
          target="_blank"
          href={`${row[domainKey]}`}
        >
          {row[domainKey]}
        </a>
      );
    } else if (domainKey === "reportingDate") {
      return (
        <span
          title={castToDefaultDateFormat(row[domainKey])}
          className="bind-width"
        >
          {castToDefaultDateFormat(row[domainKey])}
        </span>
      );
    } else {
      return (
        <span title={row[domainKey]} className="bind-width">
          {row[domainKey]}
        </span>
      );
    }
  }

  changeBrandSelectBox(e) {
    this.setState((prevState) => ({
      brand: e.target.value,
      pagination: paginationInitialState,
    }));
  }

  changeCompanySelectBox(e) {
    this.setState((prevState) => ({
      company: e.target.value,
      brand: "",
      brands: [],
      pagination: paginationInitialState,
    }));
  }

  changePlatformSelectBox(key) {
    let selectedKeyValue = "domainKeys";

    if (key === "website") {
      selectedKeyValue = "websiteKeys";
    }
    if (key === "social-media") {
      selectedKeyValue = "socialMediaKeys";
    }
    if (key === "e-commerce") {
      selectedKeyValue = "ecommerceKeys";
    }
    if (key === "app-store") {
      selectedKeyValue = "appStoreKeys";
    }

    this.setState(() => ({
      platform: key,
      excelData: [],
      selectedKey: selectedKeyValue,
      registrarFilter: "",
      ipAddressFilter: "",
      hostingFilter: "",
      actionTakenFilter: "",
      availableReportingDate: [],
      filters: {},
      checkedInfrigements: [],
    }));
  }

  async fetchAvailableECommerceSiteData() {
    const options = {
      headers: { "Content-Type": "application/json" },
    };
    await window.axios
      .post(
        "/save-bulk-data/fetch-eCommerce-site-data",
        {
          company: this.state.company,
          brand: this.state.brand,
          platform: this.state.platform,
          reportingDate: this.state.reportingDate,
          status: this.state.infrigementStatusFilter,
        },
        options
      )
      .then((res) => {
        this.setState({ eCommerceSiteData: res.data?.sites || [] });
      })
      .catch(function (error) {
        alert(error.response.data.message);
      });
  }

  async fetchAvailableReportingDate() {
    const options = {
      headers: { "Content-Type": "application/json" },
    };

    await window.axios
      .post(
        "/save-bulk-data/fetch-available-reporting-dates",
        {
          company: this.state.company,
          brand: this.state.brand,
          platform: this.state.platform,
        },
        options
      )
      .then((res) => {
        this.setState({ availableReportingDate: res.data });
      })
      .catch(function (error) {
        alert(error.response.data.message);
      });
  }

  printEnforcementData(row, rowKey) {
    return (
      <>
        <h4>
          Enforcement Data
          <a
            title="Add Enforcement"
            href="javascript:;"
            className="px-1 text-danger pull-right"
            onClick={(e) => this.showAddEnforcementPopup(row, rowKey)}
          >
            <i className="fa fa-plus"></i>
          </a>
        </h4>
        {this.state.platform === "e-commerce" &&
          row.clientComment &&
          row.clientComment.length && (
            <span>
              <strong>Client Comment: </strong>
              {row.clientComment}
            </span>
          )}
        <Table>
          <thead>
            <tr>
              {(this.state.platform === "domain" ||
                this.state.platform === "website") && (
                <th className="border-0">Registrar</th>
              )}
              {(this.state.platform === "domain" ||
                this.state.platform === "website") && (
                <th className="border-0">IP Address</th>
              )}
              {(this.state.platform === "domain" ||
                this.state.platform === "website") && (
                <th className="border-0">Hosting</th>
              )}
              <th className="border-0">Action Taken</th>
              <th className="border-0">Date</th>
            </tr>
          </thead>
          <tbody style={{ background: "#a1a1" }}>
            {row.enforcementData.map((enforcement, key) => (
              <tr key={key}>
                {(this.state.platform === "domain" ||
                  this.state.platform === "website") && (
                  <td>{enforcement.registrar}</td>
                )}
                {(this.state.platform === "domain" ||
                  this.state.platform === "website") && (
                  <td>{enforcement.ipAddress}</td>
                )}
                {(this.state.platform === "domain" ||
                  this.state.platform === "website") && (
                  <td>{enforcement.hosting}</td>
                )}
                <td>{enforcement.actionTaken}</td>
                <td>{moment(enforcement.date).format("D-M-Y")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }

  changeFilterValue(columnName, value) {
    let filters = this.state.filters;

    filters[columnName] = value;

    this.setState(() => ({
      filters,
    }));
  }

  renderFilterPopover(columnName) {
    return (
      <Popover id="popover-positioned-right" title="Popover right">
        <div className="container py-1 px-1" style={{ width: "100%" }}>
          <div className="row">
            <div className="col-7 mx-0">
              <input
                onChange={(e) =>
                  this.changeFilterValue(columnName, e.target.value)
                }
                value={this.state.filters[columnName]}
                className="form-control"
              />
            </div>
            <div className="col-5 mx-0">
              <button
                onClick={(e) => this.fetchData()}
                className="btn btn-success"
              >
                Apply Filter
              </button>
            </div>
          </div>
        </div>
      </Popover>
    );
  }

  toggleSelectAll = (e) => {
    if (e.target.checked) {
      // Select All: Add all row IDs to the checkedInfrigements array
      const allRowIds = this.state.excelData.map((row) => row.id);
      this.setState({ checkedInfrigements: allRowIds });
    } else {
      // Deselect All: Clear the checkedInfrigements array
      this.setState({ checkedInfrigements: [] });
    }
  };

  updateInfrigementCheckStatus(e, id) {
    const checkedInfrigements = new Set(this.state.checkedInfrigements);
    if (e.target.checked) {
      checkedInfrigements.add(id);
    } else {
      if (checkedInfrigements.has(id)) {
        checkedInfrigements.delete(id);
      }
    }

    this.setState(() => ({
      checkedInfrigements: [...checkedInfrigements],
    }));
  }

  domainComponent() {
    if (this.state.platform === "domain") {
      return (
        <div className="col">
          <div className="card card-small mb-4">
            <div className="card-body">
              <h5 className="card-title">
                Domain
                {this.state.checkedInfrigements.length > 1 && (
                  <a
                    onClick={(e) => this.showBulkEnforcementPopup(true)}
                    className="float-end"
                    href="#"
                  >
                    Bulk enforcement
                  </a>
                )}
              </h5>
              <div className="row">
                <div className="col-md-12">
                  <div>
                    <Table width="100%" className="table-responsive">
                      <thead>
                        <tr>
                          <th scope="col" width="3%">
                            #
                          </th>
                          <th width="3%">IMAGE</th>
                          <th width="3%">
                            <span
                              onClick={(e) => this.changeSorting("domain")}
                              className={`border-0 ${
                                this.state.sorting.sortBy === "domain"
                                  ? this.state.sorting.sortIconClass +
                                    " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                              }`}
                            >
                              DOMAIN
                            </span>
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("domain")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;<i className="fa fa-search"></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th width="3%">
                            <span
                              onClick={(e) => this.changeSorting("tld")}
                              className={`border-0 ${
                                this.state.sorting.sortBy === "tld"
                                  ? this.state.sorting.sortIconClass +
                                    " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                              }`}
                            >
                              TLD
                            </span>
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("tld")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;<i className="fa fa-search"></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th
                            width="3%"
                            onClick={(e) => this.changeSorting("priority")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "priority"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            PRIORITY
                          </th>
                          <th
                            width="3%"
                            onClick={(e) => this.changeSorting("status")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "status"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            STATUS
                          </th>
                          <th
                            width="3%"
                            onClick={(e) => this.changeSorting("advertiser")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "advertiser"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            ADVERTISER
                          </th>
                          <th
                            width="3%"
                            onClick={(e) => this.changeSorting("reason")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "reason"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            REASON
                          </th>
                          <th
                            width="3%"
                            onClick={(e) => this.changeSorting("reasonCode")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "reasonCode"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            REASON CODE
                          </th>
                          <th
                            width="3%"
                            onClick={(e) => this.changeSorting("registrar")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "registrar"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            REGISTRAR
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key="selectAll">
                          <div>
                            All
                            <br />
                            <input
                              type="checkbox"
                              checked={
                                this.state.excelData.length > 0 &&
                                this.state.checkedInfrigements.length ===
                                  this.state.excelData.length
                              }
                              onChange={this.toggleSelectAll}
                            />
                            {/* Rest of the headers */}
                          </div>
                        </tr>

                        {this.state.excelData.map((row, rowKey) => (
                          <>
                            <tr key={rowKey}>
                              <td>
                                <div>
                                  <input
                                    className="inline"
                                    type="checkbox"
                                    checked={
                                      this.state.checkedInfrigements.includes(
                                        row?.id
                                      )
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      this.updateInfrigementCheckStatus(
                                        e,
                                        row?.id
                                      )
                                    }
                                  />
                                </div>
                                <i
                                  data-toggle="collapse"
                                  data-target={".multi-collapse" + rowKey}
                                  aria-controls={
                                    "multiCollapseExampleDomain" + rowKey
                                  }
                                  title="Expend/Collapse row"
                                  class="fa fa-arrows-alt px-1 clickable inline"
                                  aria-hidden="true"
                                ></i>
                                {rowKey + 1}
                              </td>
                              {this.state[this.state.selectedKey].map(
                                (domainKey, domainKeyIndex) => (
                                  <td key={domainKeyIndex}>
                                    {this.renderDomainComponentImage(
                                      domainKey,
                                      row,
                                      rowKey
                                    )}
                                  </td>
                                )
                              )}
                            </tr>
                            <tr
                              className={"collapse multi-collapse" + rowKey}
                              id={"multiCollapseExampleDomain" + rowKey}
                            >
                              <td colSpan={7}>
                                {this.printEnforcementData(row, rowKey)}
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  websiteComponent() {
    if (this.state.platform === "website") {
      return (
        <div className="col">
          <div className="card card-small mb-4">
            <div className="card-body">
              <h5 className="card-title">
                Website
                {this.state.checkedInfrigements.length > 1 && (
                  <a
                    onClick={(e) => this.showBulkEnforcementPopup(true)}
                    className="float-end"
                    href="#"
                  >
                    Bulk enforcement
                  </a>
                )}
              </h5>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>IMAGE</th>
                          <th>
                            <span
                              onClick={(e) => this.changeSorting("domain")}
                              className={`border-0 ${
                                this.state.sorting.sortBy === "domain"
                                  ? this.state.sorting.sortIconClass +
                                    " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                              }`}
                            >
                              DOMAIN
                            </span>
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("domain")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;<i className="fa fa-search"></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>
                            <span
                              onClick={(e) => this.changeSorting("url")}
                              className={`border-0 ${
                                this.state.sorting.sortBy === "url"
                                  ? this.state.sorting.sortIconClass +
                                    " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                              }`}
                            >
                              URL
                            </span>
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("url")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;<i className="fa fa-search"></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th
                            onClick={(e) => this.changeSorting("dateAdded")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "dateAdded"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            DATE ADDED
                          </th>

                          <th
                            onClick={(e) => this.changeSorting("priority")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "priority"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            PRIORITY
                          </th>
                          <th
                            onClick={(e) => this.changeSorting("status")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "status"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            STATUS
                          </th>
                          <th
                            width="3%"
                            onClick={(e) => this.changeSorting("advertiser")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "advertiser"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            ADVERTISER
                          </th>
                          <th
                            width="3%"
                            onClick={(e) => this.changeSorting("reason")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "reason"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            REASON
                          </th>
                          <th
                            width="3%"
                            onClick={(e) => this.changeSorting("reasonCode")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "reasonCode"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            REASON CODE
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key="selectAll">
                          <div>
                            All
                            <br />
                            <input
                              type="checkbox"
                              checked={
                                this.state.excelData.length > 0 &&
                                this.state.checkedInfrigements.length ===
                                  this.state.excelData.length
                              }
                              onChange={this.toggleSelectAll}
                            />
                            {/* Rest of the headers */}
                          </div>
                        </tr>
                        {this.state.excelData.map((row, rowKey) => (
                          <>
                            <tr
                              key={rowKey}
                              data-toggle="collapse"
                              data-target={".multi-collapse" + rowKey}
                              aria-controls={
                                "multiCollapseExampleWebsite" + rowKey
                              }
                            >
                              <td>
                                <div>
                                  <input
                                    className="inline"
                                    type="checkbox"
                                    checked={
                                      this.state.checkedInfrigements.includes(
                                        row?.id
                                      )
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      this.updateInfrigementCheckStatus(
                                        e,
                                        row?.id
                                      )
                                    }
                                  />
                                  <span className="inline">{rowKey + 1}</span>
                                </div>
                              </td>
                              {this.state[this.state.selectedKey].map(
                                (domainKey, domainKeyIndex) => (
                                  <td key={domainKeyIndex}>
                                    {this.renderDomainComponentImage(
                                      domainKey,
                                      row,
                                      rowKey
                                    )}
                                  </td>
                                )
                              )}
                            </tr>
                            <tr
                              className={"collapse multi-collapse" + rowKey}
                              id={"multiCollapseExampleWebsite" + rowKey}
                            >
                              <td colSpan={7}>
                                {this.printEnforcementData(row, rowKey)}
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  socialMediaComponent() {
    if (this.state.platform === "social-media") {
      return (
        <div className="col">
          <div className="card card-small mb-4">
            <div className="card-body">
              <h5 className="card-title">
                Social Media
                {this.state.checkedInfrigements.length > 1 && (
                  <a
                    onClick={(e) => this.showAddEnforcementPopup(true)}
                    className="float-end"
                    href="#"
                  >
                    Bulk enforcement
                  </a>
                )}
              </h5>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>IMAGE</th>
                          <th>
                            <span
                              onClick={(e) => this.changeSorting("platform")}
                              className={`border-0 ${
                                this.state.sorting.sortBy === "platform"
                                  ? this.state.sorting.sortIconClass +
                                    " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                              }`}
                            >
                              PLATFORM
                            </span>
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover(
                                "socialMediaPlatform"
                              )}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;<i className="fa fa-search"></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>
                            <span
                              onClick={(e) => this.changeSorting("heading")}
                              className={`border-0 ${
                                this.state.sorting.sortBy === "heading"
                                  ? this.state.sorting.sortIconClass +
                                    " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                              }`}
                            >
                              HEADING
                            </span>
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("heading")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;<i className="fa fa-search"></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th
                            onClick={(e) => this.changeSorting("link")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "link"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            LINK
                          </th>
                          <th
                            onClick={(e) => this.changeSorting("dateAdded")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "dateAdded"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            DATE ADDED
                          </th>
                          <th
                            onClick={(e) => this.changeSorting("priority")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "priority"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            PRIORITY
                          </th>
                          <th
                            onClick={(e) => this.changeSorting("status")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "status"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            STATUS
                          </th>
                          <th
                            width="3%"
                            onClick={(e) => this.changeSorting("advertiser")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "advertiser"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            ADVERTISER
                          </th>
                          <th
                            width="3%"
                            onClick={(e) => this.changeSorting("reason")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "reason"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            REASON
                          </th>
                          <th
                            width="3%"
                            onClick={(e) => this.changeSorting("reasonCode")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "reasonCode"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            REASON CODE
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key="selectAll">
                          <div>
                            All
                            <br />
                            <input
                              type="checkbox"
                              checked={
                                this.state.excelData.length > 0 &&
                                this.state.checkedInfrigements.length ===
                                  this.state.excelData.length
                              }
                              onChange={this.toggleSelectAll}
                            />
                            {/* Rest of the headers */}
                          </div>
                        </tr>
                        {this.state.excelData.map((row, rowKey) => (
                          <>
                            <tr
                              key={rowKey}
                              data-toggle="collapse"
                              data-target={".multi-collapse" + rowKey}
                              aria-controls={
                                "multiCollapseExampleDomain" + rowKey
                              }
                            >
                              <td>
                                <div>
                                  <input
                                    className="inline"
                                    type="checkbox"
                                    checked={
                                      this.state.checkedInfrigements.includes(
                                        row?.id
                                      )
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      this.updateInfrigementCheckStatus(
                                        e,
                                        row?.id
                                      )
                                    }
                                  />
                                  <span className="inline">{rowKey + 1}</span>
                                </div>
                              </td>
                              {this.state[this.state.selectedKey].map(
                                (domainKey, domainKeyIndex) => (
                                  <td key={domainKeyIndex}>
                                    {this.renderDomainComponentImage(
                                      domainKey,
                                      row,
                                      rowKey
                                    )}
                                  </td>
                                )
                              )}
                            </tr>
                            <tr
                              className={"collapse multi-collapse" + rowKey}
                              id={"multiCollapseExampleDomain" + rowKey}
                            >
                              <td colSpan={7}>
                                {this.printEnforcementData(row, rowKey)}
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  ecommerceComponent() {
    if (this.state.platform === "e-commerce") {
      return (
        <div className="col">
          <div className="card card-small mb-4">
            <div className="card-body">
              <h5 className="card-title">
                E-COMMERCE
                {this.state.checkedInfrigements.length > 1 && (
                  <a
                    onClick={(e) => this.showAddEnforcementPopup()}
                    className="float-end"
                    href="#"
                  >
                    Bulk enforcement
                  </a>
                )}
              </h5>
              <div className="row">
                <div className="col-md-12">
                  <div>
                    <Table width="100%" className="table-responsive">
                      <thead>
                        <tr>
                          <th scope="col" width="10%">
                            #
                          </th>
                          <th scope="col" width="10%">
                            IMAGE
                          </th>
                          <th scope="col" width="10%">
                            <span
                              onClick={(e) => this.changeSorting("site")}
                              className={`border-0 ${
                                this.state.sorting.sortBy === "site"
                                  ? this.state.sorting.sortIconClass +
                                    " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                              }`}
                            >
                              SITE
                            </span>
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("site")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;<i className="fa fa-search"></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th
                            scope="col"
                            width="10%"
                            onClick={(e) => this.changeSorting("dateAdded")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "dateAdded"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            DATE ADDED
                          </th>
                          <th scope="col" width="10%">
                            <span
                              onClick={(e) => this.changeSorting("url")}
                              className={`border-0 ${
                                this.state.sorting.sortBy === "url"
                                  ? this.state.sorting.sortIconClass +
                                    " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                              }`}
                            >
                              URL
                            </span>
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("url")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;<i className="fa fa-search"></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th scope="col" width="10%">
                            <span
                              onClick={(e) => this.changeSorting("title")}
                              className={`border-0 ${
                                this.state.sorting.sortBy === "title"
                                  ? this.state.sorting.sortIconClass +
                                    " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                              }`}
                            >
                              TITLE
                            </span>
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("title")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;<i className="fa fa-search"></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th
                            onClick={(e) => this.changeSorting("status")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "status"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            STATUS
                          </th>
                          <th
                            onClick={(e) => this.changeSorting("priority")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "priority"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            PRIORITY
                          </th>
                          <th
                            onClick={(e) => this.changeSorting("price")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "price"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            PRICE
                          </th>
                          <th
                            onClick={(e) => this.changeSorting("reason")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "reason"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            REASON
                          </th>
                          <th
                            onClick={(e) => this.changeSorting("sellerRating")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "sellerRating"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            REASON CODE
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key="selectAll">
                          <div>
                            All
                            <br />
                            <input
                              type="checkbox"
                              checked={
                                this.state.excelData.length > 0 &&
                                this.state.checkedInfrigements.length ===
                                  this.state.excelData.length
                              }
                              onChange={this.toggleSelectAll}
                            />
                            {/* Rest of the headers */}
                          </div>
                        </tr>
                        {this.state.excelData.map((row, rowKey) => (
                          <>
                            <tr key={rowKey}>
                              <td>
                                <div>
                                  <input
                                    className="inline"
                                    type="checkbox"
                                    checked={
                                      this.state.checkedInfrigements.includes(
                                        row?.id
                                      )
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      this.updateInfrigementCheckStatus(
                                        e,
                                        row?.id
                                      )
                                    }
                                  />
                                  <i
                                    data-toggle="collapse"
                                    data-target={".multi-collapse" + rowKey}
                                    aria-controls={
                                      "multiCollapseExampleDomain" + rowKey
                                    }
                                    title="Expend/Collapse row"
                                    class="fa fa-arrows-alt px-1 clickable inline"
                                    aria-hidden="true"
                                  ></i>

                                  <span className="inline">{rowKey + 1}</span>
                                </div>
                              </td>
                              {this.state[this.state.selectedKey].map(
                                (domainKey, domainKeyIndex) => (
                                  <td key={domainKeyIndex}>
                                    {this.renderDomainComponentImage(
                                      domainKey,
                                      row,
                                      rowKey
                                    )}
                                  </td>
                                )
                              )}
                            </tr>
                            <tr
                              className={"collapse multi-collapse" + rowKey}
                              id={"multiCollapseExampleWebsite" + rowKey}
                            >
                              <td colSpan={7}>
                                {this.printEnforcementData(row, rowKey)}
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  appStoreComponent() {
    if (this.state.platform === "app-store") {
      return (
        <div className="col">
          <div className="card card-small mb-4">
            <div className="card-body">
              <h5 className="card-title">
                APP STORE
                {this.state.checkedInfrigements.length > 1 && (
                  <a
                    onClick={(e) => this.showAddEnforcementPopup(true)}
                    className="float-end"
                    href="#"
                  >
                    Bulk enforcement
                  </a>
                )}
              </h5>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>ENFORCEMENT</th>
                          <th>IMAGE</th>
                          <th>
                            <span
                              onClick={(e) => this.changeSorting("appName")}
                              className={`border-0 ${
                                this.state.sorting.sortBy === "appName"
                                  ? this.state.sorting.sortIconClass +
                                    " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                              }`}
                            >
                              APP NAME
                            </span>
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("appName")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;<i className="fa fa-search"></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>
                            <span
                              onClick={(e) => this.changeSorting("appStore")}
                              className={`border-0 ${
                                this.state.sorting.sortBy === "appStore"
                                  ? this.state.sorting.sortIconClass +
                                    " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                              }`}
                            >
                              APP STORE
                            </span>
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("appStore")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;<i className="fa fa-search"></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th
                            onClick={(e) => this.changeSorting("dateAdded")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "dateAdded"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            DATE ADDED
                          </th>
                          <th
                            onClick={(e) => this.changeSorting("url")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "url"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            URL
                          </th>
                          <th
                            onClick={(e) => this.changeSorting("developerName")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "developerName"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            DEVELOPER NAME
                          </th>
                          <th
                            onClick={(e) =>
                              this.changeSorting("latestPublishDate")
                            }
                            className={`border-0 ${
                              this.state.sorting.sortBy === "latestPublishDate"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            LATEST PUBLISH DATE
                          </th>
                          <th
                            onClick={(e) => this.changeSorting("status")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "status"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            STATUS
                          </th>
                          <th
                            width="3%"
                            onClick={(e) => this.changeSorting("reason")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "reason"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            REASON
                          </th>
                          <th
                            width="3%"
                            onClick={(e) => this.changeSorting("reasonCode")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "reasonCode"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            REASON CODE
                          </th>
                          <th
                            onClick={(e) => this.changeSorting("priority")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "priority"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            PRIORITY
                          </th>
                          <th
                            onClick={(e) => this.changeSorting("price")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "price"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            PRICE
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key="selectAll">
                          <div>
                            All
                            <br />
                            <input
                              type="checkbox"
                              checked={
                                this.state.excelData.length > 0 &&
                                this.state.checkedInfrigements.length ===
                                  this.state.excelData.length
                              }
                              onChange={this.toggleSelectAll}
                            />
                            {/* Rest of the headers */}
                          </div>
                        </tr>
                        {this.state.excelData.map((row, rowKey) => (
                          <>
                            <tr
                              key={rowKey}
                              data-toggle="collapse"
                              data-target={".multi-collapse" + rowKey}
                              aria-controls={
                                "multiCollapseExampleWebsite" + rowKey
                              }
                            >
                              <td>
                                <div>
                                  <input
                                    className="inline"
                                    type="checkbox"
                                    checked={
                                      this.state.checkedInfrigements.includes(
                                        row?.id
                                      )
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      this.updateInfrigementCheckStatus(
                                        e,
                                        row?.id
                                      )
                                    }
                                  />
                                  <br />
                                  {rowKey + 1}
                                </div>
                              </td>
                              {this.state[this.state.selectedKey].map(
                                (domainKey, domainKeyIndex) => (
                                  <td key={domainKeyIndex}>
                                    {this.renderDomainComponentImage(
                                      domainKey,
                                      row,
                                      rowKey
                                    )}
                                  </td>
                                )
                              )}
                            </tr>
                            <tr
                              className={"collapse multi-collapse" + rowKey}
                              id={"multiCollapseExampleWebsite" + rowKey}
                            >
                              <td colSpan={7}>
                                {this.printEnforcementData(row, rowKey)}
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  renderHtml() {
    if (!this.state.authorised) {
      return <UnauthorisedPage />;
    }

    const options = this.state.brands.map((brand, brandKey) => ({
      value: brand.id,
      label: brand.brandName,
    }));

    return (
      <div className="row">
        {this.props?.login?.user?.role === "Admin" ? (
          <AdminSideNav />
        ) : (
          <AnalystSideNav />
        )}

        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
          <TopNav />

          <div className="main-content-container container-fluid px-4">
            <div className="page-header row no-gutters py-4">
              <div className="col-12">
                <div className="row">
                  <div className="col-4 text-sm-left mb-0">
                    <span className="text-uppercase page-subtitle">
                      Enforcement
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card card-small mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Company
                            </span>
                            <select
                              onChange={(e) => this.changeCompanySelectBox(e)}
                              className="form-control"
                            >
                              <option value="">Select from dropdown</option>
                              {this.state.companies.map(
                                (company, companyIndex) => (
                                  <option key={companyIndex} value={company.id}>
                                    {company.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3" style={{ zIndex: 101 }}>
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Brand
                            </span>
                            {/* <select
                              onChange={(e) => this.changeBrandSelectBox(e)}
                              className="form-control"
                            >
                              <option value="">Select from dropdown</option>
                              {this.state.brands.map((brand, brandKey) => (
                                <option key={brandKey} value={brand.id}>
                                  {brand.brandName}
                                </option>
                              ))}
                            </select> */}
                            <Select
                              options={options}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Platform
                            </span>
                            <select
                              value={this.state.platform}
                              onChange={(e) =>
                                this.changePlatformSelectBox(e.target.value)
                              }
                              className="form-control"
                            >
                              <option value="">Select from dropdown</option>
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.domain) ||
                                !this.state.availablePlatforms) && (
                                <option value="domain">Domain</option>
                              )}
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.website) ||
                                !this.state.availablePlatforms) && (
                                <option value="website">Website</option>
                              )}
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.socialMedia) ||
                                !this.state.availablePlatforms) && (
                                <option value="social-media">
                                  Social Media
                                </option>
                              )}
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.eCommerce) ||
                                !this.state.availablePlatforms) && (
                                <option value="e-commerce">E-commerce</option>
                              )}
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.appStore) ||
                                !this.state.availablePlatforms) && (
                                <option value="app-store">App Store</option>
                              )}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Reporting Date
                            </span>
                            <select
                              onChange={(e) =>
                                this.setState({ reportingDate: e.target.value })
                              }
                              className="form-control"
                            >
                              <option value="">Select from dropdown</option>
                              {this.state.availableReportingDate.map(
                                (date, dateIndex) => (
                                  <option key={dateIndex} value={date}>
                                    {moment(date).format("DD/MM/YYYY")}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Infringement Status
                            </span>
                            <select
                              onChange={(e) =>
                                this.setState({
                                  infrigementStatusFilter: e.target.value,
                                })
                              }
                              className="form-control"
                            >
                              {fetchInfrigementAllStatus().map(
                                (status, index) => (
                                  <option value={status.code}>
                                    {status.text}
                                  </option>
                                )
                              )}
                              {this.state.platform === "e-commerce" && (
                                <option value={PURCHASE.code}>
                                  {PURCHASE.text}{" "}
                                </option>
                              )}
                              {this.state.platform === "e-commerce" && (
                                <option value={PURCHASE_IN_PROGRESS.code}>
                                  {PURCHASE_IN_PROGRESS.text}{" "}
                                </option>
                              )}
                              {this.state.platform === "e-commerce" && (
                                <option value={PURCHASE_IN_COMPLETE.code}>
                                  {PURCHASE_IN_COMPLETE.text}{" "}
                                </option>
                              )}
                              {this.state.platform === "e-commerce" && (
                                <option
                                  value={
                                    PURCHASE_INCOMPLETE_PRODUCT_NOT_AVAILABLE.code
                                  }
                                >
                                  {
                                    PURCHASE_INCOMPLETE_PRODUCT_NOT_AVAILABLE.text
                                  }{" "}
                                </option>
                              )}
                              {this.state.platform === "e-commerce" && (
                                <option
                                  value={
                                    PURCHASE_INCOMPLETE_SELLER_NOT_RESPONSIVE.code
                                  }
                                >
                                  {
                                    PURCHASE_INCOMPLETE_SELLER_NOT_RESPONSIVE.text
                                  }{" "}
                                </option>
                              )}
                            </select>
                          </div>
                        </div>

                        {this.state.platform === "e-commerce" && (
                          <div className="col-md-3">
                            <div className="form-group">
                              <span className="text-muted d-block mb-2">
                                Site
                              </span>
                              <select
                                onChange={(e) =>
                                  this.setState({
                                    site: e.target.value,
                                  })
                                }
                                className="form-control"
                              >
                                <option value="">Select site</option>
                                {this.state.eCommerceSiteData &&
                                  this.state.eCommerceSiteData.map((el) => (
                                    <option value={el} key={el}>
                                      {el}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        )}

                        {(this.state.platform === "domain" ||
                          this.state.platform === "website") && (
                          <div className="col-md-3">
                            <div className="form-group">
                              <span className="text-muted d-block mb-2">
                                Enforcement Registrar
                              </span>
                              <input
                                onChange={(e) =>
                                  this.setState({
                                    registrarFilter: e.target.value,
                                  })
                                }
                                value={this.state.registrarFilter}
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}
                        {(this.state.platform === "domain" ||
                          this.state.platform === "website") && (
                          <div className="col-md-3">
                            <div className="form-group">
                              <span className="text-muted d-block mb-2">
                                Enforcement IP Address
                              </span>
                              <input
                                onChange={(e) =>
                                  this.setState({
                                    ipAddressFilter: e.target.value,
                                  })
                                }
                                value={this.state.ipAddressFilter}
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}
                        {(this.state.platform === "domain" ||
                          this.state.platform === "website") && (
                          <div className="col-md-3">
                            <div className="form-group">
                              <span className="text-muted d-block mb-2">
                                Enforcement Hosting
                              </span>
                              <input
                                onChange={(e) =>
                                  this.setState({
                                    hostingFilter: e.target.value,
                                  })
                                }
                                value={this.state.hostingFilter}
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Action Taken
                            </span>
                            <input
                              onChange={(e) =>
                                this.setState({
                                  actionTakenFilter: e.target.value,
                                })
                              }
                              value={this.state.actionTakenFilter}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <button
                              onClick={(e) => this.fetchData()}
                              className="mt-4 btn btn-ldotr"
                            >
                              Fetch Data
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="d-flex align-items-center justify-content-between sticky-div-user px-5"
              style={{ zIndex: 100 }}
            >
              <div className="d-flex justify-content-center align-items-center">
                <PaginationComponentV1
                  totalRecords={this.state.pagination.totalDocs}
                  itemsPerPage={this.state.perPage}
                  currentPage={this.state.pagination.page}
                  onPageChange={(newPage) => {
                    this.setState((prevState) => {
                      return {
                        ...prevState,
                        pagination: { ...prevState.pagination, page: newPage },
                      };
                    });
                  }}
                  displayPagesCount={8}
                />
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <Dropdown
                  style={{ width: "80px" }}
                  onSelect={(e) => {
                    this.setState((prevState) => ({
                      perPage: e,
                      pagination: paginationInitialState,
                    }));
                  }}
                >
                  <Dropdown.Toggle variant="danger" id="limit">
                    {this.state.perPage}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      eventKey="10"
                      active={this.state.perPage == 10}
                    >
                      10
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="20"
                      active={this.state.perPage == 20}
                    >
                      20
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="50"
                      active={this.state.perPage == 50}
                    >
                      50
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="100"
                      active={this.state.perPage == 100}
                    >
                      100
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="All"
                      active={this.state.perPage == "All"}
                    >
                      All
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <span>{this.state.pagination.totalDocs} records</span>
              </div>
            </div>
            {/* Domain Upload Screen */}
            {this.domainComponent()}
            {/* Website upload screen */}
            {this.websiteComponent()}
            {/* Social Media Screen */}
            {this.socialMediaComponent()}
            {/* E-COMMERCE */}
            {this.ecommerceComponent()}
            {/* APP STORE */}
            {this.appStoreComponent()}
          </div>
          {/* data saved sweet alert */}
          <SweetAlert
            show={this.state.showDataSavedAlert}
            title="Success"
            text="Data Saved Successfully !"
            onConfirm={() => this.setState({ showDataSavedAlert: false })}
          />
          <Footer />
        </main>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.renderHtml()}

        <Modal
          className="add-brand-platform"
          id="modal-1"
          size="lg"
          backdrop="static"
          backdropClassName="add-brand-platform"
          keyboard={false}
          show={this.state.showAddEnforcementModal}
          onHide={this.handleCloseAddEnforcementModal}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#d32f2f" }}>
              Add Enforcement
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="row">
                {(this.state.platform === "domain" ||
                  this.state.platform === "website") && (
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Registrar</Form.Label>
                      <Form.Control
                        name="registrar"
                        onChange={(e) => this.changeInputBox(e, "registrar")}
                        value={this.state.registrar}
                        type="text"
                        placeholder="Registrar"
                        disabled={this.state.isDisable}
                      />
                      <span className="form-text-error-text">
                        {this.state.errorMessage?.registrar}
                      </span>
                    </Form.Group>
                  </div>
                )}
                {(this.state.platform === "domain" ||
                  this.state.platform === "website") && (
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>IP Address</Form.Label>
                      <Form.Control
                        name="ipAddress"
                        onChange={(e) => this.changeInputBox(e, "ipAddress")}
                        value={this.state.ipAddress}
                        type="text"
                        placeholder="ipAddress"
                        disabled={this.state.isDisable}
                      />
                      <span className="form-text-error-text">
                        {this.state.errorMessage?.ipAddress}
                      </span>
                    </Form.Group>
                  </div>
                )}
                {(this.state.platform === "domain" ||
                  this.state.platform === "website") && (
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>hosting</Form.Label>
                      <Form.Control
                        name="hosting"
                        onChange={(e) => this.changeInputBox(e, "hosting")}
                        value={this.state.hosting}
                        type="text"
                        placeholder="hosting"
                        disabled={this.state.isDisable}
                      />
                      <span className="form-text-error-text">
                        {this.state.errorMessage?.hosting}
                      </span>
                    </Form.Group>
                  </div>
                )}

                <div className="col-6">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>date</Form.Label>
                    <Form.Control
                      name="date"
                      onChange={(e) => this.changeInputBox(e, "date")}
                      value={this.state.date}
                      type="date"
                      placeholder="date"
                    />
                    <span className="form-text-error-text">
                      {this.state.errorMessage?.date}
                    </span>
                  </Form.Group>
                </div>
                <div className="col-12">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Action Taken</Form.Label>
                    <Form.Control
                      name="actionTaken"
                      onChange={(e) => this.changeInputBox(e, "actionTaken")}
                      value={this.state.actionTaken}
                      type="text"
                      placeholder="actionTaken"
                    />
                    <span className="form-text-error-text">
                      {this.state.errorMessage?.actionTaken}
                    </span>
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Change Status to</Form.Label>
                    <select
                      onChange={(e) =>
                        this.setState({ infrigementStatus: e.target.value })
                      }
                      className="form-control"
                    >
                      <option>Select from dropdown</option>
                      <option
                        selected={
                          this.state.infrigementStatus === SUCCESS.code
                            ? "selected"
                            : ""
                        }
                        value={SUCCESS.code}
                      >
                        Success
                      </option>
                      <option
                        selected={
                          this.state.infrigementStatus === IN_PROGRESS.code
                            ? "selected"
                            : ""
                        }
                        value={IN_PROGRESS.code}
                      >
                        In Progress
                      </option>
                      <option
                        selected={
                          this.state.infrigementStatus ===
                          AWAITING_APPROVAL.code
                            ? "selected"
                            : ""
                        }
                        value={AWAITING_APPROVAL.code}
                      >
                        Awaiting Approval
                      </option>
                      <option
                        selected={
                          this.state.infrigementStatus === APPROVED.code
                            ? "selected"
                            : ""
                        }
                        value={APPROVED.code}
                      >
                        Approved
                      </option>
                      <option
                        selected={
                          this.state.infrigementStatus ===
                          NO_FURTHER_ACTION.code
                            ? "selected"
                            : ""
                        }
                        value={NO_FURTHER_ACTION.code}
                      >
                        No Further Action
                      </option>
                      <option
                        selected={
                          this.state.infrigementStatus === NO_ACTION.code
                            ? "selected"
                            : ""
                        }
                        value={NO_ACTION.code}
                      >
                        No action{" "}
                      </option>
                      <option
                        selected={
                          this.state.infrigementStatus === CLIENT_REVIEW.code
                            ? "selected"
                            : ""
                        }
                        value={CLIENT_REVIEW.code}
                      >
                        {CLIENT_REVIEW.text}{" "}
                      </option>

                      <option
                        selected={
                          this.state.infrigementStatus === SELF_RESOLVED.code
                            ? "selected"
                            : ""
                        }
                        value={SELF_RESOLVED.code}
                      >
                        {SELF_RESOLVED.text}{" "}
                      </option>

                      {this.state.platform === "e-commerce" && (
                        <option
                          selected={
                            this.state.infrigementStatus === PURCHASE.code
                              ? "selected"
                              : ""
                          }
                          value={PURCHASE.code}
                        >
                          {PURCHASE.text}{" "}
                        </option>
                      )}
                      {this.state.platform === "e-commerce" && (
                        <option
                          selected={
                            this.state.infrigementStatus ===
                            PURCHASE_IN_PROGRESS.code
                              ? "selected"
                              : ""
                          }
                          value={PURCHASE_IN_PROGRESS.code}
                        >
                          {PURCHASE_IN_PROGRESS.text}{" "}
                        </option>
                      )}
                      {this.state.platform === "e-commerce" && (
                        <option
                          selected={
                            this.state.infrigementStatus ===
                            PURCHASE_IN_COMPLETE.code
                              ? "selected"
                              : ""
                          }
                          value={PURCHASE_IN_COMPLETE.code}
                        >
                          {PURCHASE_IN_COMPLETE.text}{" "}
                        </option>
                      )}
                      {this.state.platform === "e-commerce" && (
                        <option
                          value={PURCHASE_INCOMPLETE_PRODUCT_NOT_AVAILABLE.code}
                        >
                          {PURCHASE_INCOMPLETE_PRODUCT_NOT_AVAILABLE.text}{" "}
                        </option>
                      )}
                      {this.state.platform === "e-commerce" && (
                        <option
                          value={PURCHASE_INCOMPLETE_SELLER_NOT_RESPONSIVE.code}
                        >
                          {PURCHASE_INCOMPLETE_SELLER_NOT_RESPONSIVE.text}{" "}
                        </option>
                      )}
                    </select>
                    <span className="form-text-error-text">
                      {this.state.errorMessage?.infrigementStatus}
                    </span>
                  </Form.Group>
                </div>

                <div className="col-12">
                  <Button onClick={this.saveEnforcementData}>Save</Button>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        {/* show enforcement modal */}

        <Modal
          size="xl"
          backdrop="static"
          keyboard={false}
          show={this.state.showModal}
          onHide={this.handleCloseModal}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#d32f2f" }}>
              Enforcement Data
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table>
              <thead>
                <tr>
                  {(this.state.platform === "domain" ||
                    this.state.platform === "website") && <th>Registrar</th>}
                  {(this.state.platform === "domain" ||
                    this.state.platform === "website") && <th>IP Address</th>}
                  {(this.state.platform === "domain" ||
                    this.state.platform === "website") && <th>Hosting</th>}
                  <th>Action Taken</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {this.state.enforcementData.map((enforcement) => (
                  <tr>
                    {(this.state.platform === "domain" ||
                      this.state.platform === "website") && (
                      <td>{enforcement.registrar}</td>
                    )}
                    {(this.state.platform === "domain" ||
                      this.state.platform === "website") && (
                      <td>{enforcement.ipAddress}</td>
                    )}
                    {(this.state.platform === "domain" ||
                      this.state.platform === "website") && (
                      <td>{enforcement.hosting}</td>
                    )}
                    <td>{enforcement.actionTaken}</td>
                    <td>{moment(enforcement.date).format("D-M-Y")}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
  response: state.response,
});

export default withAlert()(
  connect(mapStateToProps)(authorizeAnalystOrAdmin(requireAuth(Enforcement)))
);
