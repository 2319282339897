import React from "react";
import Footer from "../../common/Footer.js";
import AnalystSideNav from "../../common/AnalystSideNav.js";
import AdminSideNav from "../../common/AdminSideNav.js";
import Select from "react-select";
import UnauthorisedPage from "../Unauthorised";
import TopNav from "../../common/TopNav.js";
import { Button, Modal, Form, Table } from "react-bootstrap";
import { connect } from "react-redux";
import requireAuth from "../../hoc/requireAuth";
import authorizeAnalystOrAdmin from "../../hoc/authorizeAnalystOrAdmin";
import { withAlert } from "react-alert";
import {
  getInfrigementStatusText,
  getAllPurchaseStatusText,
} from "../../../src/infrigementStatusCode";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/src/collapse.js";
import SweetAlert from "sweetalert-react";
import "sweetalert/dist/sweetalert.css";
import "sweetalert2/src/sweetalert2.scss";
import "bootstrap-daterangepicker/daterangepicker.css";
import * as moment from "moment";
import {
  castToServerDateFormat,
  castToDefaultDateFormat,
} from "../../settings/index";
import { getNewSortOrder } from "../../helpers.js";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import "./uploadImage.css";

class UploadPurchase extends React.Component {
  constructor(props) {
    super(props);
    this.showAddEnforcementPopup = this.showAddEnforcementPopup.bind(this);
    this.handleCloseAddEnforcementModal =
      this.handleCloseAddEnforcementModal.bind(this);
    this.saveEnforcementData = this.saveEnforcementData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleGSTFile = this.handleGSTFile.bind(this);
    this.handleInvoiceFile = this.handleInvoiceFile.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleImageClose = this.handleImageClose.bind(this);
    this.handleUpdateClick = this.handleUpdateClick.bind(this);

    this.state = {
      sorting: {
        sortBy: "",
        sortIconClass: "sort-icon-nutral",
        sortOrder: "",
      },
      selectedFiles: [],
      checkedInfrigements: [],
      registrarFilter: "",
      ipAddressFilter: "",
      infrigementStatusFilter: "",
      hostingFilter: "",
      actionTakenFilter: "",
      infrigementStatus: null,
      enforcementData: [],
      currentInfrigementId: null,
      showModal: false,
      showAddEnforcementModal: false,
      authorised: true,
      platform: "",
      showDataSavedAlert: false,
      showFilesUploadedAlert: false,
      excelData: [],
      companies: [],
      brands: [],
      reportingDate: "",
      company: "",
      brand: "",
      files: [],
      errorMessage: null,
      filters: {},
      availablePlatforms: null,
      isDisable: false,
      ecommerceKeys: [
        "image",
        "site",
        "reportingDate",
        "url",
        "title",
        "status",
        "priority",
        "price",
        "reason",
        "sellerRating",
      ],
      selectedKey: "",
      availableReportingDate: [],
      sellerName: "",
      sellerAddress: "",
      sellerCity: "",
      sellerCountry: "",
      sellerState: "",
      pricePaid: "",
      pan: "",
      invoiceFile: "",
      invoice: "",
      ldotrNote: "",
      gstFile: "",
      gstNumber: "",
      imageUrls: [],
      selectedPurchaseId: "",
      selectedStatus: "",
    };
  }

  handleRemoveImage = (index) => {
    const updatedFiles = [...this.state.selectedFiles];
    updatedFiles.splice(index, 1);
    this.setState({
      selectedFiles: updatedFiles,
    });
  };

  handleUpdateClick = (data) => {
    this.setState(() => {
      return {
        sellerName: data.sellerName,
        sellerAddress: data.sellerAddress,
        sellerCity: data.sellerCity,
        sellerCountry: data.sellerCountry,
        sellerState: data.sellerState,
        pricePaid: data.pricePaid,
        pan: data.pan,
        invoiceFile: data.invoiceFile,
        invoice: data.invoice,
        ldotrNote: data.ldotrNote,
        gstFile: data.gstFile,
        gstNumber: data.gstNumber,
        imageUrls: data.imageUrls,
        showAddEnforcementModal: true,
        selectedPurchaseId: data.selectedPurchaseId,
        currentInfrigementId: data?.ecomId || null,
        selectedStatus: data.status,
      };
    });
  };

  handleFileChange = async (event) => {
    const files = event.target.files;
    let formData = new FormData();
    const validImages = [];

    for (let i = 0; i < files.length; i++) {
      if (!files[i].type.match("image.*")) {
        continue; // Skip non-image files
      }
      validImages.push(files[i]);
      formData.append("files", files[i]);
    }

    if (validImages.length > 0) {
      const options = {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "true",
        },
      };
      await window.axios
        .post("/trapPurchase/uploadImageUrls", formData, options)
        .then((res) => {
          let response = res.data.ResponseData;
          this.setState((prevState) => ({
            imageUrls: [...prevState.imageUrls, ...response],
          }));
        });
    }
  };

  handleImageClose = (index) => {
    const newImages = this.state.imageUrls.filter((_, i) => index !== i);
    this.setState({ imageUrls: newImages });
  };

  handleGSTFile = async (event) => {
    const files = event.target.files;
    let formData = new FormData();
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "true",
      },
    };
    formData.append("files", files[0]);
    await window.axios
      .post("/trapPurchase/uploadGSTFile", formData, options)
      .then((res) => {
        this.setState({ gstFile: res.data?.ResponseData[0] || "" });
      });
  };

  handleInvoiceFile = async (event) => {
    const files = event.target.files;
    let formData = new FormData();
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "true",
      },
    };
    formData.append("files", files[0]);
    await window.axios
      .post("/trapPurchase/uploadInvoiceFile", formData, options)
      .then((res) => {
        this.setState({ invoiceFile: res.data?.ResponseData[0] || "" });
      });
  };

  showAddEnforcementPopup(data, index = null) {
    this.fetchLastEnforcementData(data);
    this.setState(() => {
      return {
        showAddEnforcementModal: true,
        currentInfrigementId: data?._id,
        currentInfrigementIndex: index,
      };
    });
  }

  changeSorting(sortBy) {
    this.setState((prevState) => {
      const { sortOrder, classPostFix } = getNewSortOrder(prevState);

      return {
        sorting: {
          sortBy,
          sortIconClass: `sort-icon-${classPostFix}`,
          sortOrder,
        },
      };
    });
  }

  async fetchLastEnforcementData(data) {
    if (!data) {
      this.setState(() => ({
        date: castToServerDateFormat(moment()),
      }));
      return;
    }

    let infrigementId = data?.id;

    let platform = this.state.platform;

    let url = `/enforcement/get-last-enforcement-record`;

    await window.axios
      .get(url, {
        params: {
          platform,
          infrigementId,
        },
      })
      .then((response) => {
        let lastInfrigement = response.data;

        this.setState(() => ({
          registrar: lastInfrigement?.registrar,
          ipAddress: lastInfrigement?.ipAddress,
          hosting: lastInfrigement?.hosting,
          actionTaken: lastInfrigement?.actionTaken,
          date: castToServerDateFormat(moment()), //castToServerDateFormat(lastInfrigement?.date),
          infrigementStatus: data?.status,
        }));
      })
      .catch((error) => {
        if (error.response.status === 422) {
          this.setState(() => {
            return {
              errorMessage: error.response.data?.errors,
            };
          });
        }
      });
  }

  async saveEnforcementData() {
    const dataId = this.state.currentInfrigementId;
    let params = {
      eId: dataId,
      sellerName: this.state.sellerName,
      sellerAddress: this.state.sellerAddress,
      sellerCountry: this.state.sellerCountry,
      sellerCity: this.state.sellerCity,
      sellerState: this.state.sellerState,
      pricePaid: this.state.pricePaid,
      pan: this.state.pan,
      invoiceFile: this.state.invoiceFile,
      invoice: this.state.invoice,
      ldotrNote: this.state.ldotrNote,
      gstFile: this.state.gstFile,
      gstNumber: this.state.gstNumber,
      imageUrls: this.state.imageUrls,
      selectedPurchaseId: this.state.selectedPurchaseId,
      selectedStatus: this.state.selectedStatus || "44",
    };
    await window.axios
      .post("/trapPurchase", params)
      .then((response) => {
        this.props.alert.show(response.data.message);
        this.fetchData();
        this.handleCloseAddEnforcementModal();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          this.setState(() => {
            return {
              errorMessage: error.response.data?.errors,
            };
          });
        }
      });
  }

  async fetchEnforcement(dataId) {
    let platform = this.state.platform;

    if (platform === "e-commerce") {
      platform = "ecommerce";
    }

    let url = `/${platform}/${dataId}/enforcement`;

    await window.axios.get(url, {}).then((res) => {
      this.setState(() => {
        return { enforcementData: res.data.data };
      });
    });
  }

  async changeInputBox(e, column) {
    console.log(e, column);
    let state = {};
    state[column] = e.target.value;
    await this.setState(() => {
      return state;
    });
  }

  handleCloseAddEnforcementModal() {
    this.setState(() => {
      return {
        sellerName: "",
        sellerAddress: "",
        sellerCity: "",
        sellerCountry: "",
        sellerState: "",
        pricePaid: "",
        pan: "",
        invoiceFile: "",
        invoice: "",
        ldotrNote: "",
        gstFile: "",
        gstNumber: "",
        imageUrls: [],
        showAddEnforcementModal: false,
        selectedPurchaseId: "",
        errorMessage: null,
      };
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.company !== this.state.company) {
      this.getBrands();

      if (this.state.platform) {
        this.fetchAvailableReportingDate();
      }
    }

    if (prevState.platform !== this.state.platform) {
      if (this.state.platform) {
        this.fetchAvailableReportingDate();
      }
    }

    if (
      prevState.availablePlatforms !== this.state.availablePlatforms &&
      this.state.availablePlatforms
    ) {
      const platform = this.state.availablePlatforms;

      let selectedPlatform = "domain";

      if (platform.appStore) {
        selectedPlatform = "app-store";
      }

      if (platform.eCommerce) {
        selectedPlatform = "e-commerce";
      }

      if (platform.socialMedia) {
        selectedPlatform = "social-media";
      }

      if (platform.website) {
        selectedPlatform = "website";
      }

      this.changePlatformSelectBox(selectedPlatform);
    }

    if (prevState.brand !== this.state.brand) {
      if (this.state.platform) {
        this.fetchAvailableReportingDate();
      }

      if (this.state.brand) {
        let selectedBrand = this.state.brands.filter(
          (brand) => brand.id === this.state.brand
        );

        if (selectedBrand.length) {
          let platform = selectedBrand[0].platform;

          this.setState(() => ({
            availablePlatforms: platform,
          }));
        }
      } else {
        this.setState(() => ({
          availablePlatforms: null,
        }));
      }
    }

    if (
      prevState.sorting.sortBy !== this.state.sorting.sortBy ||
      prevState.sorting.sortOrder !== this.state.sorting.sortOrder
    ) {
      this.fetchData();
    }
  }

  componentDidMount() {
    this.changePlatformSelectBox("domain");
    this.getCompanies();
  }

  async getCompanies() {
    await window.axios
      .get("/company", {
        params: {
          perPage: 100,
        },
      })
      .then((res) => {
        this.setState({
          companies: res.data.docs,
        });
      });
  }

  async getBrands() {
    await window.axios
      .get("/brand", {
        params: {
          disabledBrands: true,
          company: this.state.company,
          page: 1,
          perPage: 800, //JiraId :- LDOTR-3
        },
      })
      .then((res) => {
        this.setState({
          brands: res.data.docs,
        });
      });
  }

  async fetchData() {
    const options = {
      headers: { "Content-Type": "application/json" },
    };
    if (
      this.state.company === "" ||
      this.state.infrigementStatusFilter === ""
    ) {
      return;
    }

    let params = {
      company: this.state.company,
      brand: this.state.brand,
      reportingDate: this.state.reportingDate,
      status: this.state.infrigementStatusFilter,
    };
    await window.axios
      .get("/trapPurchase/getTrapPurchaseRecords", { params }, options)
      .then((res) => {
        this.setState({ excelData: res.data.data });
      })
      .catch(function (error) {
        alert(error.response.data.message);
      });
  }

  handleChange = (selectedOption) => {
    const selectedBrand = this.state.brands.find(
      (brand) => brand.id === selectedOption.value
    );

    this.setState({
      brand: selectedOption.value,
      brandId: selectedBrand ? selectedBrand.id : null,
    });
  };

  renderDomainComponentImage(domainKey, row, rowKey) {
    if (domainKey === "image") {
      return (
        <>
          <img width="50px" src={row.imageUrl} alt="image" />
        </>
      );
    } else if (domainKey === "status") {
      return (
        <span
          title={getInfrigementStatusText(row[domainKey])}
          className="bind-width"
        >
          {getInfrigementStatusText(row[domainKey])}
        </span>
      );
    } else if (domainKey === "domain") {
      return (
        <a
          base
          className="bind-width"
          title={row[domainKey]}
          rel="noreferrer"
          target="_blank"
          href={`//${row[domainKey]}`}
        >
          {row[domainKey]}
        </a>
      );
    } else if (domainKey === "link") {
      return (
        <a
          base
          className="bind-width"
          title={row[domainKey]}
          rel="noreferrer"
          target="_blank"
          href={`${row[domainKey]}`}
        >
          {row[domainKey]}
        </a>
      );
    } else if (domainKey === "url") {
      return (
        <a
          base
          className="bind-width"
          title={row[domainKey]}
          rel="noreferrer"
          target="_blank"
          href={`${row[domainKey]}`}
        >
          {row[domainKey]}
        </a>
      );
    } else if (domainKey === "reportingDate") {
      return (
        <span
          title={castToDefaultDateFormat(row[domainKey])}
          className="bind-width"
        >
          {castToDefaultDateFormat(row[domainKey])}
        </span>
      );
    } else {
      return (
        <span title={row[domainKey]} className="bind-width">
          {row[domainKey]}
        </span>
      );
    }
  }

  changeBrandSelectBox(e) {
    this.setState(() => ({
      brand: e.target.value,
    }));
  }

  changeCompanySelectBox(e) {
    this.setState(() => ({
      company: e.target.value,
      brand: "",
      brands: [],
    }));
  }

  changePlatformSelectBox(key) {
    let selectedKeyValue = "ecommerceKeys";

    this.setState(() => ({
      platform: key,
      excelData: [],
      selectedKey: selectedKeyValue,
      registrarFilter: "",
      ipAddressFilter: "",
      hostingFilter: "",
      actionTakenFilter: "",
      availableReportingDate: [],
      filters: {},
      checkedInfrigements: [],
    }));
  }

  async fetchAvailableReportingDate() {
    const options = {
      headers: { "Content-Type": "application/json" },
    };

    await window.axios
      .post(
        "/save-bulk-data/fetch-available-reporting-dates",
        {
          company: this.state.company,
          brand: this.state.brand,
          platform: this.state.platform,
        },
        options
      )
      .then((res) => {
        this.setState({ availableReportingDate: res.data });
      })
      .catch(function (error) {
        alert(error.response.data.message);
      });
  }

  printTrapPurchaseData(row, rowKey) {
    return (
      <>
        <h4>
          Trap Purchase Data
          <a
            title="Add Enforcement"
            href="javascript:;"
            className="px-1 text-danger pull-right"
            onClick={(e) => this.showAddEnforcementPopup(row, rowKey)}
          >
            {row.trappurchases.length === 0 && (
              <i
                className="fa fa-plus"
                style={{ backgroundColor: "white" }}
              ></i>
            )}
          </a>
        </h4>
        <Table width="100%" className="table-responsive">
          <thead>
            <tr>
              <th scope="col" width="5%">
                Image
              </th>
              <th scope="col" width="5%">
                Gst File
              </th>
              <th scope="col" width="5%">
                Gst Number
              </th>
              <th scope="col" width="5%">
                Seller Name
              </th>
              <th scope="col" width="35%">
                Seller Address
              </th>
              <th scope="col" width="5%">
                Country
              </th>
              <th scope="col" width="5%">
                State
              </th>
              <th scope="col" width="5%">
                City
              </th>
              <th scope="col" width="5%">
                Price
              </th>
              <th scope="col" width="5%">
                Pan
              </th>
              <th scope="col" width="5%">
                Invoice File
              </th>
              <th scope="col" width="5%">
                Invoice Number
              </th>
              <th scope="col" width="5%">
                LdotR Note
              </th>
              <th scope="col" width="5%">
                Action
              </th>
            </tr>
          </thead>
          <tbody style={{ backgroundColor: "#f5f5f5" }}>
            {row.trappurchases.map((enforcement, key) => (
              <tr key={key} style={{ backgroundColor: "#f5f5f5" }}>
                <td>
                  {/* Display one image */}
                  <img
                    width="50"
                    src={enforcement.imageUrls[0]}
                    className="thumbnail"
                    data-toggle="modal"
                    data-target={`#imageModal${rowKey}`}
                    id="mainImage"
                    alt="Main"
                  />
                  {/* Modal */}
                  <div
                    className="modal fade"
                    id={`imageModal${rowKey}`}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby={`imageModalLabel${rowKey}`}
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      style={{ maxWidth: "50%" }}
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-body">
                          {/* Image Carousel */}
                          <div
                            id={`imageCarousel${rowKey}`}
                            className="carousel slide"
                            data-ride="carousel"
                          >
                            <div className="carousel-inner">
                              {enforcement.imageUrls.map((image, index) => (
                                <div
                                  key={index}
                                  className={`carousel-item ${
                                    index === 0 ? "active" : ""
                                  }`}
                                >
                                  {/* Larger image */}
                                  <img
                                    src={image}
                                    className="d-block mx-auto my-auto w-200 larger-image"
                                    alt={`Image ${index + 1}`}
                                  />
                                  {/* Download link */}
                                  <a
                                    href={image}
                                    download
                                    className="download-link"
                                  >
                                    Download
                                  </a>
                                </div>
                              ))}
                            </div>
                            <a
                              className="carousel-control-prev"
                              href={`#imageCarousel${rowKey}`}
                              role="button"
                              data-slide="prev"
                            >
                              <span
                                className="carousel-control-prev-icon bg-dark rounded-circle"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">Previous</span>
                            </a>
                            <a
                              className="carousel-control-next"
                              href={`#imageCarousel${rowKey}`}
                              role="button"
                              data-slide="next"
                            >
                              <span
                                className="carousel-control-next-icon bg-dark rounded-circle"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">Next</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <a href={enforcement.gstFile}>Download</a>
                </td>
                <td>{enforcement.gstNumber || ""}</td>
                <td>{enforcement.sellerName || ""}</td>
                <td>{enforcement.sellerAddress || ""}</td>
                <td>{enforcement.sellerCountry || ""}</td>
                <td>{enforcement.sellerState || ""}</td>
                <td>{enforcement.sellerCity || ""}</td>
                <td>{enforcement.pricePaid || ""}</td>
                <td>{enforcement.pan || ""}</td>
                <td>
                  <a href={enforcement.invoiceFile}>Download</a>
                </td>
                <td>{enforcement.invoice || ""}</td>
                <td>{enforcement.ldotrNote || ""}</td>
                <td>
                  <i
                    className="fa fa-edit"
                    onClick={() =>
                      this.handleUpdateClick({
                        imageUrls: enforcement.imageUrls,
                        gstFile: enforcement.gstFile,
                        gstNumber: enforcement.gstNumber,
                        sellerName: enforcement.sellerName,
                        sellerAddress: enforcement.sellerAddress,
                        sellerCountry: enforcement.sellerCountry,
                        sellerState: enforcement.sellerState,
                        sellerCity: enforcement.sellerCity,
                        pricePaid: enforcement.pricePaid,
                        pan: enforcement.pan,
                        invoiceFile: enforcement.invoiceFile,
                        invoice: enforcement.invoice,
                        ldotrNote: enforcement.ldotrNote,
                        selectedPurchaseId: enforcement._id,
                        ecomId: row._id,
                        status: row.status,
                      })
                    }
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }

  changeFilterValue(columnName, value) {
    let filters = this.state.filters;

    filters[columnName] = value;

    this.setState(() => ({
      filters,
    }));
  }

  renderFilterPopover(columnName) {
    return (
      <Popover id="popover-positioned-right" title="Popover right">
        <div className="container py-1 px-1" style={{ width: "100%" }}>
          <div className="row">
            <div className="col-7 mx-0">
              <input
                onChange={(e) =>
                  this.changeFilterValue(columnName, e.target.value)
                }
                value={this.state.filters[columnName]}
                className="form-control"
              />
            </div>
            <div className="col-5 mx-0">
              <button
                onClick={(e) => this.fetchData()}
                className="btn btn-success"
              >
                Apply Filter
              </button>
            </div>
          </div>
        </div>
      </Popover>
    );
  }

  ecommerceComponent() {
    // if (this.state.platform === "e-commerce") {
    return (
      <div className="col">
        <div className="card card-small mb-4">
          <div className="card-body">
            <h5 className="card-title">E-COMMERCE</h5>
            <div className="row">
              <div className="col-md-12">
                <div>
                  <Table width="100%" className="table-responsive">
                    <thead>
                      <tr>
                        <th scope="col" width="10%">
                          SNO
                        </th>
                        <th scope="col" width="10%">
                          IMAGE
                        </th>
                        <th scope="col" width="10%">
                          <span
                            onClick={(e) => this.changeSorting("site")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "site"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            SITE
                          </span>
                          <OverlayTrigger
                            trigger="click"
                            rootClose
                            placement="right"
                            overlay={this.renderFilterPopover("site")}
                          >
                            <span
                              className="capitalize-text filter-parent-tag"
                              href="#"
                            >
                              &nbsp;<i className="fa fa-search"></i>
                            </span>
                          </OverlayTrigger>
                        </th>
                        <th
                          scope="col"
                          width="10%"
                          onClick={(e) => this.changeSorting("dateAdded")}
                          className={`border-0 ${
                            this.state.sorting.sortBy === "dateAdded"
                              ? this.state.sorting.sortIconClass + " sort-icon"
                              : "sort-icon sort-icon-nutral"
                          }`}
                        >
                          DATE ADDED
                        </th>
                        <th scope="col" width="10%">
                          <span
                            onClick={(e) => this.changeSorting("url")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "url"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            URL
                          </span>
                          <OverlayTrigger
                            trigger="click"
                            rootClose
                            placement="right"
                            overlay={this.renderFilterPopover("url")}
                          >
                            <span
                              className="capitalize-text filter-parent-tag"
                              href="#"
                            >
                              &nbsp;<i className="fa fa-search"></i>
                            </span>
                          </OverlayTrigger>
                        </th>
                        <th scope="col" width="10%">
                          <span
                            onClick={(e) => this.changeSorting("title")}
                            className={`border-0 ${
                              this.state.sorting.sortBy === "title"
                                ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                : "sort-icon sort-icon-nutral"
                            }`}
                          >
                            TITLE
                          </span>
                          <OverlayTrigger
                            trigger="click"
                            rootClose
                            placement="right"
                            overlay={this.renderFilterPopover("title")}
                          >
                            <span
                              className="capitalize-text filter-parent-tag"
                              href="#"
                            >
                              &nbsp;<i className="fa fa-search"></i>
                            </span>
                          </OverlayTrigger>
                        </th>
                        <th
                          onClick={(e) => this.changeSorting("status")}
                          className={`border-0 ${
                            this.state.sorting.sortBy === "status"
                              ? this.state.sorting.sortIconClass + " sort-icon"
                              : "sort-icon sort-icon-nutral"
                          }`}
                        >
                          STATUS
                        </th>
                        <th
                          onClick={(e) => this.changeSorting("priority")}
                          className={`border-0 ${
                            this.state.sorting.sortBy === "priority"
                              ? this.state.sorting.sortIconClass + " sort-icon"
                              : "sort-icon sort-icon-nutral"
                          }`}
                        >
                          PRIORITY
                        </th>
                        <th
                          onClick={(e) => this.changeSorting("price")}
                          className={`border-0 ${
                            this.state.sorting.sortBy === "price"
                              ? this.state.sorting.sortIconClass + " sort-icon"
                              : "sort-icon sort-icon-nutral"
                          }`}
                        >
                          PRICE
                        </th>
                        <th
                          onClick={(e) => this.changeSorting("reason")}
                          className={`border-0 ${
                            this.state.sorting.sortBy === "reason"
                              ? this.state.sorting.sortIconClass + " sort-icon"
                              : "sort-icon sort-icon-nutral"
                          }`}
                        >
                          REASON
                        </th>
                        <th
                          onClick={(e) => this.changeSorting("sellerRating")}
                          className={`border-0 ${
                            this.state.sorting.sortBy === "sellerRating"
                              ? this.state.sorting.sortIconClass + " sort-icon"
                              : "sort-icon sort-icon-nutral"
                          }`}
                        >
                          SELLER RATING
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.excelData.map((row, rowKey) => (
                        <>
                          <tr key={rowKey}>
                            <td>
                              <div>
                                <i
                                  data-toggle="collapse"
                                  data-target={".multi-collapse" + rowKey}
                                  aria-controls={
                                    "multiCollapseExampleDomain" + rowKey
                                  }
                                  title="Expend/Collapse row"
                                  class="fa fa-arrows-alt px-1 clickable inline"
                                  aria-hidden="true"
                                ></i>

                                <span className="inline">{rowKey + 1}</span>
                              </div>
                            </td>
                            {this.state[this.state.selectedKey].map(
                              (domainKey, domainKeyIndex) => (
                                <td key={domainKeyIndex}>
                                  {this.renderDomainComponentImage(
                                    domainKey,
                                    row,
                                    rowKey
                                  )}
                                </td>
                              )
                            )}
                          </tr>
                          <tr
                            className={"collapse multi-collapse" + rowKey}
                            id={"multiCollapseExampleWebsite" + rowKey}
                          >
                            <td colSpan={9}>
                              {this.printTrapPurchaseData(row, rowKey)}
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    //}
  }

  renderHtml() {
    if (!this.state.authorised) {
      return <UnauthorisedPage />;
    }

    const options = this.state.brands.map((brand, brandKey) => ({
      value: brand.id,
      label: brand.brandName,
    }));

    return (
      <div className="row">
        {this.props?.login?.user?.role === "Admin" ? (
          <AdminSideNav />
        ) : (
          <AnalystSideNav />
        )}

        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
          <TopNav />

          <div className="main-content-container container-fluid px-4">
            <div className="page-header row no-gutters py-4">
              <div className="col-12">
                <div className="row">
                  <div className="col-4 text-sm-left mb-0">
                    <span className="text-uppercase page-subtitle">
                      UPLOAD PURCHASE
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card card-small mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Company
                            </span>
                            <select
                              onChange={(e) => this.changeCompanySelectBox(e)}
                              className="form-control"
                            >
                              <option value="">Select from dropdown</option>
                              {this.state.companies.map(
                                (company, companyIndex) => (
                                  <option key={companyIndex} value={company.id}>
                                    {company.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Brand
                            </span>
                            <Select
                              options={options}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Status
                            </span>
                            <select
                              onChange={(e) =>
                                this.setState({
                                  infrigementStatusFilter: e.target.value,
                                })
                              }
                              className="form-control"
                            >
                              <option value="">Select from dropdown</option>
                              {getAllPurchaseStatusText().map(
                                (status, index) => (
                                  <option value={status.code}>
                                    {status.text}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Reporting Date
                            </span>
                            <select
                              onChange={(e) =>
                                this.setState({ reportingDate: e.target.value })
                              }
                              className="form-control"
                            >
                              <option value="">Select from dropdown</option>
                              {this.state.availableReportingDate.map(
                                (date, dateIndex) => (
                                  <option key={dateIndex} value={date}>
                                    {moment(date).format("DD/MM/YYYY")}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <button
                              onClick={(e) => this.fetchData()}
                              className="mt-4 btn btn-ldotr"
                            >
                              Fetch Data
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* E-COMMERCE */}
            {this.ecommerceComponent()}
          </div>
          {/* data saved sweet alert */}
          <SweetAlert
            show={this.state.showDataSavedAlert}
            title="Success"
            text="Data Saved Successfully !"
            onConfirm={() => this.setState({ showDataSavedAlert: false })}
          />
          <Footer />
        </main>
      </div>
    );
  }

  render() {
    //const { selectedFiles } = this.state;
    return (
      <>
        {this.renderHtml()}

        <Modal
          className="add-brand-platform"
          id="modal-1"
          size="xl"
          backdrop="static"
          backdropClassName="add-brand-platform"
          keyboard={false}
          show={this.state.showAddEnforcementModal}
          onHide={this.handleCloseAddEnforcementModal}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#d32f2f" }}>
              Upload Purchase
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="row">
                <div className="row">
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Seller Name On Invoice</Form.Label>
                      <Form.Control
                        name="actionTaken"
                        onChange={(e) => this.changeInputBox(e, "sellerName")}
                        value={this.state.sellerName}
                        type="text"
                        placeholder="Seller Name"
                      />
                      <span className="form-text-error-text">
                        {this.state.errorMessage?.sellerName}
                      </span>
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Seller Address On Invoice</Form.Label>
                      <Form.Control
                        name="actionTaken"
                        onChange={(e) =>
                          this.changeInputBox(e, "sellerAddress")
                        }
                        value={this.state.sellerAddress}
                        type="text"
                        placeholder="Seller Address"
                      />
                      <span className="form-text-error-text">
                        {this.state.errorMessage?.sellerAddress}
                      </span>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Seller City</Form.Label>
                      <Form.Control
                        name="actionTaken"
                        onChange={(e) => this.changeInputBox(e, "sellerCity")}
                        value={this.state.sellerCity}
                        type="text"
                        placeholder="Seller City"
                      />
                      <span className="form-text-error-text">
                        {this.state.errorMessage?.sellerCity}
                      </span>
                    </Form.Group>
                  </div>
                  <div className="col-4">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Seller State</Form.Label>
                      <Form.Control
                        name="actionTaken"
                        onChange={(e) => this.changeInputBox(e, "sellerState")}
                        value={this.state.sellerState}
                        type="text"
                        placeholder="Seller State"
                      />
                      <span className="form-text-error-text">
                        {this.state.errorMessage?.sellerState}
                      </span>
                    </Form.Group>
                  </div>
                  <div className="col-4">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Seller Country</Form.Label>
                      <Form.Control
                        name="actionTaken"
                        onChange={(e) =>
                          this.changeInputBox(e, "sellerCountry")
                        }
                        value={this.state.sellerCountry}
                        type="text"
                        placeholder="Seller Country"
                      />
                      <span className="form-text-error-text">
                        {this.state.errorMessage?.sellerCountry}
                      </span>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Price Paid</Form.Label>
                      <Form.Control
                        name="actionTaken"
                        onChange={(e) => this.changeInputBox(e, "pricePaid")}
                        value={this.state.pricePaid}
                        type="text"
                        placeholder="Price Paid"
                      />
                      <span className="form-text-error-text">
                        {this.state.errorMessage?.pricePaid}
                      </span>
                    </Form.Group>
                  </div>
                  <div className="col-4">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Pan</Form.Label>
                      <Form.Control
                        name="actionTaken"
                        onChange={(e) => this.changeInputBox(e, "pan")}
                        value={this.state.pan}
                        type="text"
                        placeholder="Pan"
                      />
                      <span className="form-text-error-text">
                        {this.state.errorMessage?.pan}
                      </span>
                    </Form.Group>
                  </div>
                  <div className="col-4">
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        value={this.state.selectedStatus}
                        onChange={(e) =>
                          this.changeInputBox(e, "selectedStatus")
                        }
                      >
                        {getAllPurchaseStatusText().map((status) => (
                          <option key={status.code} value={status.code}>
                            {status.text}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Invoice</Form.Label>
                      <Form.Control
                        name="actionTaken"
                        onChange={(e) => this.changeInputBox(e, "invoice")}
                        value={this.state.invoice}
                        type="text"
                        placeholder="Invoice"
                      />
                      <span className="form-text-error-text">
                        {this.state.errorMessage?.invoice}
                      </span>
                    </Form.Group>
                  </div>
                  <div className="col-8">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Upload Invoice File</Form.Label>
                      <Form.File
                        id="exampleFormControlFile1"
                        onChange={this.handleInvoiceFile}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>GST Number</Form.Label>
                      <Form.Control
                        name="actionTaken"
                        onChange={(e) => this.changeInputBox(e, "gstNumber")}
                        value={this.state.gstNumber}
                        type="text"
                        placeholder="GST Number"
                      />
                      <span className="form-text-error-text">
                        {this.state.errorMessage?.gstNumber}
                      </span>
                    </Form.Group>
                  </div>
                  <div className="col-8">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Upload GST File</Form.Label>
                      <Form.File
                        id="exampleFormControlFile1"
                        onChange={this.handleGSTFile}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>LdotR Note</Form.Label>
                      <Form.Control
                        name="actionTaken"
                        onChange={(e) => this.changeInputBox(e, "ldotrNote")}
                        value={this.state.ldotrNote}
                        placeholder="LdotR Note"
                        as="textarea"
                        rows={5}
                      />
                      <span className="form-text-error-text">
                        {this.state.errorMessage?.ldotrNote}
                      </span>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Upload Images</Form.Label>
                      <Form.File
                        id="exampleFormControlFile1"
                        onChange={this.handleFileChange}
                        multiple
                      />
                      <span className="form-text-error-text">
                        {this.state.errorMessage?.imageUrls}
                      </span>
                    </Form.Group>
                  </div>
                  {this.state.imageUrls.map((image, index) => (
                    <div key={image} className="col-4">
                      <img
                        src={image}
                        alt="Selected Image"
                        style={{ maxWidth: "100%", marginTop: "10px" }}
                      />
                      <div
                        className="upload__img-close"
                        onClick={() => this.handleImageClose(index)}
                      >
                        &#x2716;
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-12 mt-4">
                  <Button onClick={this.saveEnforcementData}>Save</Button>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
  response: state.response,
});

export default withAlert()(
  connect(mapStateToProps)(authorizeAnalystOrAdmin(requireAuth(UploadPurchase)))
);
