import React from "react";
import Footer from "../../common/Footer.js";
import AnalystSideNav from "../../common/AnalystSideNav.js";
import AdminSideNav from "../../common/AdminSideNav.js";
import UnauthorisedPage from "../Unauthorised";
import TopNav from "../../common/TopNav.js";
import {
  Button,
  Modal,
  Form,
  Table,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import debounce from "lodash.debounce";
import { connect } from "react-redux";
import requireAuth from "../../hoc/requireAuth";
import { withAlert } from "react-alert";
import Select from "react-select";

import authorizeAnalystOrAdmin from "../../hoc/authorizeAnalystOrAdmin";
// Bootstrap CSS
import "../../bulkData.css";
import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";
import SweetAlert from "sweetalert-react";
import "sweetalert/dist/sweetalert.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import * as moment from "moment";
import { castToServerDateFormat } from "../../settings/index";
import {
  fetchInfrigementAllStatus,
  fetchInfrigementEcommerceStatus,
} from "./../../infrigementStatusCode";
const FormData = require("form-data");
const {
  keyToReasonCode,
  reasonCodeToKey,
} = require("../../common/ReasonKeys.js");

class EditBulkData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      enforcementData: [],
      currentInfrigementId: null,
      showModal: false,
      showAddEnforcementModal: false,
      authorised: true,
      platform: "",
      showDataSavedAlert: false,
      showFilesUploadedAlert: false,
      uploadedFiles: {},
      excelData: [],
      companies: [],
      brands: [],
      reportingDate: "",
      company: "",
      brand: "",
      files: [],
      errorMessage: null,
      selectedItems: [],
      dataSaveError: {},
      urlDuplicates: 0,
      lidDuplicates: 0,
      domainKeys: [
        "image",
        "domain",
        "tld",
        "priority",
        "status",
        "advertiser",
        "reason",
        "reasonCode",
        "registrar",
        "ipAddress",
        "hosting",
        "lastMofifiedDate",
        "expiryDate",
        "registrationDate",
        "note",
        "name",
        "org",
        "address",
        "state",
        "country",
        "emailAddress",
        "contactNumber",
        "employeeCode",
      ],
      websiteKeys: [
        "image",
        "domain",
        "url",
        "dateAdded",
        "priority",
        "status",
        "advertiser",
        "reason",
        "reasonCode",
        "employeeCode",
      ],
      socialMediaKeys: [
        "image",
        "platform",
        "heading",
        "link",
        "dateAdded",
        "priority",
        "status",
        "advertiser",
        "reason",
        "reasonCode",
        "employeeCode",
        "PostDate",
        "LikesCount",
        "CommentsCount",
        "SharedCount",
        "PostDuration_yt",
        "Follower/SubscriberCount",
        "FollowingCount",
        "LastPostDate",
        "LastPostComments",
        "LastPostLikes",
        "LastPostShare",
        "LastPostCount",
        "Creation/JoiningDate",
        "PostCount/YouTube_VideoCount",
        "ViewCount",
        "Telegram_LinkCount",
      ],
      ecommerceKeys: [
        "image",
        "site",
        "dateAdded",
        "url",
        "title",
        "price",
        "reason",
        "reasonCode",
        "comparativeLink", //add the Key for Comparative Link
        "comparativePrice", //add the Key for Comparative Price
        "priority",
        "status",
        "username",
        "storeId",
        "address",
        "city",
        "country",
        "Rating",
        "numberOfRatings",
        "numberOfReviews",
        "mrp",
        "sellerRating",
        "contactNumber",
        "pid",
        "lid",
        "discountPercentage",
        "flipkartAssured",
        "employeeCode",
        "sku",
      ],
      appStoreKeys: [
        "image",
        "appName",
        "appStore",
        "dateAdded",
        "url",
        "developerName",
        "latestPublishDate",
        "numberOfDownload",
        "version",
        "status",
        "reason",
        "reasonCode",
        "priority",
        "price",
        "employeeCode",
      ],
      selectedKey: "",
      availableReportingDate: [],
      filters: {},
    };

    this.changeInputEvent = this.changeInputEvent.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showDeleteItemCheckBox = this.showDeleteItemCheckBox.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.deleteSelected = debounce(this.deleteSelected.bind(this), 1000);
  }

  async changeInputBox(e, column) {
    let state = {};
    state[column] = e.target.value;
    await this.setState(() => {
      return state;
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.company !== this.state.company) {
      this.getBrands();
    }
  }

  componentDidMount() {
    this.changeInputEvent();
    this.getCompanies();
  }

  async getCompanies() {
    await window.axios
      .get("/company", {
        params: {
          perPage: 100,
        },
      })
      .then((res) => {
        this.setState({
          companies: res.data.docs,
        });
      });
  }

  async getBrands() {
    await window.axios
      .get("/brand", {
        params: {
          disabledBrands: true,
          company: this.state.company,
          page: 1,
          perPage: 800, //JiraId :- LDOTR-3
        },
      })
      .then((res) => {
        this.setState({
          brands: res.data.docs,
        });
      });
  }

  changeInputBoxValue(value, rowKey, domainKey) {
    let data = this.state.excelData;

    data[rowKey][domainKey] = value;
    data[rowKey].updated = true;

    this.setState({
      excelData: data,
    });
  }

  changeInputEvent(id, rowKey, domainKey) {
    const input = document.getElementById(id);

    if (!input) {
      return;
    }

    this.handleFileUpload(input.files, rowKey, domainKey);
  }

  async handleFileUpload(files, rowKey, domainKey) {
    let formData = new FormData();

    //Adding files to the formdata

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "true",
      },
    };

    await window.axios
      .post("/upload-images-to-s3", formData, options)
      .then((res) => {
        let upoadedFile = res.data.Data[0];
        let data = this.state.excelData;
        data[rowKey].image = upoadedFile.uploadedImage;
        data[rowKey].imageUrl = upoadedFile.Location;
        data[rowKey].s3BucketName = upoadedFile.Bucket;
        data[rowKey].s3FullImagePath = upoadedFile.Key;
        data[rowKey].updated = true;
        this.setState({ excelData: data });
      });
  }

  async fetchData() {
    const options = {
      headers: { "Content-Type": "application/json" },
    };
    this.setState(() => ({
      dataSaveError: {},
      urlDuplicates: 0,
      lidDuplicates: 0,
    }));
    await window.axios
      .post(
        "/save-bulk-data/fetch-bulk-data",
        {
          company: this.state.company,
          brand: this.state.brand,
          reportingDate: this.state.reportingDate,
          platform: this.state.platform,
          ...this.state.filters,
        },
        options
      )
      .then((res) => {
        this.setState({ excelData: res.data.data });
      })
      .catch(function (error) {
        alert(error.response.data.message);
      });
  }

  async saveData() {
    const options = {
      headers: { "Content-Type": "application/json" },
    };
    this.setState(() => ({
      dataSaveError: {},
      urlDuplicates: 0,
      lidDuplicates: 0,
    }));
    let updatedData = [];

    let invalidReasonCode = false;

    this.state.excelData.map((data) => {
      if (data.updated == true) {
        if (
          this.state.platform === "e-commerce" ||
          this.state.platform === "domain" ||
          this.state.platform === "social-media" ||
          this.state.platform === "website" ||
          this.state.platform === "app-store"
        ) {
          // if (
          //   data.reasonCode === "000" ||
          //   data.reasonCode === "123" ||
          //   data.reasonCode === "567" ||
          //   data.reasonCode === "234" ||
          //   data.reasonCode === "891" ||
          //   data.reasonCode === "912" ||
          //   data.reasonCode === "789" ||
          //   data.reasonCode === "678" ||
          //   data.reasonCode === "345" ||
          //   data.reasonCode === "456" ||
          //   data.reasonCode.toUpperCase() === "NOTAPPLICABLE" ||
          //   data.reasonCode.toUpperCase() === "TM" ||
          //   data.reasonCode.toUpperCase() === "MRP" ||
          //   data.reasonCode.toUpperCase() === "FAKE" ||
          //   data.reasonCode.toUpperCase() === "COMPLIANCE" ||
          //   data.reasonCode.toUpperCase() === "DESIGN" ||
          //   data.reasonCode.toUpperCase() === "IMPORT" ||
          //   data.reasonCode.toUpperCase() === "EXTENSIONS" ||
          //   data.reasonCode.toUpperCase() === "COMBO" ||
          //   data.reasonCode.toUpperCase() === "TRADEDRESS"
          // ) {
          if (
            reasonCodeToKey[data.reasonCode] ||
            keyToReasonCode[data.reasonCode]
          ) {
            if (keyToReasonCode[data.reasonCode]) {
              data.reasonCode = keyToReasonCode[data.reasonCode];
            }
            updatedData.push(data);
          } else {
            invalidReasonCode = true;
            // Display error message for invalid reason code
          }
        } else {
          updatedData.push(data); //Bug Id:- LDOTR-4
        }
      }
    });

    if (invalidReasonCode) {
      this.props.alert.show("Invalid Reason Code", { type: "error" });
      return;
    }

    await window.axios
      .post(
        "/save-bulk-data/bulk-update",
        {
          data: updatedData,
          company: this.state.company,
          brand: this.state.brand,
          reportingDate: this.state.reportingDate,
          platform: this.state.platform,
        },
        options
      )
      .then((res) => {
        // this.setState({ showDataSavedAlert: true });
        this.setState({ excelData: [] });
        this.props.alert.show("data updated successfully", { type: "success" });
      })
      .catch((error) => {
        if (this.state.platform === "e-commerce") {
          this.setState(() => ({
            dataSaveError: error.response.data?.errors,
            urlDuplicates: error.response.data?.errors.urlCount,
            lidDuplicates: error.response.data?.errors.lidCount,
          }));
          if (error.response.data?.errors?.data[0])
            return this.props.alert.show(
              error.response.data?.errors?.data[0] || "Something went wrong",
              { type: "error" }
            );
          this.props.alert.show("URL or lid duplicates!", { type: "error" });
        } else if (this.state.platform === "domain") {
          this.setState(() => ({
            dataSaveError: error.response.data?.errors,
            urlDuplicates: error.response.data?.errors.urlCount,
            lidDuplicates: error.response.data?.errors.lidCount,
          }));
          this.props.alert.show("Domain(s) found in whitelabel list", {
            type: "error",
          });
        } else if (this.state.platform === "social-media") {
          this.setState(() => ({
            dataSaveError: error.response.data?.errors,
            urlDuplicates: error.response.data?.errors.urlCount,
            lidDuplicates: error.response.data?.errors.lidCount,
          }));
          this.props.alert.show("Links found in whitelabel list", {
            type: "error",
          });
        }
      });
  }

  renderDomainComponentImage(domainKey, row, rowKey) {
    if (domainKey === "status" && this.state.platform !== "e-commerce") {
      return (
        <select
          className="form-control"
          onChange={(e) =>
            this.changeInputBoxValue(e.target.value, rowKey, domainKey)
          }
        >
          {fetchInfrigementAllStatus().map((status, index) => (
            <option
              selected={row[domainKey] === status.code ? "selected" : ""}
              key={index}
              value={status.code}
            >
              {status.text}
            </option>
          ))}
        </select>
      );
    } else if (domainKey === "status" && this.state.platform === "e-commerce") {
      return (
        <select
          value={row[domainKey]}
          className="form-control"
          onChange={(e) =>
            this.changeInputBoxValue(e.target.value, rowKey, domainKey)
          }
        >
          {fetchInfrigementEcommerceStatus().map((status, index) => (
            <option
              selected={row[domainKey] === status.code ? "selected" : ""}
              key={index}
              value={status.code}
            >
              {status.text}
            </option>
          ))}
        </select>
      );
    } else if (domainKey === "image") {
      return (
        <>
          {row.imageUrl ? (
            <img width="50px" src={row.imageUrl} alt="infringement-images" />
          ) : (
            <span>IMAGE NOT AVAILABLE</span>
          )}
          <input
            id={"input" + rowKey}
            type="file"
            name={domainKey}
            onChange={(e) =>
              this.changeInputEvent(e.target.id, rowKey, domainKey)
            }
            className="form-control"
          />
        </>
      );
    } else if (domainKey === "url" && this.state.platform === "e-commerce") {
      return (
        <input
          name={domainKey}
          onChange={(e) =>
            this.changeInputBoxValue(e.target.value, rowKey, domainKey)
          }
          value={
            [
              "lastMofifiedDate",
              "expiryDate",
              "registrationDate",
              "dateAdded",
              "latestPublishDate",
            ].includes(domainKey)
              ? castToServerDateFormat(row[domainKey])
              : row[domainKey]
          }
          className={
            this.state.dataSaveError &&
            ((this.state.dataSaveError?.duplicates &&
              row["url"] &&
              this.state.dataSaveError?.duplicates[row["url"]]) ||
              (this.state.dataSaveError?.duplicates &&
                this.state.dataSaveError?.duplicates[row.id]?.url))
              ? "form-control duplicate-errors"
              : "form-control"
          }
        />
      );
    } else if (domainKey === "lid" && this.state.platform === "e-commerce") {
      return (
        <input
          name={domainKey}
          onChange={(e) =>
            this.changeInputBoxValue(e.target.value, rowKey, domainKey)
          }
          value={
            [
              "lastMofifiedDate",
              "expiryDate",
              "registrationDate",
              "dateAdded",
              "latestPublishDate",
            ].includes(domainKey)
              ? castToServerDateFormat(row[domainKey])
              : row[domainKey]
          }
          className={
            this.state.dataSaveError &&
            ((this.state.dataSaveError?.duplicates &&
              row["lid"] &&
              this.state.dataSaveError?.duplicates[row["lid"]]) ||
              (this.state.dataSaveError?.duplicates &&
                this.state.dataSaveError?.duplicates[row.id]?.lid))
              ? "form-control duplicate-errors"
              : "form-control"
          }
        />
      );
    }
    ////    LDOTR-10 Start    ////
    //Disable the DateAdded Column in all plattform.
    else if (domainKey.toUpperCase() === "DATEADDED") {
      return (
        <input
          name={domainKey}
          onChange={(e) =>
            this.changeInputBoxValue(e.target.value, rowKey, domainKey)
          }
          value={
            [
              "lastMofifiedDate",
              "expiryDate",
              "registrationDate",
              "dateAdded",
              "latestPublishDate",
            ].includes(domainKey)
              ? castToServerDateFormat(row[domainKey])
              : row[domainKey]
          }
          className="form-control"
          disabled={true}
        />
      );
    } else if (domainKey.toUpperCase() === "EMPLOYEECODE") {
      //New field added for employeecode
      return (
        <input
          name={domainKey}
          onChange={(e) =>
            this.changeInputBoxValue(e.target.value, rowKey, domainKey)
          }
          value={
            [
              "lastMofifiedDate",
              "expiryDate",
              "registrationDate",
              "dateAdded",
              "latestPublishDate",
            ].includes(domainKey)
              ? castToServerDateFormat(row[domainKey])
              : row[domainKey]
          }
          className="form-control"
          disabled={true}
        />
      );
    }
    ////    LDOTR-10 End    ////
    else {
      if (
        [
          "PostDate",
          "LikesCount",
          "CommentsCount",
          "SharedCount",
          "Follwer/SubscriberCount",
          "LastPostDate",
          "LastPostComments",
          "LastPostLikes",
          "LastPostShare",
          "Creation/JoiningDate",
          "FollowingCount",
          "LastPostCount",
          "ViewCount",
          "PostCount/YouTube_VideoCount",
          "PostDuration_yt",
          "Telegram_LinkCount",
        ].includes(domainKey)
      ) {
        return this.renderNewColumnSocialMedia(row, rowKey, domainKey);
      } else {
        return (
          <input
            name={domainKey}
            onChange={(e) => {
              this.changeInputBoxValue(e.target.value, rowKey, domainKey);
              if (
                domainKey === "username" &&
                this.state.platform === "e-commerce"
              ) {
                if (this.state.dataSaveError?.whiteLabelUrl) {
                  delete this.state.dataSaveError.duplicates[row["url"]];
                }
              }
            }}
            value={
              [
                "lastMofifiedDate",
                "expiryDate",
                "registrationDate",
                "dateAdded",
                "latestPublishDate",
              ].includes(domainKey)
                ? castToServerDateFormat(row[domainKey])
                : row[domainKey]
            }
            className="form-control"
          />
        );
      }
    }
  }

  renderNewColumnSocialMedia(row, rowKey, domainKey) {
    return (
      <input
        name={domainKey}
        onChange={(e) =>
          this.changeInputBoxValue(e.target.value, rowKey, domainKey)
        }
        value={row[domainKey] || ""}
        className={
          domainKey === "url"
            ? this.state.dataSaveError &&
              this.state.dataSaveError?.duplicates &&
              row["url"] &&
              this.state.dataSaveError?.duplicates[row["url"]]
              ? "form-control duplicate-errors"
              : "form-control"
            : "form-control"
        }
      />
    );
  }

  changePlatformSelectBox(e) {
    let selectedKeyValue = "domainKeys";

    let key = e.target.value;

    if (key == "website") {
      selectedKeyValue = "websiteKeys";
    }
    if (key == "social-media") {
      selectedKeyValue = "socialMediaKeys";
    }
    if (key == "e-commerce") {
      selectedKeyValue = "ecommerceKeys";
    }
    if (key == "app-store") {
      selectedKeyValue = "appStoreKeys";
    }

    this.setState({
      platform: e.target.value,
      excelData: [],
      selectedKey: selectedKeyValue,
      selectedItems: [],
    });

    this.fetchAvailableReportingDate(key);
  }

  async fetchAvailableReportingDate(platform) {
    const options = {
      headers: { "Content-Type": "application/json" },
    };

    await window.axios
      .post(
        "/save-bulk-data/fetch-available-reporting-dates",
        {
          company: this.state.company,
          brand: this.state.brand,
          platform: platform,
        },
        options
      )
      .then((res) => {
        this.setState({ availableReportingDate: res.data });
      })
      .catch(function (error) {
        alert(error.response.data.message);
      });
  }
  changeSelectedItems(e, item) {
    let selectedItems = this.state.selectedItems;

    if (e.target.checked) {
      selectedItems.push(item.id);
    } else {
      var index = selectedItems.indexOf(item.id);
      if (index !== -1) {
        selectedItems.splice(index, 1);
      }
    }

    this.setState(() => ({
      selectedItems,
    }));
  }

  async deleteSelected() {
    const options = {
      headers: { "Content-Type": "application/json" },
    };
    await window.axios
      .post(
        "/save-bulk-data/delete-multiple",
        {
          company: this.state.company,
          brand: this.state.brand,
          platform: this.state.platform,
          data: this.state.selectedItems,
        },
        options
      )
      .then((res) => {
        this.setState(() => ({
          selectedItems: [],
        }));
        this.fetchData();
      })
      .catch(function (error) {
        alert(error.response.data.message);
      });
  }

  showDeleteItemsBtn() {
    if (this.state.selectedItems.length) {
      return (
        <span
          className="text-danger"
          onClick={() => this.deleteSelected()}
          style={{ float: "right", cursor: "pointer" }}
        >
          <i className="fa fa-trash"></i>
        </span>
      );
    }
  }

  handleChange = (selectedOption) => {
    const selectedBrand = this.state.brands.find(
      (brand) => brand.id === selectedOption.value
    );

    this.setState({
      brand: selectedOption.value,
      brandId: selectedBrand ? selectedBrand.id : null,
    });
  };

  // showDeleteItemCheckBox(row) {
  //   return (
  //     <>
  //       <td>
  //         <input
  //           checked={this.state.selectedItems.indexOf(row.id) !== -1}
  //           onChange={(e) => this.changeSelectedItems(e, row)}
  //           type="checkbox"
  //         />
  //       </td>
  //     </>
  //   );
  // }

  changeFilterValue(columnName, value) {
    let filters = this.state.filters;

    filters[columnName] = value;

    this.setState(() => ({
      filters,
    }));
  }

  renderFilterPopover(columnName) {
    return (
      <Popover id="popover-positioned-right" title="Popover right">
        <div className="container py-1 px-1" style={{ width: "100%" }}>
          <div className="row">
            <div className="col-7 mx-0">
              <input
                onChange={(e) =>
                  this.changeFilterValue(columnName, e.target.value)
                }
                value={this.state.filters[columnName]}
                className="form-control"
              />
            </div>
            <div className="col-5 mx-0">
              <button
                onClick={(e) => this.fetchData()}
                className="btn btn-success"
              >
                Apply Filter
              </button>
            </div>
          </div>
        </div>
      </Popover>
    );
  }

  handleSelectAll = (e) => {
    const { excelData } = this.state;
    const allRowIds = excelData.map((row) => row.id);

    if (e.target.checked) {
      this.setState({
        selectAll: true,
        selectedItems: allRowIds,
      });
    } else {
      this.setState({
        selectAll: false,
        selectedItems: [],
      });
    }
  };

  showDeleteItemCheckBox(row, rowKey) {
    return (
      <>
        <td>
          <div style={{ display: "inline-block", marginRight: "5px" }}>
            <input
              checked={
                this.state.selectedItems.indexOf(row.id) === -1 ? "" : "checked"
              }
              onChange={(e) => this.changeSelectedItems(e, row)}
              type="checkbox"
            />
          </div>
          <span className="text-muted">{rowKey + 1}</span>
        </td>
      </>
    );
  }

  domainComponent() {
    if (this.state.platform === "domain") {
      return (
        <div className="col">
          <div className="card card-small mb-4">
            <div className="card-body">
              <h5 className="card-title">
                Domain
                {this.showDeleteItemsBtn()}
              </h5>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive fix-height">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="custom-width-th-40px">#</th>
                          <th>IMAGE</th>
                          <th>
                            DOMAIN
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("domain")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>
                            TLD
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("tld")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>PRIORITY</th>
                          <th>STATUS</th>
                          <th>ADVERTISER</th>
                          <th>REASON</th>
                          <th>
                            REASON CODE
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("reasonCode")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>REGISTRAR</th>
                          <th>IP ADDRESS</th>
                          <th>HOSTING</th>
                          <th>LAST MODIFICATION DATE</th>
                          <th>EXPIRY DATE</th>
                          <th>REGISTRATION DATE</th>
                          <th>NOTE</th>
                          <th>NAME</th>
                          <th>ORG</th>
                          <th>ADDRESS</th>
                          <th>STATE</th>
                          <th>COUNTRY</th>
                          <th>EMAIL ADDRESS</th>
                          <th>CONTACT NUMBER</th>
                          <th>
                            Employee Code
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="left"
                              overlay={this.renderFilterPopover("employeeCode")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ height: "30px !important" }}>
                          {" "}
                          {/* Use double curly braces to define the inline style */}
                          <td>
                            <h6>ALL</h6>
                            <input
                              type="checkbox"
                              checked={this.state.selectAll}
                              onChange={this.handleSelectAll}
                              className="ml-auto"
                            />
                          </td>
                        </tr>
                        {this.state.excelData.map((row, rowKey) => (
                          <tr
                            className={
                              this.state.dataSaveError &&
                              this.state.dataSaveError?.duplicates &&
                              row["domain"] &&
                              this.state.dataSaveError?.duplicates[
                                row["domain"]
                              ]
                                ? "duplicate-error"
                                : ""
                            }
                            key={rowKey}
                          >
                            {this.showDeleteItemCheckBox(row, rowKey)}
                            {this.state[this.state.selectedKey].map(
                              (domainKey, domainKeyIndex) => (
                                <td key={domainKeyIndex} className="form-group">
                                  {this.renderDomainComponentImage(
                                    domainKey,
                                    row,
                                    rowKey
                                  )}
                                </td>
                              )
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  websiteComponent() {
    if (this.state.platform === "website") {
      return (
        <div className="col">
          <div className="card card-small mb-4">
            <div className="card-body">
              <h5 className="card-title">
                Website
                {this.showDeleteItemsBtn()}
              </h5>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive fix-height">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>IMAGE</th>
                          <th>
                            DOMAIN
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("domain")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>
                            URL
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("url")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>DATE ADDED</th>
                          <th>PRIORITY</th>
                          <th>STATUS</th>
                          <th>ADVERTISER</th>
                          <th>REASON</th>
                          <th>
                            REASON CODE
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("reasonCode")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>
                            Employee Code
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="left"
                              overlay={this.renderFilterPopover("employeeCode")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ height: "30px !important" }}>
                          {" "}
                          {/* Use double curly braces to define the inline style */}
                          <td>
                            <h6>ALL</h6>
                            <input
                              type="checkbox"
                              checked={this.state.selectAll}
                              onChange={this.handleSelectAll}
                              className="ml-auto"
                            />
                          </td>
                        </tr>
                        {this.state.excelData.map((row, rowKey) => (
                          <tr key={rowKey}>
                            {this.showDeleteItemCheckBox(row, rowKey)}
                            {this.state[this.state.selectedKey].map(
                              (domainKey, domainKeyIndex) => (
                                <td key={domainKeyIndex} className="form-group">
                                  {this.renderDomainComponentImage(
                                    domainKey,
                                    row,
                                    rowKey
                                  )}
                                </td>
                              )
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  socialMediaComponent() {
    if (this.state.platform === "social-media") {
      return (
        <div className="col">
          <div className="card card-small mb-4">
            <div className="card-body">
              <h5 className="card-title">
                Social Media
                {this.showDeleteItemsBtn()}
              </h5>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive fix-height">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>IMAGE</th>
                          <th>PLATFORM</th>
                          <th>
                            HEADING
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("heading")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>
                            LINK
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("link")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>DATE ADDED</th>
                          <th>PRIORITY</th>
                          <th>STATUS</th>
                          <th>ADVERTISER</th>
                          <th>REASON</th>
                          <th>
                            REASON CODE
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("reasonCode")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>
                            Employee Code
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="left"
                              overlay={this.renderFilterPopover("employeeCode")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>PostDate</th>
                          <th>LikesCount</th>
                          <th>CommentsCount</th>
                          <th>SharedCount</th>
                          <th>PostDuration_yt</th>
                          <th>Follower/SubscriberCount</th>
                          <th>FollowingCount</th>
                          <th>LastPostDate</th>
                          <th>LastPostComments</th>
                          <th>LastPostLikes</th>
                          <th>LastPostShare</th>
                          <th>LastPostCount</th>
                          <th>Creation/JoiningDate</th>
                          <th>PostCount/YouTube_VideoCount</th>
                          <th>ViewCount</th>
                          <th>Telegram_LinkCount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ height: "30px !important" }}>
                          {" "}
                          {/* Use double curly braces to define the inline style */}
                          <td>
                            <h6>ALL</h6>
                            <input
                              type="checkbox"
                              checked={this.state.selectAll}
                              onChange={this.handleSelectAll}
                              className="ml-auto"
                            />
                          </td>
                        </tr>
                        {this.state.excelData.map((row, rowKey) => {
                          const duplicateError =
                            this.state.dataSaveError &&
                            this.state.dataSaveError?.duplicates &&
                            this.state.dataSaveError?.duplicates?.[row["link"]];
                          return (
                            <tr
                              key={rowKey}
                              className={
                                duplicateError ? "duplicate-error" : ""
                              }
                            >
                              {this.showDeleteItemCheckBox(row, rowKey)}
                              {this.state[this.state.selectedKey].map(
                                (domainKey, domainKeyIndex) => (
                                  <td
                                    key={domainKeyIndex}
                                    className="form-group"
                                  >
                                    {this.renderDomainComponentImage(
                                      domainKey,
                                      row,
                                      rowKey
                                    )}
                                  </td>
                                )
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  ecommerceComponent() {
    if (this.state.platform === "e-commerce") {
      return (
        <div className="col">
          <div className="card card-small mb-4">
            <div className="card-body">
              <h5 className="card-title">
                E-COMMERCE
                {this.showDeleteItemsBtn()}
              </h5>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive fix-height">
                    <table className="table">
                      {/* add the for Comparative Link and Comparative Price header*/}
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>IMAGE</th>
                          <th>
                            SITE
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("site")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>DATE ADDED</th>
                          <th>
                            URL
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("url")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>
                            TITLE
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("title")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>PRICE</th>
                          <th>REASON</th>
                          <th>
                            REASON CODE
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("reasonCode")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>
                            COMPARATIVE LINK
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover(
                                "comparativeLink"
                              )}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>COMPARATIVE PRICE</th>
                          <th>PRIORITY</th>
                          <th>STATUS</th>
                          <th>USERNAME</th>
                          <th>STORE ID</th>
                          <th>ADDRESS</th>
                          <th>CITY</th>
                          <th>COUNTRY</th>
                          <th>Rating</th>
                          <th>Number of Ratings</th>
                          <th>Number of Reviews</th>
                          <th>MRP</th>
                          <th>Seller Rating</th>
                          <th>Contact Number</th>
                          <th>PID</th>
                          <th>LID</th>
                          <th>Discount Percentage</th>
                          <th>Flipkart Assured</th>
                          <th>
                            Employee Code
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="left"
                              overlay={this.renderFilterPopover("employeeCode")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>SKU</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr style={{ height: "30px !important" }}>
                          {" "}
                          {/* Use double curly braces to define the inline style */}
                          <td>
                            <h6>ALL</h6>
                            <input
                              type="checkbox"
                              checked={this.state.selectAll}
                              onChange={this.handleSelectAll}
                              className="ml-auto"
                            />
                          </td>
                        </tr>
                        {this.state.excelData.map((row, rowKey) => {
                          let isError = false;
                          const url = row?.url;
                          if (this.state.dataSaveError?.whiteLabelUrl >= 1) {
                            if (this.state.dataSaveError?.duplicates[url]) {
                              isError = true;
                            }
                          }
                          return (
                            <tr
                              key={rowKey}
                              className={isError ? "duplicate-error" : ""}
                            >
                              {this.showDeleteItemCheckBox(row, rowKey)}
                              {this.state[this.state.selectedKey].map(
                                (domainKey, domainKeyIndex) => (
                                  <td
                                    key={domainKeyIndex}
                                    className="form-group"
                                  >
                                    {this.renderDomainComponentImage(
                                      domainKey,
                                      row,
                                      rowKey
                                    )}
                                  </td>
                                )
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  appStoreComponent() {
    if (this.state.platform === "app-store") {
      return (
        <div className="col">
          <div className="card card-small mb-4">
            <div className="card-body">
              <h5 className="card-title">
                APP STORE
                {this.showDeleteItemsBtn()}
              </h5>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive fix-height">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>IMAGE</th>
                          <th>APP NAME</th>
                          <th>
                            APP STORE
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="left"
                              overlay={this.renderFilterPopover("appStore")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>DATE ADDED</th>
                          <th>
                            URL
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="left"
                              overlay={this.renderFilterPopover("url")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>DEVELOPER NAME</th>
                          <th>LATEST PUBLISH DATE</th>
                          <th>NUMBER OF DOWNLOADS</th>
                          <th>VERSION</th>
                          <th>STATUS</th>
                          <th>REASON</th>
                          <th>
                            REASON CODE
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={this.renderFilterPopover("reasonCode")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>PRIORITY</th>
                          <th>PRICE</th>
                          <th>
                            Employee Code
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="left"
                              overlay={this.renderFilterPopover("employeeCode")}
                            >
                              <span
                                className="capitalize-text filter-parent-tag"
                                href="#"
                              >
                                &nbsp;
                                <i
                                  className="fa fa-search"
                                  style={{ marginLeft: "16px" }}
                                ></i>
                              </span>
                            </OverlayTrigger>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ height: "30px !important" }}>
                          {" "}
                          {/* Use double curly braces to define the inline style */}
                          <td>
                            <h6>ALL</h6>
                            <input
                              type="checkbox"
                              checked={this.state.selectAll}
                              onChange={this.handleSelectAll}
                              className="ml-auto"
                            />
                          </td>
                        </tr>
                        {this.state.excelData.map((row, rowKey) => (
                          <tr key={rowKey}>
                            {this.showDeleteItemCheckBox(row, rowKey)}
                            {this.state[this.state.selectedKey].map(
                              (domainKey, domainKeyIndex) => (
                                <td key={domainKeyIndex} className="form-group">
                                  {this.renderDomainComponentImage(
                                    domainKey,
                                    row,
                                    rowKey
                                  )}
                                </td>
                              )
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  renderHtml() {
    if (!this.state.authorised) {
      return <UnauthorisedPage />;
    }

    const options = this.state.brands.map((brand, brandKey) => ({
      value: brand.id,
      label: brand.brandName,
    }));
    //// LDOTR-1 Start////
    //If any duplicate lids and urls are present then display error
    let duplicateMessage;
    if (this.state.lidDuplicates > 0 || this.state.urlDuplicates > 0) {
      if (this.state.lidDuplicates > 0 && this.state.urlDuplicates > 0) {
        duplicateMessage =
          this.state.lidDuplicates +
          " Duplicate LIDs and " +
          this.state.urlDuplicates +
          " Duplicate URLs present.";
      } else if (this.state.lidDuplicates > 0) {
        duplicateMessage =
          this.state.lidDuplicates + " Duplicate LIDs present.";
      } else if (this.state.urlDuplicates > 0) {
        duplicateMessage =
          this.state.urlDuplicates + " Duplicate URLs present.";
      } else {
        duplicateMessage = "";
      }
    }
    //// LDOTR-1 End////

    return (
      <div className="row">
        {this.props?.login?.user?.role === "Admin" ? (
          <AdminSideNav />
        ) : (
          <AnalystSideNav />
        )}

        <main
          id="data-upload-screen"
          className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3"
        >
          <TopNav />

          <div className="main-content-container container-fluid px-4">
            <div className="page-header row no-gutters py-4">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-sm-4 text-left text-sm-left mb-0">
                    <span className="text-uppercase page-subtitle">
                      Edit Data
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card card-small mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Company
                            </span>
                            <select
                              onChange={(e) =>
                                this.setState({ company: e.target.value })
                              }
                              className="form-control"
                            >
                              <option>Select from dropdown</option>
                              {this.state.companies.map(
                                (company, companyIndex) => (
                                  <option key={companyIndex} value={company.id}>
                                    {company.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Brand
                            </span>
                            {/* <select
                              onChange={(e) =>
                                this.setState({ brand: e.target.value })
                              }
                              className="form-control"
                            >
                              <option>Select from dropdown</option>
                              {this.state.brands.map((brand, brandKey) => (
                                <option key={brandKey} value={brand.id}>
                                  {brand.brandName}
                                </option>
                              ))}
                            </select> */}
                            <Select
                              options={options}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Platform
                            </span>
                            <select
                              onChange={(e) => this.changePlatformSelectBox(e)}
                              className="form-control"
                            >
                              <option>Select from dropdown</option>
                              <option value="domain">Domain</option>
                              <option value="website">Website</option>
                              <option value="social-media">Social Media</option>
                              <option value="e-commerce">E-commerce</option>
                              <option value="app-store">App Store</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Reporting Date
                            </span>
                            <select
                              onChange={(e) =>
                                this.setState({ reportingDate: e.target.value })
                              }
                              className="form-control"
                            >
                              <option>Select from dropdown</option>
                              {this.state.availableReportingDate.map(
                                (date, dateIndex) => (
                                  <option key={dateIndex} value={date}>
                                    {moment(date).format("DD/MM/YYYY")}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="form-group">
                            <button
                              onClick={(e) => this.fetchData()}
                              className="mt-4 btn btn-ldotr"
                            >
                              Fetch Data
                            </button>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <button
                              onClick={(e) => this.saveData()}
                              className="mt-4 btn btn-ldotr"
                            >
                              Upload Data
                            </button>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div
                            className="mt-4 form-group"
                            style={{ color: "red" }}
                          >
                            {duplicateMessage}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Domain Upload Screen */}
            {this.domainComponent()}
            {/* Website upload screen */}
            {this.websiteComponent()}
            {/* Social Media Screen */}
            {this.socialMediaComponent()}
            {/* E-COMMERCE */}
            {this.ecommerceComponent()}
            {/* APP STORE */}
            {this.appStoreComponent()}
          </div>
          {/* data saved sweet alert */}
          <SweetAlert
            show={this.state.showDataSavedAlert}
            title="Success"
            text="Data Saved Successfully !"
            onConfirm={() => this.setState({ showDataSavedAlert: false })}
          />
          <Footer />
        </main>
      </div>
    );
  }

  render() {
    return <>{this.renderHtml()}</>;
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
  response: state.response,
});

// export default connect(mapStateToProps)(
//   authorizeAnalyst(requireAuth(EditBulkData))
// );
export default withAlert()(
  connect(mapStateToProps)(authorizeAnalystOrAdmin(requireAuth(EditBulkData)))
);
