import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Footer = () => {
  return (
    <footer className="bg-white border-top py-3 d-flex align-items-center justify-content-between px-4 shadow-sm">
      <nav className="d-none d-md-flex gap-3">
        {/* <a href="#" className="text-muted text-decoration-none">
          Home
        </a>
        <a href="#" className="text-muted text-decoration-none">
          Services
        </a>
        <a href="#" className="text-muted text-decoration-none">
          About
        </a>
        <a href="#" className="text-muted text-decoration-none">
          Products
        </a>
        <a href="#" className="text-muted text-decoration-none">
          Blog
        </a> */}
      </nav>
      <span className="text-muted small">
        Copyright &copy; {new Date().getFullYear()}&nbsp;
        <a
          href="https://www.ldotr.red"
          className="text-primary text-decoration-none"
          rel="nofollow"
        >
          LDotR
        </a>
      </span>
    </footer>
  );
};

export default Footer;
