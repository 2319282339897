import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Footer from "../../common/Footer.js";
import ClientSideNav from "../../common/ClientSideNav";
import TopNav from "../../common/TopNav.js";
import requireAuth from "../../hoc/requireAuth";
import authorizeClient from "../../hoc/authorizeClient";
import NotAccessablePage from "../NotAccessablePage.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/src/collapse.js";
import { connect } from "react-redux";
import "bootstrap-daterangepicker/daterangepicker.css";
import { withAlert } from "react-alert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Select from "react-select";

const ClientRequestDashboard = (props) => {
  const user = useSelector((state) => state.login.user);
  const selectedCompany = useSelector((state) => state.selectedCompany);
  const [company, setCompany] = useState("");
  const [companies, setCompanies] = useState([]);
  const [brand, setBrand] = useState("");
  const [brands, setBrands] = useState([]);
  const [platform, setPlatform] = useState("");
  const [text, setText] = useState("");
  const [isDisabledField, setIsDisabledField] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [rows, setRows] = useState([]);
  const [domainUniqueSites, setDomainUniqueSites] = useState([]);
  const [availablePlatforms, setAvailablePlatforms] = useState(null);

  useEffect(() => {
    if (user?.companyDetails) {
      setCompanies(user?.companyDetails);
    }

    if (selectedCompany?.companyName) {
      setCompany(selectedCompany.id);
      fetchAvailableBrands(selectedCompany.id || "");
    }
  }, []);

  useEffect(() => {
    if (company) {
      fetchAvailableBrands(company || "");
    }
  }, [company]);

  useEffect(() => {
    if (brand) {
      let selectedBrand = brands.filter((b) => b.id === brand);

      if (selectedBrand.length) {
        let platform = selectedBrand[0].platform;
        setAvailablePlatforms(platform);
      } else {
        setAvailablePlatforms(null);
      }
    }
  }, [brand, brands]);

  const fetchAvailableBrands = async (company) => {
    try {
      const res = await window.axios.get(`/brand/client/${company}`, {
        params: {
          disabledBrands: "true",
          page: 1,
          perPage: 800,
        },
      });
      setBrands(res?.data?.docs || []);
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  const submitDomainAndURLS = async () => {
    try {
        const res = await window.axios.post("/client-request", {
            rows: rows,
            company: company,
            platform: platform,
            brand: brand,
        });

        Swal.fire({
            icon: "success",
            title: "Submitted Successfully",
            text: "Your request has been submitted successfully!",
        });
        setRows([]);
        setIsDisabledField(false);
    } catch (err) {
        console.error("Error submitting data:", err);

        if (err.response && err.response.status === 409) {
            const domainUrls = err.response.data.domainUrls || [];

            // Count duplicates
            const duplicateCount = domainUrls.filter(item => item.isDuplicate).length;

            // Count existing records but exclude those that are also duplicates
            const existingCount = domainUrls.filter(item => item.isExists && !item.isDuplicate).length;

            let errorMessage = "";
            if (duplicateCount > 0 && existingCount > 0) {
              errorMessage = `${duplicateCount} duplicate ${duplicateCount > 1 ? 'entries' : 'entry'} found.<br>${existingCount} existing ${existingCount > 1 ? 'records' : 'record'} found.`;
          } else if (duplicateCount > 0) {
              errorMessage = `${duplicateCount} duplicate ${duplicateCount > 1 ? 'entries' : 'entry'} found.`;
          } else if (existingCount > 0) {
              errorMessage = `${existingCount} existing ${existingCount > 1 ? 'records' : 'record'} found.`;
          }          

            Swal.fire({
                icon: "error",
                title: "Submission Failed",
                html: errorMessage,
            });

            setRows([...domainUrls]);
        } else {
            Swal.fire({
                icon: "error",
                title: "Submission Failed",
                text: "An unexpected error occurred. Please try again later.",
            });
        }
    }
};

  const uploadDomainAndURLS = async () => {
    if (!brand) {
      alert("Please select Brand");
    } else if (!platform) {
      alert("Please select platform");
    } else if (!text.trim()) {
      alert("Please enter Domains / URLs");
      return;
    }

    const newRows = text
      .split("\n")
      .filter((line) => line.trim() !== "")
      .map((line, index) => ({
        //id: rows.length + index + 1,
        brand: brand,
        domain: line.trim(),
        remarks: remarks.trim(),
      }));

    const res = await window.axios.post(
      `/client-request/uploadMultipleDomain`,
      {
        company: company,
        brand: brand,
        platform: platform,
        domainUrls: newRows,
      }
    );
    setRows([...rows, ...res.data.domainUrls]);
    setIsDisabledField(true);
    setText("");
    setRemarks("");
  };

  const addNewRecord = () => {
    setRows([
      ...rows,
      { id: rows.length + 1, brand: rows[0].brand, domain: "", remarks: "" },
    ]);
  };

  const clearRecords = () => {
    setRows([]);
    setIsDisabledField(false);
  };

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index] ={
      ...updatedRows[index],
      [field]:value,
      isExists: false,
      isDuplicate: false,
    }
    setRows(updatedRows);
  };

  const deleteRecord = (index) => {
    // Remove the selected row
    const updatedRows = rows.filter((_, i) => i !== index);

    // Helper function to normalize domain URLs
    const normalizeUrl = (url) => {
      return url
        .trim()
        .replace(/^(https?:\/\/)?(www\.)?/, "") // Remove http, https, www
        .replace(/\/$/, ""); // Remove trailing slash
    };

    // Count occurrences of each normalized domain
    const domainCount = {};
    updatedRows.forEach((row) => {
      const normalizedDomain = normalizeUrl(row.domain);
      domainCount[normalizedDomain] = (domainCount[normalizedDomain] || 0) + 1;
    });

    // Update rows with isDuplicate flag
    const updatedRowsWithDuplicates = updatedRows.map((row) => {
      const normalizedDomain = normalizeUrl(row.domain);
      return {
        ...row,
        isDuplicate: domainCount[normalizedDomain] > 1,
      };
    });

    // Update state
    setRows(updatedRowsWithDuplicates);
    if (updatedRowsWithDuplicates.length < 1) {
      setIsDisabledField(false);
    }
  };

  const changePlatformSelectBox = (e) => {
    setPlatform(e.target.value); // Directly update with the selected value
  };

  if (!props.selectedBrand) {
    return <NotAccessablePage />;
  }

  return (
    <div className="row">
      <ClientSideNav domainUniqueSites={domainUniqueSites} />
      <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
        <TopNav />
        <div className="main-content-container container-fluid px-4">
          <div className="page-header row no-gutters py-4">
            <div className="col-12">
              <div className="row">
                <div className="col-5 text-sm-left mb-0">
                  <span className="text-uppercase page-subtitle">
                    CLIENT REQUEST
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card card-small mb-4">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <span className="text-muted d-block mb-2">Company</span>
                      <select
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        className="form-control"
                      >
                        <option value="">Select from dropdown</option>
                        {companies.map((company, index) => (
                          <option key={index} value={company.id}>
                            {company.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <span className="text-muted d-block mb-2">Brand</span>
                      <Select
                        options={brands.map((brand) => ({
                          value: brand.id,
                          label: brand.brandName,
                        }))}
                        onChange={(selectedOption) =>
                          setBrand(selectedOption?.value)
                        }
                        defaultValue={{
                          value: undefined,
                          label: "Select from Brand",
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <span className="text-muted d-block mb-2">Platform</span>
                      <select
                        value={platform} // Controlled Component
                        onChange={changePlatformSelectBox}
                        className="form-control"
                      >
                        <option value="">Select from dropdown</option>
                        {availablePlatforms?.domain && (
                          <option value="domain">Domain</option>
                        )}
                        {availablePlatforms?.website && (
                          <option value="website">Website</option>
                        )}
                        {availablePlatforms?.socialMedia && (
                          <option value="social-media">Social Media</option>
                        )}
                        {availablePlatforms?.eCommerce && (
                          <option value="e-commerce">E-commerce</option>
                        )}
                        {availablePlatforms?.appStore && (
                          <option value="app-store">App Store</option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7">
                    <div className="form-group">
                      <span className="text-muted d-block mb-2">
                        Domains / URLs
                      </span>
                      <textarea
                        className="form-control mb-3"
                        rows="5"
                        placeholder="Type multiple lines here..."
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        disabled={isDisabledField}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="form-group">
                      <span className="text-muted d-block mb-2">Remarks</span>
                      <textarea
                        className="form-control mb-3"
                        rows="5"
                        placeholder="Type Remarks here..."
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                        disabled={isDisabledField}
                      ></textarea>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <button
                        type="button"
                        onClick={() => uploadDomainAndURLS()}
                        className="btn btn-outline-danger"
                        disabled={isDisabledField}
                      >
                        Upload Domain's / URLs
                      </button>
                    </div>
                    <div className="col-md-4">
                      <button
                        type="button"
                        onClick={() => submitDomainAndURLS()}
                        className="btn btn-outline-success"
                        disabled={rows.length < 1}
                      >
                        Submit Domain's / URLs
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-small mb-4">
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h5 className="card-title">Requested Data</h5>
                  {isDisabledField && (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <button
                        type="button"
                        className="btn btn-primary add-single-record-btn"
                        onClick={addNewRecord}
                      >
                        Add a record
                      </button>

                      <button
                        type="button"
                        className="btn btn-primary add-single-record-btn"
                        onClick={clearRecords}
                      >
                        Clear all records
                      </button>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive fix-height">
                      <table className="table">
                        <thead style={{position:"sticky",top:0,background:"white",zIndex:1050}}>
                          <tr>
                            {/* <th>SN</th> */}
                            <th>Brand</th>
                            <th>Domain/Urls</th>
                            <th>Remarks</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows.map((row, index) => (
                            <tr key={row.id}>
                              <td style={{ minWidth: "200px", width: "20%" }}>
                                <Select
                                  className="w-full"
                                  options={brands.map((brand) => ({
                                    value: brand.id,
                                    label: brand.brandName,
                                  }))}
                                  value={
                                    brands.find((b) => b.id === row.brand)
                                      ? {
                                          value: row.brand,
                                          label: brands.find(
                                            (b) => b.id === row.brand
                                          )?.brandName,
                                        }
                                      : null
                                  }
                                  onChange={
                                    (selectedOption) =>
                                      handleInputChange(
                                        index,
                                        "brand",
                                        selectedOption?.value
                                      )
                                  }
                                  isDisabled={isDisabledField}
                                  placeholder="Select a Brand"
                                  menuPlacement="auto"
                                  menuPortalTarget={document.body} // Ensures dropdown renders properly
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={row.domain}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "domain",
                                      e.target.value
                                    )
                                  }
                                  className="form-control"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={row.remarks}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "remarks",
                                      e.target.value
                                    )
                                  }
                                  className="form-control"
                                />
                              </td>
                              <td
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                  minHeight: "40px",
                                }}
                              >
                                <span
                                  style={{
                                    visibility:
                                      row.isExists || row.isDuplicate
                                        ? "visible"
                                        : "hidden",
                                    color: "red",
                                    fontWeight: "bold",
                                    width:"36%",
                                    maxWidth:"130px"
                                  }}
                                >
                                  {row.isDuplicate
                                    ? "Duplicate Record"
                                    : "Record Exists"}
                                </span>

                                <button
                                  className="btn btn-danger"
                                  onClick={() => deleteRecord(index)}
                                >
                                  Delete
                                </button>

                                {!row.isDuplicate && row.isExists && (
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                      let additionalContent = "";

                                      if (platform === "domain") {
                                        additionalContent = `<p><strong>Domain Name:</strong> ${
                                          row.domainName || "N/A"
                                        }</p>`;
                                      } else if (platform === "website") {
                                        additionalContent = `<p><strong>URL:</strong> ${
                                          row.url || "N/A"
                                        }</p>`;
                                      } else if (platform === "social-media") {
                                        additionalContent = `<p><strong>Heading:</strong> ${
                                          row.heading || "N/A"
                                        }</p>
                                        <p><strong>Platform:</strong> ${
                                          row.platform || "N/A"
                                        }</p>`;
                                      } else if (platform === "e-commerce") {
                                        additionalContent = `<p><strong>Title:</strong> ${
                                          row.title || "N/A"
                                        }</p>
                                        <p><strong>Site:</strong> ${
                                          row.site || "N/A"
                                        }</p>`;
                                      } else if (platform === "app-store") {
                                        additionalContent = `<p><strong>App Name:</strong> ${
                                          row.appName || "N/A"
                                        }</p>
                                        <p><strong>App Store:</strong> ${
                                          row.appStore || "N/A"
                                        }</p>`;
                                      }
                                      Swal.fire({
                                        title: "Existing Record Details",
                                        html: `
                                        ${additionalContent}
            <p><strong>Reporting Date:</strong> ${
              row.reportingDate || "N/A"
            }</p>
            <p><strong>Priority:</strong> ${row.priority || "N/A"}</p>
            <p><strong>Status:</strong> ${row.status || "N/A"}</p>
          `,
                                        icon: "info",
                                        confirmButtonText: "OK",
                                      });
                                    }}
                                  >
                                    View record
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({
  // clientTrapPurchaseFilter: state.clientTrapPurchaseFilter,
  // clientDateFilter: state.clientDateFilter,
});

export default withAlert()(
  connect(mapStateToProps)(authorizeClient(requireAuth(ClientRequestDashboard)))
);
