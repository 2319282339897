import React from "react";
import Footer from "../../common/Footer.js";
import AdminSideNav from "../../common/AdminSideNav.js";
import AnalystSideNav from "../../common/AnalystSideNav.js";
import UnauthorisedPage from "../Unauthorised";
import TopNav from "../../common/TopNav.js";
import { connect } from "react-redux";
import Select from "react-select";
import requireAuth from "../../hoc/requireAuth";
import authorizeAnalystOrAdmin from "../../hoc/authorizeAnalystOrAdmin";
import DateRangePicker from "react-bootstrap-daterangepicker";
import * as moment from "moment";
import "sweetalert/dist/sweetalert.css";
import { withAlert } from "react-alert";
import "../../assets/css/data-upload.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import debounce from "lodash.debounce";
import { Table } from "react-bootstrap";
import {
  castToDefaultDateFormat,
  castToTimeFormat,
} from "../../settings/index";
import Loader from "react-loader-spinner";

class BulkDataDownload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authorised: true,
      platform: "",
      showDataSavedAlert: false,
      selectedOption: null,
      companies: [],
      brands: [],
      emailId: "",
      startDate: moment().format("DD/MM/YYYY"),
      endDate: moment().format("DD/MM/YYYY"),
      company: "",
      brand: [],
      dataSaveError: {},
      locale: { format: "DD/MM/YYYY" },
      queues: [],
      pagination: {
        page: 1,
        nextPage: 1,
        prevPage: null,
        hasNextPage: true,
        hasPrevPage: false,
        pagingCounter: 1,
        totalDocs: null,
        totalPages: null,
      },
      perPage: 10,
      isLoading: false,
      hasMoreDomainData: true,
      selectedKey: "",
      correctPassword: "Acc3s@s@f4",
    };
    this.listofRecords = debounce(this.listofRecords.bind(this), 500);
    window.onscroll = debounce(() => {
      const {
        listofRecords,
        state: { hasMoreDomainData },
      } = this;

      if (!hasMoreDomainData) {
        return;
      }

      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        listofRecords();
      }
    }, 100);
  }

  componentDidUpdate(prevProps, prevState) {
    //this.state.listofRecords();
    if (prevState.company !== this.state.company) {
      this.getBrands();
      this.getEmailId();
      this.setState({ brand: [], brandId: [] });
    }

    // if (
    //     this.state.brand &&
    //     this.state.brand !== prevState.brand // Check if the brand has changed
    // ) {
    //     let selectedBrand = this.state.brands.filter(
    //         (brand) => brand.id === this.state.brand
    //     );

    //     if (selectedBrand.length) {
    //         let platform = selectedBrand[0].platform;

    //         this.setState(() => ({
    //             availablePlatforms: platform,
    //         }));
    //     } else {
    //         this.setState(() => ({
    //             availablePlatforms: null,
    //         }));
    //     }
    // }
  }

  componentDidMount() {
    this.getCompanies();
    this.listofRecords();
  }

  async getCompanies() {
    await window.axios
      .get("/company", {
        params: {
          perPage: 100,
        },
      })
      .then((res) => {
        this.setState({
          companies: res.data.docs,
        });
      });
  }

  async getBrands() {
    await window.axios
      .get("/brand", {
        params: {
          disabledBrands: true,
          company: this.state.company,
          page: 1,
          perPage: 800,
        },
      })
      .then((res) => {
        res.data.docs.push({ brandName: "All" });
        this.setState({
          brands: res.data.docs,
        });
      });
  }

  async getEmailId() {
    await window.axios
      .get("/users/getEmailId", {
        params: {
          page: 1,
          perPage: 400,
        },
      })
      .then((res) => {
        this.setState({
          emailId: res.data.data,
        });
      });
  }

  async postQueueConfirmation() {
    // Step 1: Prompt for password
    const { value: password } = await Swal.fire({
      title: "Enter your password",
      input: "password",
      inputLabel: "Password",
      inputPlaceholder: "Enter your password",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: (password) => {
        if (password !== this.state.correctPassword) {
          Swal.showValidationMessage("Incorrect password");
          return false;
        }
        return password;
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });

    // Step 2: Check if the password is correct
    if (password === this.state.correctPassword) {
      // Step 3: Proceed with the original logic if the password is correct
      if (
        this.state.company === "" ||
        this.state.brand === "" ||
        this.state.platform === ""
      ) {
        return;
      }
      let brandParams = new URLSearchParams();
      this.state.brand.forEach((b) => brandParams.append("brand", b.value));

      const username = this.props.login.user.username;
      const options = {
        headers: { "Content-Type": "application/json" },
      };

      let brandName = "";
      let noOfDocument = 0;

      try {
        const res = await window.axios.get(
          `/queue/confirmFetchRecords?${brandParams.toString()}`,
          {
            params: {
              company: this.state.company,
              startDate: this.state.startDate,
              endDate: this.state.endDate,
              platform: this.state.platform,
              email: this.state.emailId,
              username,
            },
          },
          options
        );

        const { result, companyName } = res.data;

        if (!result || result.length === 0) {
          Swal.fire("No records found!", "", "info");
          return;
        }

        // Format records into a readable message
        let recordsMessage = result
          .map(
            ({ brandName, noOfDocument }) =>
              `<b>${brandName}</b>: ${noOfDocument} records`
          )
          .join("<br>");

        const totalRecords = result.reduce(
          (sum, { noOfDocument }) => sum + noOfDocument,
          0
        );
        brandName = result.map(({ brandName }) => brandName).join(", ");
        noOfDocument = totalRecords || 0;
        let brandIds = this.state.brand.map((b) => b.value);

        const resultPopup = await Swal.fire({
          title: `Company: ${companyName}`,
          html: `<p>${recordsMessage}</p><p><b>Total Records:</b> ${totalRecords}</p>`,
          showDenyButton: true,
          showCancelButton: false,
          showConfirmButton: totalRecords > 0,
          confirmButtonText: "Accept",
          denyButtonText: "Cancel",
        });

        if (resultPopup.isConfirmed) {
          await window.axios.post("/queue/postQueueRecord", {
            company: companyName,
            brand: brandName,
            dateRange: `${this.state.startDate} - ${this.state.endDate}`,
            platform: this.state.platform,
            brandId: brandIds,
            companyId: this.state.company,
            email: this.state.emailId,
            username,
            numOfRecords: noOfDocument,
            // brandName: brandName,
          });

          this.listofRecords(true);
          Swal.fire("Saved!", "", "success");
        }
      } catch (error) {
        alert(error.response?.data?.message || "An error occurred");
      }
    }
  }

  async listofRecords(reArrangeRecords = false) {
    let reqData = {
      page: this.state.pagination.nextPage,
      perPage: this.state.perPage,
    };

    if (reArrangeRecords) {
      reqData.page = 1;
    }

    this.setState(() => ({ isLoading: true }));

    await window.axios
      .get("/queue/getRecords", {
        params: reqData,
      })
      .then((res) => {
        let queueData = [...this.state.queues].concat(res.data.docs);

        if (reArrangeRecords) {
          queueData = res.data.docs;
        }

        this.setState(() => ({
          queues: queueData,
          isLoading: false,
          pagination: {
            page: res.data.page,
            nextPage: res.data.nextPage,
            prevPage: res.data.prevPage,
            hasNextPage: res.data.hasNextPage,
            hasPrevPage: res.data.hasPrevPage,
            pagingCounter: res.data.pagingCounter,
            totalDocs: res.data.totalDocs,
            totalPages: res.data.totalPages,
          },
          hasMoreDomainData: res.data.hasNextPage,
        }));
      });
  }

  renderLoader() {
    if (this.state.isLoading) {
      return (
        <div className="text-center">
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={10}
            width={200}
            timeout={5000} //3 secs
          />
        </div>
      );
    }
  }

  getDateFilters() {
    return {
      startDate: moment(),
      endDate: moment(),
      locale: this.state.locale,
    };
  }

  handleCallback = (date) => {
    const dateArray = date.delegateTarget.value.split(" - ");
    this.setState({ startDate: dateArray[0], endDate: dateArray[1] });
  };

  handleChange = (selectedOptions) => {
    if (!selectedOptions || selectedOptions.length === 0) {
      this.setState({ brand: [], brandId: [] });
      return;
    }
  
    // Check if "ALL" is selected
    const allSelected = selectedOptions.some(
      (option) => option.label.toUpperCase() === "ALL"
    );
  
    if (allSelected) {
      this.setState({
        brand: [{ label: "ALL", value: "ALL" }], // Store "ALL" as an object
        brandId: [],
      });
    } else {
      const selectedBrands = selectedOptions.map((option) => option);
      const selectedBrandIds = selectedOptions
        .map((option) => {
          const brand = this.state.brands.find((b) => b.id === option.value);
          return brand ? brand.id : null;
        })
        .filter((id) => id !== null); // Remove null values
  
      this.setState({
        brand: selectedBrands,
        brandId: selectedBrandIds,
      });
    }
  };
  

  changePlatformSelectBox(e) {
    let selectedKeyValue = "domainKeys";

    let key = e.target.value;

    if (key === "website") {
      selectedKeyValue = "websiteKeys";
    }
    if (key === "social-media") {
      selectedKeyValue = "socialMediaKeys";
    }
    if (key === "e-commerce") {
      selectedKeyValue = "ecommerceKeys";
    }
    if (key === "app-store") {
      selectedKeyValue = "appStoreKeys";
    }

    this.setState({
      platform: e.target.value,
      selectedKey: selectedKeyValue,
    });
  }

  renderHtml() {
    if (!this.state.authorised) {
      return <UnauthorisedPage />;
    }

    const options = this.state.brands.map((brand, brandKey) => ({
      value: brand.id,
      label: brand.brandName,
    }));

    return (
      <div className="row">
        {this.props?.login?.user?.role === "Admin" ? (
          <AdminSideNav />
        ) : (
          <AnalystSideNav />
        )}

        <main
          id="bulk-data-download-screen"
          className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3"
        >
          <TopNav />

          <div className="main-content-container container-fluid px-4">
            <div className="page-header row no-gutters py-4">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-sm-4 text-sm-left mb-0">
                    <span className="text-uppercase page-subtitle">
                      Bulk Data Download
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card card-small mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Company
                            </span>
                            <select
                              onChange={(e) => {
                                this.setState(
                                  {
                                    company: e.target.value,
                                    brand: [], // Reset brand
                                    brandId: [],
                                  },
                                );
                              }}
                              className="form-control"
                            >
                              <option value="">Select from dropdown</option>
                              {this.state.companies.map(
                                (company, companyIndex) => (
                                  <option key={companyIndex} value={company.id}>
                                    {company.name}
                                  </option>
                                )
                              )}
                            </select>
                            {this.state.dataSaveError?.company && (
                              <span className="text-danger">
                                {this.state.dataSaveError?.company}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Brand
                            </span>
                            <Select
                              closeMenuOnSelect={false}
                              options={options}
                              isMulti
                              value={
                                this.state.brand.length > 0
                                  ? this.state.brand
                                  : null
                              } // Use null instead of []
                              onChange={this.handleChange}
                            />

                            {this.state.dataSaveError?.brand && (
                              <span className="text-danger">
                                {this.state.dataSaveError?.brand}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Platform
                            </span>
                            <select
                              value={this.state.platform}
                              onChange={(e) => this.changePlatformSelectBox(e)}
                              className="form-control"
                            >
                              <option value="">Select from dropdown</option>
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.domain) ||
                                !this.state.availablePlatforms) && (
                                <option value="domain">Domain</option>
                              )}
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.website) ||
                                !this.state.availablePlatforms) && (
                                <option value="website">Website</option>
                              )}
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.socialMedia) ||
                                !this.state.availablePlatforms) && (
                                <option value="social-media">
                                  Social Media
                                </option>
                              )}
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.eCommerce) ||
                                !this.state.availablePlatforms) && (
                                <option value="e-commerce">E-commerce</option>
                              )}
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.appStore) ||
                                !this.state.availablePlatforms) && (
                                <option value="app-store">App Store</option>
                              )}
                            </select>
                            {this.state.dataSaveError?.platform && (
                              <span className="text-danger">
                                {this.state.dataSaveError?.platform}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Date Range
                            </span>
                            <DateRangePicker
                              onApply={this.handleCallback}
                              initialSettings={this.getDateFilters()}
                              alwaysShowCalendars={true}
                            >
                              <input className="from-control" />
                            </DateRangePicker>
                          </div>
                          {this.state.dataSaveError?.reportingDate && (
                            <span className="text-danger">
                              {this.state.dataSaveError?.reportingDate}
                            </span>
                          )}
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Email Id
                            </span>
                            <Select
                              value={{
                                value: this.state.emailId,
                                label: this.state.emailId,
                              }}
                              isDisabled={true}
                            />
                            {this.state.dataSaveError?.emailId && (
                              <span className="text-danger">
                                {this.state.dataSaveError?.brand}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <button
                              onClick={(e) => this.postQueueConfirmation()}
                              className="mt-4 btn btn-ldotr"
                            >
                              Fetch Data
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="card card-small mb-4">
                  <div className="card-body p-0">
                    <Table
                      hover
                      width="100%"
                      id="queue-table"
                      className="table mb-0"
                    >
                      <thead className="bg-light uppercase-th">
                        <tr>
                          <th width="3%" scope="col" className="border-0">
                            Sr.
                          </th>
                          <th width="10%" scope="col" className="border-0">
                            Date & Time added
                          </th>
                          <th width="8%" scope="col" className="border-0">
                            Username
                          </th>
                          <th width="10%" scope="col" className="border-0">
                            Company
                          </th>
                          <th width="10%" scope="col" className="border-0">
                            Brand
                          </th>
                          <th width="10%" scope="col" className="border-0">
                            Platform
                          </th>
                          <th width="14%" scope="col" className="border-0">
                            DateRange
                          </th>
                          <th width="6%" scope="col" className="border-0">
                            No of Records
                          </th>
                          <th width="8%" scope="col" className="border-0">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state?.queues.map((queue, key) => (
                          <>
                            <tr
                              key={key}
                              data-toggle="collapse"
                              data-target={".multi-collapse" + key}
                              aria-controls={"multiCollapseExample" + key}
                            >
                              <td>
                                <p className="mx-0 my-0">{key + 1}</p>
                              </td>
                              <td>
                                <p className="mx-0 my-0">
                                  {queue?.DateCreated
                                    ? `${castToDefaultDateFormat(
                                        queue?.DateCreated
                                      )} ${castToTimeFormat(
                                        queue?.DateCreated
                                      )}`
                                    : ""}
                                </p>
                              </td>
                              <td>
                                <p
                                  className="mx-0 my-0 truncate"
                                  title={queue.username}
                                >
                                  {queue.username}
                                </p>
                              </td>
                              <td>
                                <p
                                  className="mx-0 my-0 truncate"
                                  title={queue.company}
                                >
                                  {queue.company}
                                </p>
                              </td>
                              <td>
                                <p
                                  className="mx-0 my-0 truncate"
                                  title={queue.brand}
                                >
                                  {queue.brand}
                                </p>
                              </td>
                              <td>
                                <p
                                  className="mx-0 my-0 truncate"
                                  title={queue.platform}
                                >
                                  {queue.platform === "domain"
                                    ? "Domain"
                                    : queue.platform === "website"
                                    ? "Website"
                                    : queue.platform === "social-media"
                                    ? "Social Media"
                                    : queue.platform === "e-commerce"
                                    ? "E-commerce"
                                    : queue.platform === "app-store"
                                    ? "App Store"
                                    : ""}
                                </p>
                              </td>
                              <td>
                                <p className="mx-0 my-0 truncate">
                                  {queue?.dateRange ? queue?.dateRange : ""}
                                </p>
                              </td>
                              <td>
                                <p
                                  className="mx-0 my-0 truncate"
                                  title={queue.numOfRecords}
                                >
                                  {queue.numOfRecords}
                                </p>
                              </td>
                              <td>
                                <p
                                  className="mx-0 my-0 truncate"
                                  title={queue.status}
                                >
                                  {queue.status}
                                </p>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
                {this.state.hasMoreDomainData && (
                  <div className="text-center">
                    <a
                      href="#"
                      className="text-danger"
                      onClick={(e) => this.listofRecords()}
                    >
                      {" "}
                      {this.renderLoader()} Load more...
                    </a>
                  </div>
                )}
                {!this.state.hasMoreDomainData && (
                  <div className="text-center">
                    <label className="text-muted">No more data to show.</label>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* data saved sweet alert */}
          <Footer />
        </main>
      </div>
    );
  }

  render() {
    return <>{this.renderHtml()}</>;
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  login: state.login,
  response: state.response,
});

export default withAlert()(
  connect(mapStateToProps)(
    authorizeAnalystOrAdmin(requireAuth(BulkDataDownload))
  )
);
