import React, { Component } from "react";
import Footer from "../../common/Footer.js";
import AdminSideNav from "../../common/AdminSideNav.js";
import TopNav from "../../common/TopNav.js";
import {
  Button,
  Modal,
  Form,
  Dropdown,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import Select from "react-select";
import { connect } from "react-redux";
import requireAuth from "../../hoc/requireAuth";
import authorizeAdmin from "../../hoc/authorizeAdmin";
import "sweetalert2/src/sweetalert2.scss";
import { Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap CSS
import "bootstrap/js/src/collapse.js"; // To make rows collapsible
import Loader from "react-loader-spinner";
import {
  castToDefaultDateFormat,
  castToTimeFormat,
} from "../../settings/index";
import { withAlert } from "react-alert";
import "./../../itemsAlignment.css";
import PaginationComponentV1 from "../../common/PaginationComponentV1.js";

class UserComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      companies: [],
      showModal: false,
      selectedRole: {
        roleName: "",
        role: null,
      },
      roles: [
        {
          roleName: "Client",
          role: "Client",
        },
        {
          roleName: "Admin",
          role: "Admin",
        },
        {
          roleName: "Analyst",
          role: "Analyst",
        },
      ],
      userIndex: null,
      userId: null,
      confirmPassword: "",
      password: "",
      twoFactorAuth: false,
      isBlocked: 0,
      isGoogleAuth: 0,
      mobile: "",
      email: "",
      username: "",
      firstName: "",
      lastName: "",
      employeeCode: "",
      selectedCompany: null,
      options: [
        { value: "chocolate", label: "Chocolate" },
        { value: "strawberry", label: "Strawberry" },
        { value: "vanilla", label: "Vanilla" },
      ],
      pagination: {
        totalDocs: null,
        totalPages: null,
        page: 1,
        pagingCounter: 1,
        hasPrevPage: false,
        hasNextPage: true,
        prevPage: null,
        nextPage: 1,
      },
      perPage: 20,
      sortBy: "_id", // column to sort by
      sortDirection: -1, // sort direction (asc or desc)
      isLoading: false,
      hasMoreDomainData: true,
      search: "",
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.selectRole = this.selectRole.bind(this);
    this.changeInputBox = this.changeInputBox.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.createUser = this.createUser.bind(this);
    this.listAllUsers = this.listAllUsers.bind(this);
    this.handleLimit = this.handleLimit.bind(this);
    this.sortTable = this.sortTable.bind(this);
    this.inputSearchBox = this.inputSearchBox.bind(this);
    this.handleSearchButton = this.handleSearchButton.bind(this);
    this.myPageChange = this.myPageChange.bind(this);
  }

  myPageChange(newPage) {
    this.setState({
      pagination: {
        ...this.state.pagination,
        page: newPage,
      },
    });
  }

  handleSearchButton() {
    this.listAllUsers(true);
  }

  inputSearchBox(search) {
    this.setState({ search });
  }

  sortTable = (column) => {
    let { sortBy, sortDirection } = this.state;

    if (sortBy === column) {
      sortDirection = sortDirection === 1 ? -1 : 1;
    } else {
      sortBy = column;
      sortDirection = 1;
    }

    // Update state
    this.setState({ sortBy, sortDirection });
  };

  handleLimit(e) {
    this.setState({ perPage: e });
    this.setState((prevState) => ({
      pagination: {
        ...prevState.pagination,
        page: 1,
      },
    }));
  }

  renderLoader() {
    if (this.state.isLoading) {
      return (
        <div className="text-center">
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={10}
            width={200}
            timeout={5000} //3 secs
          />
        </div>
      );
    }
  }

  prepareUserParams() {
    return {
      passwordHash: this.state.password,
      mobile: this.state.mobile,
      email: this.state.email,
      username: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      role: this.state.selectedRole.role,
      company: this.state.selectedCompany?.value,
      twoFactorAuth: this.state.twoFactorAuth,
      employeeCode: this.state.employeeCode,
      isBlocked: this.state.isBlocked,
      isGoogleAuth: this.state.isGoogleAuth,
    };
  }

  async createUser(update) {
    let params = this.prepareUserParams();

    await this.setState(() => {
      return {
        errorMessage: null,
      };
    });

    let url = "/users/create";

    if (this.state.userId) {
      url = `/users/update/${this.state.userId}`;
    }

    if (this.state.isGoogleAuth == 1) {
      params.twoFactorAuth = false;
    }

    await window.axios
      .post(url, params)
      .then((response) => {
        this.handleCloseModal();
        this.listAllUsers(update);
        this.props.alert.show("Data Saved Successfully");
      })
      .catch((error) => {
        let statusCode = error.response.status;
        let errors = error.response.data.errors;
        let errorMessage = error.response.data.message;

        if (statusCode === 401 || statusCode === 400 || statusCode === 500) {
          this.props.alert.show(errorMessage, { type: "error" });
        }

        if (statusCode === 422) {
          let errorMessageObj = {};

          for (let errorData of errors) {
            let errorKeyName = Object.keys(errorData)[0];
            errorMessageObj[errorKeyName] = errorData[errorKeyName];
          }

          this.setState(() => {
            return { errorMessage: errorMessageObj };
          });

          this.props.alert.show(errorMessage || "Something wrong wrong!", {
            type: "error",
          });
        }
      });
  }

  async resetForm() {
    await this.setState(() => {
      return {
        userIndex: null,
        userId: null,
        confirmPassword: "",
        password: "",
        mobile: "",
        email: "",
        username: "",
        firstName: "",
        lastName: "",
        selectedRole: {
          roleName: "",
          role: null,
        },
        employeeCode: "",
        isBlocked: 0,
        selectedCompany: null,
        errorMessage: null,
        isGoogleAuth: 0,
      };
    });
  }

  handleOpenModal() {
    this.setState((prevState) => {
      return {
        showModal: true,
      };
    });
  }

  handleCloseModal() {
    this.setState((prevState) => {
      return {
        showModal: false,
      };
    });
    this.resetForm();
  }

  async selectRole(e, role) {
    await this.setState(() => {
      return {
        selectedRole: role,
      };
    });

    console.log(this.state.selectedRole);
  }

  async changeInputBox(e, column) {
    let state = {};
    if (column === "twoFactorAuth") {
      state[column] = e.target.checked;
    } else if (column === "isBlocked") {
      state[column] = e.target.checked ? 1 : 0;
    } else if (column === "isGoogleAuth") {
      state[column] = e.target.checked ? 1 : 0;
    } else {
      state[column] = e.target.value;
    }
    await this.setState(() => {
      return state;
    });
  }

  componentDidMount() {
    this.listAllUsers();
    this.listAllCompanies();
  }

  componentDidUpdate(prevProps, prevState) {
    // This runs when the component updates
    // You can check if specific state or props changed and perform side effects accordingly
    if (
      this.state.pagination.page !== prevState.pagination.page ||
      this.state.perPage !== prevState.perPage ||
      this.state.sortBy !== prevState.sortBy ||
      this.state.sortDirection !== prevState.sortDirection
    ) {
      this.listAllUsers();
    }
  }

  async listAllUsers(reArrangeRecords = false) {
    let reqData = {
      page: this.state.pagination.page,
      perPage: this.state.perPage,
    };

    reqData.sortBy = this.state.sortBy;
    reqData.sortDirection = this.state.sortDirection;
    if (reArrangeRecords) {
      reqData.page = 1;
    }
    reqData.search = this.state.search;

    this.setState(() => ({ isLoading: true }));

    await window.axios
      .get("/users", {
        params: reqData,
      })
      .then((res) => {
        this.setState(() => ({
          users: res.data.docs,
          isLoading: false,
          pagination: {
            page: res.data.page,
            nextPage: res.data.nextPage,
            prevPage: res.data.prevPage,
            hasNextPage: res.data.hasNextPage,
            hasPrevPage: res.data.hasPrevPage,
            pagingCounter: res.data.pagingCounter,
            totalDocs: res.data.totalDocs,
            totalPages: res.data.totalPages,
          },
          hasMoreDomainData: res.data.hasNextPage,
        }));
      });
  }

  async listAllCompanies() {
    await window.axios
      .get("/company", {
        params: {
          perPage: 100,
        },
      })
      .then((res) => {
        let companies = [];
        res?.data?.docs.map((company) =>
          companies.push({
            label: company.name,
            value: company._id,
          })
        );
        this.setState({ companies: companies });
      });
  }

  async handleUsernameFilterChange(e) {
    await this.setState(() => ({
      filteredUsername: e.target.value,
    }));
  }

  async handleEmailFilterChange(e) {
    await this.setState(() => ({
      filteredEmail: e.target.value,
    }));
  }

  showCompanySelectBox() {
    if (this.state.selectedRole?.role === "Client") {
      return (
        <div className="col-6">
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Company</Form.Label>
            <Select
              name="company"
              value={this.state.selectedCompany}
              onChange={(e) => this.changeCompany(e)}
              options={this.state.companies}
            />
            <span className="form-text-error-text">
              {this.state.errorMessage?.company}
            </span>
          </Form.Group>
        </div>
      );
    }
    return null; // Avoid rendering anything if the role is not Client
  }

  showEmployeeCode() {
    if (
      this.state.selectedRole?.role === "Admin" ||
      this.state.selectedRole?.role === "Analyst"
    ) {
      return (
        <div className="col-6">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Employee Code</Form.Label>
            <Form.Control
              name="employeeCode"
              onChange={(e) => this.changeInputBox(e, "employeeCode")}
              value={this.state.employeeCode}
              type="text"
              placeholder="Enter Employee Code"
            />
            <span className="form-text-error-text">
              {this.state.errorMessage?.employeeCode}
            </span>
          </Form.Group>
        </div>
      );
    }
    return null; // Avoid rendering if role isn't Admin/Analyst
  }

  async changeCompany(e) {
    this.setState(() => {
      return {
        selectedCompany: e,
      };
    });
  }

  showUser(user, index) {
    this.setState(() => ({
      userId: user.id,
      userIndex: index,
      mobile: user.mobile,
      email: user.email,
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      selectedRole: {
        roleName: user.role,
        role: user.role,
      },
      selectedCompany: this.state.companies.find(
        (company) => company.value === user?.company?.id
      ),
      twoFactorAuth: user.twoFactorAuth || false,
      employeeCode: user.employeeCode,
      isBlocked: (user.isBlocked && user.isBlocked) || 0,
      isGoogleAuth: (user.isGoogleAuth && user.isGoogleAuth) || 0,
    }));

    this.handleOpenModal();
  }

  async removeUser(user) {
    await window.axios
      .delete(`/users/${user?.id}`)
      .then((response) => {
        this.listAllUsers(true);
      })
      .catch((error) => {
        let errorMessage = error.response.data.message;
        this.props.alert.show(errorMessage || "Something went wrong!", {
          type: "error",
        });
      });
  }

  renderHtml() {
    return (
      <div className="row">
        <AdminSideNav />

        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
          <TopNav />

          <div
            className="d-flex flex-column px-4 position-static gap-3"
            style={{
              height: "calc(100vh - 114px)",
              overflowY: "hidden",
              position: "relative",
            }}
          >
            <div className="d-flex align-items-center justify-content-between mt-3">
              <div className="d-flex gap-4 align-items-center">
                <InputGroup className="">
                  <FormControl
                    placeholder=""
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                    value={this.state.search}
                    onChange={(e) => {
                      this.inputSearchBox(e.target.value);
                    }}
                    style={{ width: "200px" }}
                  />
                </InputGroup>
                <Button
                  className="btn-success"
                  onClick={this.handleSearchButton}
                >
                  Search
                </Button>
                <Button onClick={this.handleOpenModal}>Add User</Button>
              </div>

              <div className="mb-2" style={{ height: "28px" }}>
                <PaginationComponentV1
                  totalRecords={this.state.pagination.totalDocs}
                  itemsPerPage={this.state.perPage}
                  currentPage={this.state.pagination.page}
                  onPageChange={this.myPageChange}
                  displayPagesCount={8}
                />
              </div>
              <div className="d-flex align-items-center">
                <Dropdown style={{ width: "80px" }} onSelect={this.handleLimit}>
                  <Dropdown.Toggle variant="danger" id="limit">
                    {this.state.perPage}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      eventKey="10"
                      active={this.state.perPage == 10}
                    >
                      10
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="20"
                      active={this.state.perPage == 20}
                    >
                      20
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="50"
                      active={this.state.perPage == 50}
                    >
                      50
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="100"
                      active={this.state.perPage == 100}
                    >
                      100
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <span>{this.state.pagination.totalDocs} records</span>
              </div>
            </div>

            {/* list user code here */}
            <div className="row">
              <div className="col">
                <div className="card card-small mb-4">
                  <div className="card-body p-0">
                    {/* Add scrollable container with fixed height */}
                    <div
                      style={{
                        height: "calc(100vh - 200px)",
                        overflowY: "auto",
                        position: "relative",
                      }}
                    >
                      <Table hover className="mb-0" id="user-table">
                        <thead
                          style={{
                            position: "sticky",
                            top: "0%",
                            zIndex: 2,
                            backgroundColor: "white",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                          }}
                        >
                          <tr key="row_header">
                            <th
                              width="3%"
                              scope="col"
                              className="border-0"
                              onClick={() => this.sortTable("_id")}
                            >
                              Sr.
                              {this.state.sortBy === "_id"
                                ? this.state.sortDirection === 1
                                  ? "↑"
                                  : "↓"
                                : ""}
                            </th>
                            <th
                              width="10%"
                              scope="col"
                              className="border-0"
                              onClick={() => this.sortTable("username")}
                            >
                              Username
                              {this.state.sortBy === "username"
                                ? this.state.sortDirection === 1
                                  ? "↑"
                                  : "↓"
                                : ""}
                            </th>
                            <th
                              width="10%"
                              scope="col"
                              className="border-0"
                              onClick={() => this.sortTable("firstName")}
                            >
                              Full Name
                              {this.state.sortBy === "firstName"
                                ? this.state.sortDirection === 1
                                  ? "↑"
                                  : "↓"
                                : ""}
                            </th>
                            <th
                              width="10%"
                              scope="col"
                              className="border-0"
                              onClick={() => this.sortTable("email")}
                            >
                              Email
                              {this.state.sortBy === "email"
                                ? this.state.sortDirection === 1
                                  ? "↑"
                                  : "↓"
                                : ""}
                            </th>
                            <th width="10%" scope="col" className="border-0">
                              Company
                            </th>
                            <th
                              width="10%"
                              scope="col"
                              className="border-0"
                              onClick={() => this.sortTable("lastLoggedInAt")}
                            >
                              Last Login
                              {this.state.sortBy === "lastLoggedInAt"
                                ? this.state.sortDirection === 1
                                  ? "↑"
                                  : "↓"
                                : ""}
                            </th>
                            <th
                              width="10%"
                              scope="col"
                              className="border-0"
                              onClick={() => this.sortTable("twoFactorAuth")}
                            >
                              2Fa
                              {this.state.sortBy === "twoFactorAuth"
                                ? this.state.sortDirection === 1
                                  ? "↑"
                                  : "↓"
                                : ""}
                            </th>
                            <th
                              width="4%"
                              scope="col"
                              className="border-0"
                              onClick={() => this.sortTable("isBlocked")}
                            >
                              Blocked
                              {this.state.sortBy === "isBlocked"
                                ? this.state.sortDirection === 1
                                  ? "↑"
                                  : "↓"
                                : ""}
                            </th>
                            <th
                              width="10%"
                              scope="col"
                              className="border-0"
                              onClick={() => this.sortTable("employeeCode")}
                            >
                              Employee Code
                              {this.state.sortBy === "employeeCode"
                                ? this.state.sortDirection === 1
                                  ? "↑"
                                  : "↓"
                                : ""}
                            </th>
                            <th width="10%" scope="col" className="border-0">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody style={{ position: "relative", zIndex: 1 }}>
                          {this.state?.users.map((user, key) => (
                            <>
                              <tr
                                key={key}
                                data-toggle="collapse"
                                data-target={".multi-collapse" + key}
                                aria-controls={"multiCollapseExample" + key}
                              >
                                <td>
                                  <p className="mx-0 my-0">
                                    {this.state.pagination.pagingCounter + key}
                                  </p>
                                </td>
                                <td>
                                  <p className="mx-0 my-0">{user.username}</p>
                                </td>
                                <td>
                                  <p
                                    className="mx-0 my-0 truncate"
                                    title={user.lastName}
                                  >{`${user.firstName} ${user.lastName}`}</p>
                                </td>
                                <td>
                                  <p
                                    className="mx-0 my-0 truncate"
                                    title={user.email}
                                  >
                                    {user.email}
                                  </p>
                                </td>
                                <td>
                                  <p
                                    className="mx-0 my-0 truncate"
                                    title={user.mobile}
                                  >
                                    {user?.company?.name}
                                  </p>
                                </td>
                                <td>
                                  <p
                                    className="mx-0 my-0 truncate"
                                    title={user.mobile}
                                  >
                                    {user?.lastLoggedInAt
                                      ? new Date(
                                          user?.lastLoggedInAt
                                        ).toLocaleString("en-IN", {
                                          day: "2-digit",
                                          month: "2-digit",
                                          year: "numeric",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hour12: true,
                                        })
                                      : ""}
                                  </p>
                                </td>
                                <td>
                                  <p className="mx-0 my-0 truncate">
                                    {user.twoFactorAuth
                                      ? "Enabled"
                                      : "Disabled"}
                                  </p>
                                </td>
                                <td>
                                  <p className="mx-0 my-0 truncate">
                                    {user.isBlocked ? "Yes" : "No"}
                                  </p>
                                </td>
                                <td>
                                  <p className="mx-0 my-0 truncate">
                                    {user.employeeCode ? user.employeeCode : ""}
                                  </p>
                                </td>
                                <td>
                                  <a
                                    className="text-danger"
                                    onClick={(e) => this.removeUser(user)}
                                    href="#"
                                  >
                                    <i className="fa fa-trash"></i>
                                  </a>
                                  <a
                                    className="text-primary mx-1"
                                    onClick={(e) => this.showUser(user, key)}
                                    href="#"
                                  >
                                    <i className="fa fa-edit"></i>
                                  </a>
                                </td>
                              </tr>
                              <tr
                                className={"collapse multi-collapse" + key}
                                id={"multiCollapseExample" + key}
                              >
                                <td colSpan="6">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <span className="text-muted d-block mb-2">
                                              Mobile
                                            </span>
                                            <input
                                              type="text"
                                              value={user?.mobile}
                                              disabled
                                              className="form-control"
                                              aria-label="Username"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <span className="text-muted d-block mb-2">
                                              Role
                                            </span>
                                            <input
                                              type="text"
                                              value={user?.role}
                                              disabled
                                              className="form-control"
                                              placeholder="Username"
                                              aria-label="Username"
                                              aria-describedby="basic-addon1"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* list user ends here */}
          </div>
          <Footer />
        </main>
      </div>
    );
  }

  renderSaveOrUpdateButton() {
    if (this.state.userId) {
      return (
        <Button onClick={() => this.createUser(false)} variant="primary">
          Update User
        </Button>
      );
    } else {
      return (
        <Button onClick={() => this.createUser(true)} variant="primary">
          Save User
        </Button>
      );
    }
  }

  render() {
    return (
      <>
        {this.renderHtml()}
        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={this.handleCloseModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>User Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="row">
                <div className="col-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      name="firstName"
                      onChange={(e) => this.changeInputBox(e, "firstName")}
                      value={this.state.firstName}
                      type="text"
                      placeholder="Enter First Name"
                    />
                    <span className="form-text-error-text">
                      {this.state.errorMessage?.firstName}
                    </span>
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      name="lastName"
                      onChange={(e) => this.changeInputBox(e, "lastName")}
                      value={this.state.lastName}
                      type="text"
                      placeholder="Enter First Name"
                    />
                    <span className="form-text-error-text">
                      {this.state.errorMessage?.lastName}
                    </span>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      name="email"
                      onChange={(e) => this.changeInputBox(e, "email")}
                      value={this.state.email}
                      type="text"
                      placeholder="Enter Email"
                    />
                    <span className="form-text-error-text">
                      {this.state.errorMessage?.email}
                    </span>
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                      name="mobile"
                      onChange={(e) => this.changeInputBox(e, "mobile")}
                      value={this.state.mobile}
                      type="text"
                      placeholder="Enter Mobile"
                    />
                    <span className="form-text-error-text">
                      {this.state.errorMessage?.mobile}
                    </span>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                {!this.state.isGoogleAuth && (
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        name="username"
                        onChange={(e) => this.changeInputBox(e, "username")}
                        value={this.state.email}
                        type="text"
                        placeholder="Enter username"
                        disabled={true}
                      />
                      <span className="form-text-error-text">
                        {this.state.errorMessage?.username}
                      </span>
                    </Form.Group>
                  </div>
                )}
                <div className="col-6">
                  <div className="row">
                    <div className="col-4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Role</Form.Label>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            {!this.state.selectedRole?.roleName.length
                              ? "Select Role"
                              : this.state.selectedRole?.roleName}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {this.state.roles.map((role, roleIndex) => (
                              <Dropdown.Item
                                key={roleIndex}
                                onClick={(e) => this.selectRole(e, role)}
                              >
                                {role.roleName}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                        <span className="form-text-error-text">
                          {this.state.errorMessage?.role}
                        </span>
                      </Form.Group>
                    </div>
                    {!this.state.isGoogleAuth && (
                      <div className="col-8">
                        <Form.Group
                          className="mb-3 ml-2"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Two Factor Authentication</Form.Label>
                          <Form.Check
                            type="checkbox"
                            id="2fa"
                            label=""
                            onChange={(e) =>
                              this.changeInputBox(e, "twoFactorAuth")
                            }
                            checked={this.state.twoFactorAuth}
                          />
                        </Form.Group>
                      </div>
                    )}
                    {this.state.isGoogleAuth ? this.showEmployeeCode() : null}
                    {this.state.isGoogleAuth
                      ? this.showCompanySelectBox()
                      : null}
                  </div>
                </div>
              </div>
              <div className="row">
                {!this.state.isGoogleAuth && (
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        name="password"
                        onChange={(e) => this.changeInputBox(e, "password")}
                        value={this.state.password}
                        type="password"
                        placeholder="Password"
                      />
                      <span className="form-text-error-text">
                        {this.state.errorMessage?.passwordHash}
                      </span>
                    </Form.Group>
                  </div>
                )}
                {!this.state.isGoogleAuth && this.showEmployeeCode()}
                {!this.state.isGoogleAuth && this.showCompanySelectBox()}
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="row">
                    <div className="col-2">
                      <Form.Group
                        className="mb-3 ml-2"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Blocked</Form.Label>
                        <Form.Check
                          type="checkbox"
                          id="isBlocked"
                          label=""
                          onChange={(e) => this.changeInputBox(e, "isBlocked")}
                          checked={this.state.isBlocked}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-6">
                      <Form.Group
                        className="mb-3 ml-2"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Google OAuth</Form.Label>
                        <Form.Check
                          type="checkbox"
                          id="isGoogleAuth"
                          label=""
                          onChange={(e) =>
                            this.changeInputBox(e, "isGoogleAuth")
                          }
                          checked={this.state.isGoogleAuth}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseModal}>
              Close
            </Button>
            {this.renderSaveOrUpdateButton()}
            <Button onClick={() => this.resetForm()} variant="primary">
              Reset Form
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
  response: state.response,
});

export default withAlert()(
  connect(mapStateToProps)(authorizeAdmin(requireAuth(UserComponent)))
);
