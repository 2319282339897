export function generateRandomColor(saturation = 100, lightness = 60) {
  const hue = Math.floor(Math.random() * 360);
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

export const DashboardLabel = {
  sumOfAllBrands: "Brand",
  sumOfHighPriority: "High Priority",
  sumOfLowPriority: "Low Priority",
  sumOfsuccess: "Success",
  sumOfEnforcement: "Enforcement",
};

export const colorMap = [
  "#A91F23", // Bright Red (Success)
  "#F09990", // Soft Pink (In Progress)
  "#000000", // Black (Awaiting Approval)
  "#3A475A", // Dark Slate Blue (Approved)
  "#90A4AE", // Soft Gray-Blue (No Further Action/Other)
  "#777878", // Dark Gray (No Action)
  "#E2E3E4", // Light Gray (Purchase)
  "#555655", // Nearly Black (Purchase in Progress)
  "#CBCBCB", // Light Silver (Purchase Complete)
  "#C5A073", // Warm Beige (Client Review)
  "#89C3CA", // Soft Teal (Purchase Incomplete)
  "#809895", // Muted Sea Green (Self Resolved)

  // Additional colors for more variety
  "#FF6B6B", // Coral Red
  "#4ECDC4", // Turquoise
  "#45B7D1", // Sky Blue
  "#FDCB6E", // Warm Yellow
  "#6C5CE7", // Purple
  "#A8E6CF", // Mint Green
  "#FF8ED4", // Soft Pink
  "#6A89CC", // Muted Blue
  "#FAD390", // Light Orange
  "#55E6C1", // Seafoam Green
];

export function toCapitalCase(text) {
  return text
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const transformedData = (data) =>
  data.reduce((acc, curr) => {
    const existing = acc.find((item) => item.name === curr.status);
    if (existing) {
      existing.value += curr.count;
    } else {
      acc.push({ name: curr.status, value: curr.count });
    }
    return acc;
  }, []);

export const statusColorMap = {
  Success: "#A91F23",
  "In Progress": "#F09990",
  "Awaiting Approval": "#000000",
  Approved: "#3A475A",
  "No Further Action": "#90A4AE",
  "No Action": "#777878",
  Purchase: "#E2E3E4",
  "Purchase in Progress": "#555655",
  "Purchase Complete": "#CBCBCB",
  "Client Review": "#C5A073",
  "Purchase Incomplete": "#89C3CA",
  "Self Resolved": "#809895",
  Other: "#90A4AE",
};

export const statusTextColorMap = {
  Success: "#FFFFFF",
  "In Progress": "#000000",
  "Awaiting Approval": "#FFFFFF",
  Approved: "#FFFFFF",
  "No Further Action": "#000000",
  "No Action": "#FFFFFF",
  Purchase: "#000000",
  "Purchase in Progress": "#FFFFFF",
  "Purchase Complete": "#000000",
  "Client Review": "#000000",
  "Purchase Incomplete": "#000000",
  "Self Resolved": "#FFFFFF",
  Other: "#000000",
};

export function formatString(str) {
  let spacedStr = str.replace(/([A-Z])/g, " $1").trim(); // Add spaces before uppercase letters
  let capitalizedStr = spacedStr.replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
  return capitalizedStr;
}

export function formatUrl(url) {
  let finalUrl;
  url = url.replace("www.", "");
  const regex = /^(https?:\/\/)/i;
  if (!regex.test(url)) {
    finalUrl = "http://" + url;
  }
  if (!/^https?:/i.test(url)) {
    finalUrl = "http://" + url;
  }

  finalUrl = new URL(finalUrl).host.split(".")[0];
  return finalUrl;
}
